import axios from 'axios'
import { checkAxios } from '../axiosConfig'
import { fetchJsonVersion } from './publishApi'

export const getAppDataJson = async (appUrl, version = 0) => {
  let newVersion = version

  if (!newVersion) {
    try {
      const data = await fetchJsonVersion(appUrl)
      if (data.response) {
        newVersion = data.responseString
      }
    } catch (error) {
      console.error(error)
    }
  }

  const url = `https://webmobi.s3.amazonaws.com/nativeapps/${appUrl}/temp/appData.json?v=${newVersion}`

  const { data } = await axios({
    url,
    method: 'GET',
  })
  return {
    ...data,
    events: [
      {
        ...data.events[0],
        tabs: data.events[0].tabs.map(
          (tab) => {
            if (tab.type === 'agenda') {
              let agendaItems = []
              for (const agenda of tab.agenda) {
                agendaItems = agendaItems.concat(agenda.detail)
              }
              const compFnc = (a, b) => {
                if (a.fromtime < b.fromtime) return -1
                if (a.fromtime > b.fromtime) return 1
                return 0
              }
              agendaItems.sort((a, b) => compFnc(a, b))
              return { ...tab, agenda: agendaItems }
            } else return tab
          }
          // tab.type === 'agenda' ? {} : tab
        ),
      },
    ],
  }
}

export const deleteSponsor = async (deleteSponsorData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: { ...deleteSponsorData },
  })

  return { ...data, ...deleteSponsorData }
}

export const editCreateSponsor = async (createEditSponsorsData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/sponsors',
    headers: { 'Content-Type': 'application/json' },
    data: createEditSponsorsData,
  })
  return {
    response: data.response,
    responseString: data.responseString,
    payload: { ...createEditSponsorsData },
    type_id: createEditSponsorsData.type_id,
  }
}
export const editCreateSpeaker = async (createEditSpeakersData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/speakers',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...createEditSpeakersData,
      speakers_data: JSON.stringify(createEditSpeakersData.speakers_data),
    },
  })
  const payload = {
    ...createEditSpeakersData,
    ...createEditSpeakersData.speakers_data[0],
    speakerId: createEditSpeakersData.speakers_data[0].speaker_id,
  }
  delete payload.speakers_data
  delete payload.speaker_id
  // console.log('payload', payload)
  return {
    response: data.response,
    responseString: data.responseString,
    payload: payload,
    type_id: createEditSpeakersData.type_id,
  }
}
export const deleteSpeaker = async (deleteSpeakerData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: { ...deleteSpeakerData },
  })
  return { ...data, ...deleteSpeakerData }
}

// for Basic.js
export const basicDetails = async (basicPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/app_settings',
    headers: { 'Content-Type': 'application/json' },
    data: { ...basicPayload },
  })
  return { ...data, ...basicPayload }
}

// delete exhibitor

export const deleteExhibitor = async (deleteExhibitorData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: { ...deleteExhibitorData },
  })
  console.log('data')
  return { ...data, ...deleteExhibitorData }
}
export const editcreateExhibitor = async (createEditExhibitorsData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/exhibitors',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data: createEditExhibitorsData,
  })
  return {
    response: data.response,
    responseString: data.responseString,
    payload: { ...createEditExhibitorsData },
    type_id: createEditExhibitorsData.type_id,
  }
}
export const homeSettings = async (homeSettingsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/home_settings',
    headers: { 'Content-Type': 'application/json' },
    data: { ...homeSettingsPayload.payload },
  })
  return { ...data, ...homeSettingsPayload }
}

export const appPublish = async (appPublishPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/app_publish',
    headers: { 'Content-Type': 'application/json' },
    data: { ...appPublishPayload.payload },
  })
  return { ...data, ...appPublishPayload }
}

export const virtualHybrid = async (virtualHybridPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/app_settings',
    headers: { 'Content-Type': 'application/json' },
    data: { ...virtualHybridPayload },
  })
  return { ...data, ...virtualHybridPayload }
}

export const speakerXlsxUpload = async (uploadXlsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/speakers',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...uploadXlsPayload,
      speakers_data: JSON.stringify(uploadXlsPayload.speakers_data),
    },
  })

  return { ...data, ...uploadXlsPayload }
}

export const sponsorXlsxUpload = async (payload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/sponsors',
    headers: { 'Content-Type': 'application/json' },
    data: payload,
  })

  return { ...data, ...payload }
}

export const exhibitorXlsxUpload = async (uploadXlsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/exhibitors',
    headers: { 'Content-Type': 'application/json' },
    data: uploadXlsPayload,
  })

  return { ...data, ...uploadXlsPayload }
}

export const modSetting = async (modSettingPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/mod_settings',
    headers: { 'Content-Type': 'application/json' },
    data: { ...modSettingPayload.payload },
  })
  return { ...data, ...modSettingPayload.payload }
}
// to add modules in layout
export const addFeature = async (addFeaturePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/add_feature',
    headers: { 'Content-Type': 'application/json' },
    data: addFeaturePayload,
  })
  return data
}
// to delete featurs from settings
export const deleteFeature = async (deleteFeaturePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_feature',
    headers: { 'Content-Type': 'application/json' },
    data: deleteFeaturePayload,
  })
  return data
}
///Export Excel for Exhibitor/sponsor/speaker/agenda
export const exportToExcel = async (exportXlsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/export_module_excel',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...exportXlsPayload,
    },
  })

  return { ...data, ...exportXlsPayload }
}

//Zip upload for Speaker
export const zipImageUpload = async (zipImgPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/admin_gallery_upload',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...zipImgPayload,
      image_info: JSON.stringify(zipImgPayload.image_info),
    },
  })

  return { ...data, ...zipImgPayload }
}

export const uploadImg = async (imagePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/uploadmisc',
    headers: { 'Content-Type': 'application/json' },
    data: { ...imagePayload },
  })
  return { ...data, ...imagePayload }
}

export const exportQrCode = async (appId, type) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/reportpdf?appid=${appId}&reportType=${type}`,
      headers: { 'Content-Type': 'application/json' },
    })

    return data.Pdf_detail
  } catch (error) {
    throw error
  }
}

export async function exportAttendeeQrCode({ appId, type }) {
  try {
    const { data } = await checkAxios({
      method: 'POST',
      url: `/event/export_module_excel`,
      headers: { 'Content-Type': 'application/json' },
      data: {
        appid: appId,
        type: type,
      },
    })

    return data
  } catch (error) {
    throw error
  }
}
