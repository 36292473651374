import React, {
  lazy,
  useEffect,
  useState,
  useContext,
  Suspense,
  memo,
} from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import {
  CssBaseline,
  Container,
  Snackbar,
  Alert,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { closeSnackbar } from "./actions";
import { healthAxios } from "./axiosConfig";
import { LoadingContext } from "./context/loading.context";
// component import
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import EventDashboard from "./components/EventDashboard";
import RightSidebar from "./components/RightSidebar";
import MyLoader from "./components/MyLoader";
import ProtectedRoute from "./components/ProtectedRoute";
import JoinWebMobi from "./components/JoinWebMobi";
import NewSignUp from "./components/NewSignUp";
import NewLogin from "./components/NewLogin";
import SurveyWeb from "./components/Survey";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";
import MobileHeader from "./components/Headers/MobileHeader";
import Drawer from "./components/drawer";
import Loader from "./components/customComponent/Loader";
import { DrawerContext } from "./components/streamComponent/Stream";
import "./styles/app.css";
import { AuthProvider } from "./context/auth.context";
import { useOnline } from "./hooks/useOnline";
import useHelper from "./hooks/useHelper";
import usePlanInfo from "./hooks/usePlanInfo";
import useFbSDKInit from "./hooks/useFbSDKInit";
import { useDomain } from "./context/domain.context";
import NotFound from "./components/streamComponent/NotFound";
// import EmbededForm from './components/embededForm/EmbededForm'

const EmbededForm = lazy(() => import("./components/embededForm/EmbededForm"));
const EventSetup = lazy(() => import("./components/eventSetup"));
const StreamLogin = lazy(() =>
  import("./components/streamComponent/StreamLogin")
);
const TemplatePreviewMain = lazy(() =>
  import("./components/templatePreview/TemplatePreviewMain")
);
const Header = lazy(() => import("./components/Header"));
const Stream = lazy(() => import("./components/streamComponent/Stream"));
const ThreeD = lazy(() => import("./components/3Dproject/ThreeD"));
const BoothSetupMain = lazy(() =>
  import("./components/boothSetup/BoothSetupMain")
);
const TemplateSetupMain = lazy(() =>
  import("./components/templateSetup/TemplateSetupMain")
);
const AttendeeRegister = lazy(() => import("./components/attendeeRegister"));
const ActiveRatingPresentation = lazy(() =>
  import("./components/eventComponent/ActiveRatingPresentation")
);
const ActivePolls = lazy(() => import("./components/ActivePolls"));
const ActiveQuizzes = lazy(() => import("./components/ActiveQuizzes"));
const Configure = lazy(() => import("./components/Configure"));
const ActiveWordCloudPresentation = lazy(() =>
  import("./components/wordCloud/ActiveWordCloudPresentation")
);
const PreviewSlide = lazy(() => import("./components/preview-slide"));
const HomePage = lazy(() => import("./components/Homepage"));
const Footer = lazy(() => import("./components/Footer"));
const EventComponent = lazy(() => import("./components/eventComponent"));
const JoinEvent = lazy(() => import("./components/JoinEvent"));
const Setting = lazy(() => import("./components/settingComponent"));
const Help = lazy(() => import("./components/Help"));
const AccountSettings = lazy(() => import("./components/accountSettings"));
const JoinLiveEvent = lazy(() => import("./components/JoinLiveEvent"));
const Subscription = lazy(() => import("./components/Subscription"));
const Invoices = lazy(() => import("./components/Invoices"));
const StreamUsage = lazy(() => import("./components/StreamUsage"));
const Events = lazy(() => import("./components/Events"));
const Vote = lazy(() => import("./components/Vote"));

const App = memo(() => {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [tabVal, setTabVal] = useState(0);
  const { loading: contextLoading } = useContext(LoadingContext);
  const isMobileView = useMediaQuery("(max-width:768px)");
  const { getPlanInfo } = usePlanInfo();
  const { brandName } = useDomain();

  useEffect(() => {
    document.title = brandName || "WebMOBI";
  }, [brandName]);

  useEffect(() => {
    healthAxios({
      url: "/isloggedin",
      method: "GET",
      withCredentials: true,
    })
      .then(async (userRes) => {
        await getPlanInfo(userRes);
        setLoading(false);
        reduxDispatch({ type: "SET_AUTH_LOADING", payload: false });

        if (
          history.location.pathname === "/" &&
          history.location.search.includes("?")
        ) {
          const location = sessionStorage.getItem("location");
          const url = location
            ? location + history.location.search
            : history.location.search;
          return history.replace(url);
        }
        const { from } = history.location.state || {
          from: {
            pathname:
              history.location.pathname === "/"
                ? "/events"
                : history.location.pathname,
          },
        };
        history.replace(from);
      })
      .catch((err) => {
        setLoading(false);
        reduxDispatch({ type: "SET_AUTH_LOADING", payload: false });
        console.log(err);
      });
  }, [history, reduxDispatch, getPlanInfo]);

  // helper widget side effect
  useHelper();

  // initialize facebook sdk
  useFbSDKInit();

  useEffect(() => {
    if ("serviceWorker" in window.navigator) {
      history.listen(() => {
        navigator.serviceWorker
          .getRegistrations()
          .then((regs) => {
            regs.forEach((reg) => {
              reg.update();
            });
          })
          .catch((reason) => console.log("service worker error", reason));
      });
    } else {
      console.log("no service worker found");
    }
  }, [history]);

  //drawerOpen
  const drawerState = (state) => {
    setToggleDrawer(state);
  };

  const linkValueHandler = (val) => {
    setTabVal(val);
  };

  const handleExpand = () => {
    console.log("Expand");
  };

  const handleOpenDrawer = () => {
    console.log("OpenDrawer");
  };

  return (
    <Suspense fallback={<MyLoader />}>
      <CssBaseline />
      <MySnackbar />
      <Loader open={contextLoading} />
      <OfflineDialog />
      <Switch>
        <ProtectedRoute path="/events" exact>
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                <Dashboard />
                <MobileHeader drawerState={drawerState} />
                <Drawer
                  drawerState={drawerState}
                  toggleDrawer={toggleDrawer}
                  linkValueHandler={linkValueHandler}
                />
                <Events />
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <ProtectedRoute path="/account-settings" exact>
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                <Dashboard />
                <MobileHeader drawerState={drawerState} />
                <Drawer
                  drawerState={drawerState}
                  toggleDrawer={toggleDrawer}
                  linkValueHandler={linkValueHandler}
                />
                <AccountSettings />
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name" exact>
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                {isMobileView ? (
                  <>
                    <MobileHeader drawerState={drawerState} />
                    <Drawer
                      drawerState={drawerState}
                      toggleDrawer={toggleDrawer}
                      linkValueHandler={linkValueHandler}
                      type="event"
                    />
                  </>
                ) : (
                  <EventDashboard currTab={tabVal} />
                )}
                <EventComponent tabVal={tabVal} setTabVal={setTabVal} />
              </div>
            </div>
            <RightSidebar currentTab={tabVal} />
          </div>
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name/activepolls" exact>
          <ActivePolls />
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name/activequizzes" exact>
          <ActiveQuizzes />
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name/activewordcloud" exact>
          <ActiveWordCloudPresentation />
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name/activerating" exact>
          <ActiveRatingPresentation />
        </ProtectedRoute>
        <ProtectedRoute path="/vote" exact>
          <Vote />
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name/join-webmobi" exact>
          <JoinWebMobi />
        </ProtectedRoute>
        <Route exact path="/join-event">
          <JoinEvent />
          <Footer />
        </Route>
        <Route exact path="/preview-slide/:app_url/:pid">
          <PreviewSlide />
        </Route>
        <Route path="/join-live-event/:app_url/:app_id/:token">
          <JoinLiveEvent />
        </Route>
        <Route path="/join-event/:app_url">
          <HomePage />
          <Footer />
        </Route>
        <Route exact path="/register">
          <NewSignUp />
        </Route>
        <ProtectedRoute path="/events/:name/setup">
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                {isMobileView ? (
                  <>
                    <MobileHeader drawerState={drawerState} />
                    <Drawer
                      drawerState={drawerState}
                      toggleDrawer={toggleDrawer}
                      linkValueHandler={linkValueHandler}
                    />
                  </>
                ) : (
                  <Header />
                )}
                <EventSetup />
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <ProtectedRoute path="/events/:name/setting">
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                {isMobileView ? (
                  <>
                    <MobileHeader drawerState={drawerState} />
                    <Drawer
                      drawerState={drawerState}
                      toggleDrawer={toggleDrawer}
                      linkValueHandler={linkValueHandler}
                    />
                  </>
                ) : (
                  <Header />
                )}
                <Setting />
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <ProtectedRoute exact path="/subscription">
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                <Dashboard />
                <MobileHeader drawerState={drawerState} />
                <Drawer
                  drawerState={drawerState}
                  toggleDrawer={toggleDrawer}
                  linkValueHandler={linkValueHandler}
                />
                <Container style={{ backgroundColor: "white" }}>
                  <Subscription />
                </Container>
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <ProtectedRoute exact path="/stream-usage">
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                <Dashboard />
                <MobileHeader drawerState={drawerState} />
                <Drawer
                  drawerState={drawerState}
                  toggleDrawer={toggleDrawer}
                  linkValueHandler={linkValueHandler}
                />
                <Container style={{ backgroundColor: "white" }}>
                  <StreamUsage />
                </Container>
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        {/******** Register Attendee ************************/}
        <Route path="/:app_url/attendee">
          <AttendeeRegister />
        </Route>
        <Route exact path="/events/:appId/form">
          <EmbededForm />
        </Route>
        <Route exact path="/events/:appId/form/:ticket_id">
          <EmbededForm />
        </Route>
        <ProtectedRoute exact path="/invoices">
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                <Dashboard />
                <MobileHeader drawerState={drawerState} />
                <Drawer
                  drawerState={drawerState}
                  toggleDrawer={toggleDrawer}
                  linkValueHandler={linkValueHandler}
                />
                <Container>
                  <Invoices />
                </Container>
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <ProtectedRoute exact path="/help">
          <div className="app">
            <Sidebar />
            <div className="app-right">
              <div className="content">
                <Dashboard />
                <MobileHeader drawerState={drawerState} />
                <Drawer
                  drawerState={drawerState}
                  toggleDrawer={toggleDrawer}
                  linkValueHandler={linkValueHandler}
                />
                <Container>
                  <Help />
                </Container>
              </div>
            </div>
            <RightSidebar />
          </div>
        </ProtectedRoute>
        <Route exact path="/events/:app_url/threeD/:template">
          <DrawerContext.Provider value={{ handleOpenDrawer, handleExpand }}>
            <AuthProvider>
              <ThreeD />
            </AuthProvider>
          </DrawerContext.Provider>
        </Route>
        <Route exact path="/threeD/templates/:template">
          <DrawerContext.Provider value={{ handleOpenDrawer, handleExpand }}>
            <AuthProvider>
              <TemplatePreviewMain />
            </AuthProvider>
          </DrawerContext.Provider>
        </Route>
        <Route exact path="/:app_url/stream/login">
          <AuthProvider>
            <StreamLogin />
          </AuthProvider>
        </Route>
        <Route path="/:app_url/stream">
          <AuthProvider>
            <Stream />
          </AuthProvider>
        </Route>
        <ProtectedRoute exact path="/events/:app_url/boothsetup">
          <BoothSetupMain />
        </ProtectedRoute>
        <ProtectedRoute exact path="/events/:app_url/template-setup">
          <TemplateSetupMain />
        </ProtectedRoute>
        <Route exact path="/">
          {loading ? <MyLoader /> : <NewLogin />}
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/reset">
          <ResetPassword />
        </Route>
        <Route exact path="/configure">
          <Configure />
        </Route>
        /:app_url/
        <Route exact path="/survey/:app_url">
          <SurveyWeb />
        </Route>
        <Route exact path="/*">
          <NotFound homePath={"/events"} />
        </Route>
      </Switch>
    </Suspense>
  );
});

const MySnackbar = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const reduxDispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    reduxDispatch(closeSnackbar());
  };

  if (snackbar?.message)
    return (
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        // key={'bottom' + 'center'}
      >
        <Alert onClose={handleClose} severity={snackbar.type} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    );
  return <></>;
};

const OfflineDialog = () => {
  const { isOnline } = useOnline();
  return (
    <Dialog open={!isOnline}>
      <DialogTitle>You are offline.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your network connection seems to be unstable.
          <br />
          Check your connection.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default Sentry.withProfiler(App);
