import { checkAxios } from '../axiosConfig'

export const multieventLogin = async (multieventLoginPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/multievent_login',
    headers: { 'Content-Type': 'application/json' },
    data: multieventLoginPayload,
  })
  return { ...data, ...multieventLoginPayload }
}
