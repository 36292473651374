// ------imports:start--------
import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import isAlpha from 'validator/lib/isAlpha'
import isEmail from 'validator/lib/isEmail'
import isStrongPassword from 'validator/lib/isStrongPassword'
import isLength from 'validator/lib/isLength'
import { useStateValue } from './StateProvider'
import { healthAxios } from '../axiosConfig'
import signupImg from './images/signup.svg'
import googleImg from './images/google.svg'
import queryString from 'query-string'
import { IFrameContext } from '../context/iframe.context'
import { DomainContext } from '../context/domain.context'
import { openSnackbar } from '../actions'
// ------imports:end--------

// ---------mui component:start---------
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
// ---------mui component:end---------

// ---------mui icons:start---------
// ---------mui icons:end---------

//----------styles import:start------------
import '../styles/newsignup.css'
import { useDispatch } from 'react-redux'
import Loader from './customComponent/Loader'
import { subscribe } from '../apis/subscriptionApis'
import { useSocket } from '../context/socket.context'

// --------main component:start--------
const NewSignUp = () => {
  // const classes = useStyles()
  const [, dispatch] = useStateValue()
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    code: '',
  })
  const [openSpinner, setOpenSpinner] = React.useState(false)
  const { search } = useLocation()
  const { user_id, email, id, dashboardUserId, planType } =
    queryString.parse(search)
  const [isIFrame] = useContext(IFrameContext)
  const { brandName } = useContext(DomainContext)
  const reduxDispatch = useDispatch()
  const socket = useSocket()
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  })

  let history = useHistory()

  useEffect(() => {
    if (user_id && email) {
      dispatch({
        type: 'SET_USER',
        user: {
          userId: user_id,
          email: email,
          dashboardUserId: dashboardUserId,
        },
      })
      setOpenSpinner(false)
      return history.replace('/')
    }
  }, [dashboardUserId, dispatch, email, history, user_id])

  useEffect(() => {
    socket.on('PAYMENT_SUCCESSFUL', ({ status, message }) => {
      if (status === 'ok') {
        reduxDispatch(openSnackbar('success', message))
      }
    })
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setUser((user) => ({
      ...user,
      [name]: value,
    }))
    setError((error) => ({ ...error, [name]: '' }))
  }

  const validateFormField = () => {
    const error = {}
    const first_name = user.first_name.trim()
    if (!first_name.length) {
      error.first_name = 'First name is required'
    } else if (!isAlpha(first_name, 'en-US', { ignore: ' ' })) {
      error.first_name = 'First name can contain only Letters and space'
    } else if (!isLength(first_name, { min: 2, max: 20 })) {
      error.first_name = 'Its length must be at least 2 and at most 20'
    }

    const last_name = user.last_name.trim()
    if (!last_name.length) {
      error.last_name = 'Last name is required'
    } else if (!isAlpha(last_name, 'en-US', { ignore: ' ' })) {
      error.last_name = 'Last name can contain only Letters and space'
    } else if (!isLength(last_name, { min: 2, max: 20 })) {
      error.last_name = 'Its length must be at least 2 and at most 20'
    }

    const email = user.email.trim().toLowerCase()
    if (!isEmail(email)) {
      error.email = 'Enter a valid email'
    }
    if (!isStrongPassword(user.password)) {
      error.password =
        'Password must be at least 8 characters, one uppercase, one lowercase, one number and one special character'
    }
    setUser((user) => ({ ...user, first_name, last_name, email }))

    return error
  }

  // signup function
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const error = validateFormField()

    if (Object.values(error).length) {
      return setError((err) => ({ ...err, ...error }))
    }
    setOpenSpinner(true)
    try {
      const data = { ...user }
      if (id) {
        data._id = id
      }
      if (!user.code) {
        delete data.code
      }

      await healthAxios({
        url: '/users/signup',
        method: 'POST',
        data,
      })

      if (planType) {
        reduxDispatch(
          openSnackbar(
            'success',
            'Account created successfully. Redirecting to payment page...'
          )
        )
        socket.emit('JOIN_PLAN_SETTINGS', { roomId: user.email })
        const response = await subscribe(
          user.email,
          user.first_name,
          user.last_name,
          planType
        )
        setOpenSpinner(false)

        if (response.status === 200) {
          console.log('hostedpage: ', response.data)
          window.open(response.data.hosted_page.url, '_self')
        }
        return
      }
      setOpenSpinner(false)
      reduxDispatch(
        openSnackbar(
          'success',
          'Account created successfully. Verification link has been sent. Please verify.'
        )
      )
      return history.replace('/')
    } catch (error) {
      setOpenSpinner(false)
      let text
      if (error?.response?.data?.errors?.length) {
        text = error.response.data.errors[0].message
      } else {
        text = error.message
      }
      reduxDispatch(openSnackbar('error', text))
    }
  }

  //--- handle google login in function ---
  const handleGoogleLogin = async () => {
    window.location.href = process.env.REACT_APP_GOOGLEOAUTHURL
  }

  return (
    <section className="newsignup">
      <Loader open={openSpinner} />
      {/* ------header:start--------- */}
      <header className="newsignup__header">
        <ul className="newSignup__list">
          <li className="newSignup__listItem brandName">
            {brandName ? brandName : 'Ngage.ai'}
          </li>
          <li className="newSignup__listItem hide--sm">
            <Button
              variant="outlined"
              color="primary"
              className="newSignup__btn"
              onClick={() => {
                return history.push('/join-event')
              }}
            >
              Join as participant
            </Button>
          </li>
        </ul>
      </header>
      {/* ------header:end--------- */}

      {/* --------container:start--------- */}
      <div className="newsignup__container">
        {/* --------left column:start-------- */}
        <div className="leftCol">
          {/* ------- left column message container:start ------- */}
          <div className="lefCol__msgContainer">
            <div className="leftCol__title">
              <h1>
                Sign up <br />
                as a meeting host
              </h1>
              <p>
                or{' '}
                <Link to="/" data-testid="loginLinkDesktop">
                  log in to your account
                </Link>
              </p>
            </div>
          </div>
          {/* ------- left column message container:end ------- */}

          {/* ------left column image container:start------ */}
          <div className="leftCol__imgContainer">
            <div className="leftCol__imgWrapper">
              <div className="img__shadow"></div>
              <img src={signupImg} alt="signup" />
            </div>
          </div>
          {/* ------left column image container:end------ */}
        </div>
        {/* --------left column:end-------- */}

        {/* --------right column:start-------- */}
        <div className="rightCol">
          {/* -------form:start-------- */}
          <form
            // className="form"
            // className={classes.root}
            autoComplete="off"
            onSubmit={handleFormSubmit}
          >
            <div className="form__wrapper">
              <div className="form__group">
                <label htmlFor="first name">First Name</label>
                <TextField
                  id="first_name"
                  name="first_name"
                  placeholder="John"
                  variant="filled"
                  type="text"
                  value={user.first_name}
                  onChange={handleChange}
                  error={error.first_name ? true : false}
                  helperText={error.first_name}
                />
              </div>
              <div className="form__group">
                <label htmlFor="last_name">Last Name</label>
                <TextField
                  id="last_name"
                  name="last_name"
                  placeholder="Doe"
                  variant="filled"
                  type="text"
                  value={user.last_name}
                  onChange={handleChange}
                  error={error.last_name ? true : false}
                  helperText={error.last_name}
                />
              </div>
            </div>
            <div className="form__group">
              <label htmlFor="email">Email</label>
              <TextField
                id="email"
                name="email"
                placeholder="JohnDoe@webmobi.com"
                variant="filled"
                type="email"
                value={user.email}
                onChange={handleChange}
                error={error.email ? true : false}
                helperText={error.email}
              />
            </div>
            <div className="form__group">
              <label htmlFor="password">Password</label>
              <TextField
                id="password"
                name="password"
                placeholder=". . . . . . . . . . . . . . . . . . . . . . . ."
                variant="filled"
                type="password"
                value={user.password}
                onChange={handleChange}
                error={error.password ? true : false}
                helperText={error.password}
              />
            </div>

            {/* --------- CODE INPUT FIELD ------- */}
            <div className="form__group">
              <label htmlFor="code">Code</label>
              <TextField
                id="code"
                name="code"
                placeholder="Enter Coupon Code"
                variant="filled"
                type="text"
                value={user.code}
                onChange={handleChange}
              />
            </div>
            <div className="buttonGroup">
              <Button
                type="submit"
                className="buttonGroup__button"
                size="large"
              >
                Sign Up
              </Button>
            </div>
          </form>
          {/* -------form:end-------- */}
          {isIFrame === false && (
            <>
              <p className="googleMsg">Or Sign up with</p>
              <div className="googleicn" onClick={handleGoogleLogin}>
                <img src={googleImg} alt="google icon" />
              </div>
            </>
          )}
        </div>
        {/* --------right column:end-------- */}
      </div>
      <p className="signUp--hidesm">
        Sign as a meeting Host ! or <Link to="/">Click here to Log In !</Link>
      </p>
      <div className="joinEvent--sm">
        <Button
          variant="outlined"
          color="primary"
          className="btnGroup__btn"
          onClick={() => {
            return history.push('/join-event')
          }}
        >
          Join as participant
        </Button>
      </div>
      {/* --------container:end--------- */}
    </section>
  )
}
export default NewSignUp
// --------main component:end--------
