import { useState, useEffect, memo } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
  Avatar,
  Badge,
  Box,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded'
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded'
import RecentActorsRoundedIcon from '@mui/icons-material/RecentActorsRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'

import styles from '../../../styles/streamRightSidebar.module.css'
import Discussion from './Discussion'
import QnA from './QnA'
import Polls from './Polls'
import Chats, { getUnSeenMsgCount } from './Chats'
import { useAuth } from '../../../context/auth.context'
import { LogoutMenu } from './LogoutMenu'
import { useChat } from '../../../context/Chat.context'

const RightSidebar = memo(({ expand, setExpand }) => {
  const [tab, setTab] = useState('list')
  const { state: users } = useChat()
  const [unSeenMsgCount, setUnSeenMsgCount] = useState(0)

  useEffect(() => {
    let count = 0
    for (const user of users) {
      count = count + getUnSeenMsgCount(user.messages)
    }
    setUnSeenMsgCount(count)
  }, [users])

  return (
    <>
      {/* right side bar expanded view mobile/laptop */}
      <Drawer
        anchor="right"
        variant="temporary"
        open={expand}
        onClose={() => setExpand(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100%', sm: '23.5rem' },
          },
        }}
      >
        <RSExpandView
          tab={tab}
          setTab={setTab}
          setExpand={setExpand}
          unSeenMsgCount={unSeenMsgCount}
        />
      </Drawer>
      {/* right side bar min view, only for large screen */}
      <Drawer
        variant="permanent"
        anchor="right"
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
        open
      >
        <RSMinView
          setTab={setTab}
          setExpand={setExpand}
          unSeenMsgCount={unSeenMsgCount}
        />
      </Drawer>
    </>
  )
})

export function RSExpandView({ tab, setTab, setExpand, unSeenMsgCount }) {
  const { authUser } = useAuth()
  const { search } = useLocation()
  const searchParam = queryString.parse(search)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (Object.hasOwn(searchParam, 'session')) {
      console.log('session id', searchParam.session)
    }
  }, [searchParam])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const chat = (
    <Badge
      badgeContent={unSeenMsgCount > 0 ? unSeenMsgCount : null}
      color="primary"
    >
      <ChatRoundedIcon />
    </Badge>
  )

  return (
    <Box sx={{ p: '0 0.75rem' }}>
      {tab === 'list' ? (
        <>
          <RSidebarItem
            sx={{
              justifyContent: 'space-between',
              gap: '0.5rem',
            }}
          >
            <IconButton size="small" onClick={() => setExpand(false)}>
              <ChevronRightRoundedIcon
                sx={{
                  border: '1px solid #888',
                  borderRadius: '50%',
                  fontSize: '1.75rem',
                }}
              />
            </IconButton>
            <IconButton onClick={handleClick}>
              <UserAvatarWithBadge />
            </IconButton>
          </RSidebarItem>
          <SidebarListItem
            title="Polls"
            icon={<RecentActorsRoundedIcon />}
            handleClick={() => setTab('polls')}
          />
          <SidebarListItem
            title="Chat"
            icon={chat}
            handleClick={() => setTab('chats')}
          />
          <SidebarListItem
            title="Discussion"
            icon={<PeopleRoundedIcon />}
            handleClick={() => setTab('discussion')}
          />
          <SidebarListItem
            title="Q &amp; A"
            icon={<HelpRoundedIcon />}
            handleClick={() => setTab('qna')}
          />
        </>
      ) : (
        <>
          <RSidebarItem sx={{ flexDirection: 'column' }}>
            <UserAvatarWithBadge size="large" />
            <Typography
              variant="h5"
              fontWeight="bold"
              width="100%"
              overflow="hidden"
              textAlign="center"
            >
              {authUser.name}
            </Typography>
          </RSidebarItem>
          {tab === 'discussion' && <Discussion setTab={setTab} />}
          {tab === 'polls' && <Polls setTab={setTab} />}
          {tab === 'chats' && <Chats setTab={setTab} />}
          {tab === 'qna' && <QnA setTab={setTab} />}
        </>
      )}
      <LogoutMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Box>
  )
}

export function SidebarPanel({ title, setTab, children }) {
  const theme = useTheme()
  return (
    <Box className={styles.SidebarPanel}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          mb: '1rem',
        }}
      >
        <IconButton size="small" onClick={() => setTab('list')}>
          <ChevronLeftRoundedIcon
            sx={{
              border: '1px solid #888',
              borderRadius: '50%',
              fontSize: '1.75rem',
            }}
          />
        </IconButton>
        <Typography
          variant="h6"
          fontWeight="bold"
          p="0 0.75rem"
          borderBottom={`2px solid ${theme.palette.primary.main}`}
        >
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}

function SidebarListItem({ title, icon = null, handleClick }) {
  const onClick = () => {
    handleClick()
  }
  return (
    <Box className={styles.SidebarListItem}>
      <IconButton size="small" onClick={onClick}>
        <ChevronRightRoundedIcon
          sx={{
            border: '1px solid #888',
            borderRadius: '50%',
            fontSize: '1.75rem',
          }}
        />
      </IconButton>
      <Box className={styles.ListItemTitle}>
        {icon}
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export function RSMinView({ setTab, setExpand, unSeenMsgCount }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (tab) => {
    setExpand(true)
    setTab(tab)
  }

  const handleAvatar = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <RSidebarItem>
        <IconButton size="small" onClick={() => setExpand(true)}>
          <ChevronLeftRoundedIcon
            sx={{
              border: '1px solid #888',
              borderRadius: '50%',
            }}
          />
        </IconButton>
      </RSidebarItem>
      <RSidebarItem>
        <IconButton onClick={handleAvatar}>
          <UserAvatarWithBadge size="small" />
        </IconButton>
      </RSidebarItem>
      <RSidebarItem>
        <CircleNotificationsIcon />
      </RSidebarItem>
      <hr />
      <RSidebarItem
        sx={{ flexDirection: 'column', cursor: 'pointer' }}
        onClick={() => handleClick('chats')}
      >
        <Badge
          badgeContent={unSeenMsgCount > 0 ? unSeenMsgCount : null}
          color="primary"
        >
          <ChatRoundedIcon />
        </Badge>
        <Typography variant="caption">Chats</Typography>
      </RSidebarItem>
      <RSidebarItem
        sx={{ flexDirection: 'column', cursor: 'pointer' }}
        onClick={() => handleClick('polls')}
      >
        <RecentActorsRoundedIcon />
        <Typography variant="caption">Polls</Typography>
      </RSidebarItem>
      <RSidebarItem
        sx={{ flexDirection: 'column', cursor: 'pointer' }}
        onClick={() => handleClick('discussion')}
      >
        <PeopleRoundedIcon />
        <Typography variant="caption">Discussion</Typography>
      </RSidebarItem>
      <RSidebarItem
        sx={{ flexDirection: 'column', cursor: 'pointer' }}
        onClick={() => handleClick('qna')}
      >
        <HelpRoundedIcon />
        <Typography variant="caption">Q &amp; A</Typography>
      </RSidebarItem>
      <LogoutMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  )
}

function RSidebarItem({ children, ...rest }) {
  return (
    <Box {...rest} className={styles.rSidebarItem}>
      {children}
    </Box>
  )
}
const getSize = (size) => {
  if (size === 'small') return 24
  if (size === 'medium') return 32
  return 40
}
export function UserAvatarWithBadge({ size = 'medium' }) {
  const { authUser } = useAuth()

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={<Box className={styles.Badge}></Box>}
    >
      {authUser.profile_pic ? (
        <Avatar
          alt=""
          src={authUser.profile_pic}
          sx={{
            width: getSize(size),
            height: getSize(size),
          }}
        />
      ) : (
        <AccountBoxRoundedIcon fontSize={size} />
      )}
    </Badge>
  )
}

export default RightSidebar
