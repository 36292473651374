import React, { useState, createContext, useMemo } from 'react'

export const IFrameContext = createContext()

export const IFrameProvider = (props) => {
  const [isIFrame, setIsIFrame] = useState(false)

  const value = useMemo(() => [isIFrame, setIsIFrame], [isIFrame])

  return (
    <IFrameContext.Provider value={value}>
      {props.children}
    </IFrameContext.Provider>
  )
}
