import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { healthAxios } from '../../axiosConfig'
import VotingPoll from './VotingPoll'
import VotingQuiz from './VotingQuiz'
import VotingWC from './VotingWC'
import VotingRating from './VotingRating'
import '../../styles/pollVote.css'
import { VoteActions, VoteContext } from '../../context/voting.context'
import ShimmerCard from './ShimmerCard'
import QuestionImg from '../images/Questions.svg'
import { Grid } from '@mui/material'
import { useSocket } from '../../context/socket.context'

export default function Poll({ app_id }) {
  const [state, dispatch] = useContext(VoteContext)
  const { app_url } = useParams()
  const [loading, setLoading] = useState(true)
  const [resetQuizOpt, setResetQuizOpt] = useState(0)
  const socket = useSocket()

  //get all polls,quizzes,vido data
  useEffect(() => {
    let didUnmount = false
    setLoading(true)
    async function getAllData() {
      try {
        const pollRes = await healthAxios({
          url: '/getpolls',
          method: 'GET',
          params: {
            app_id,
          },
        })
        if (!didUnmount)
          dispatch({
            type: VoteActions.setPolls,
            payload: pollRes?.data?.Polls,
          })

        const quizRes = await healthAxios({
          url: '/getallquiz',
          method: 'GET',
          params: {
            app_id,
          },
        })

        if (!didUnmount)
          dispatch({
            type: VoteActions.setQuizzes,
            payload: quizRes?.data?.data,
          })

        const wcRes = await healthAxios({
          url: '/getwordcloud',
          method: 'GET',
          params: {
            app_id,
          },
        })

        if (wcRes?.status === 200 && !didUnmount) {
          dispatch({
            type: VoteActions.setWC,
            payload: wcRes?.data?.wordCloud,
          })
        }

        const ratingRes = await healthAxios({
          url: '/get-rating-polls',
          method: 'GET',
          params: {
            app_id,
          },
        })

        if (!didUnmount)
          dispatch({
            type: VoteActions.setRatings,
            payload: ratingRes.data.ratings,
          })

        const activePollEvent = pollRes?.data?.Polls.filter(
          (element, index) => {
            return (
              element.active_status === 'true' || element.active_status === true
            )
          }
        )

        const activeQuizEvent = quizRes?.data?.data.filter((element) => {
          return (
            element.active_status === 'true' || element.active_status === true
          )
        })

        let activeWCEvent = []
        if (wcRes.status === 200) {
          activeWCEvent = wcRes?.data?.wordCloud?.filter((word) => {
            return (
              word?.active_status === 'true' || word?.active_status === true
            )
          })
        }

        const activeRatingEvent = ratingRes?.data?.ratings.filter((element) => {
          return (
            element.active_status === 'true' || element.active_status === true
          )
        })

        if (
          activePollEvent.length > 0 &&
          activeQuizEvent.length === 0 &&
          activeRatingEvent.length === 0 &&
          activeWCEvent.length === 0 &&
          !didUnmount
        ) {
          dispatch({
            type: VoteActions.setActiveEvent,
            payload: { type: 'poll', event: activePollEvent },
          })
        }
        if (
          activePollEvent.length === 0 &&
          activeRatingEvent.length === 0 &&
          activeWCEvent.length === 0 &&
          activeQuizEvent.length > 0 &&
          !didUnmount
        ) {
          dispatch({
            type: VoteActions.setActiveEvent,
            payload: { type: 'quiz', event: activeQuizEvent },
          })
        }
        if (
          activePollEvent.length === 0 &&
          activeQuizEvent.length === 0 &&
          activeRatingEvent.length === 0 &&
          activeWCEvent.length > 0 &&
          !didUnmount
        ) {
          dispatch({
            type: VoteActions.setActiveEvent,
            payload: { type: 'wc', event: activeWCEvent },
          })
        }
        if (
          activePollEvent.length === 0 &&
          activeQuizEvent.length === 0 &&
          activeWCEvent.length === 0 &&
          activeRatingEvent.length > 0 &&
          !didUnmount
        ) {
          dispatch({
            type: VoteActions.setActiveEvent,
            payload: { type: 'rating', event: activeRatingEvent },
          })
        }
        if (
          activePollEvent.length === 0 &&
          activeQuizEvent.length === 0 &&
          activeRatingEvent.length === 0 &&
          activeWCEvent.length === 0 &&
          !didUnmount
        ) {
          dispatch({
            type: VoteActions.setActiveEvent,
            payload: { type: '', event: [] },
          })
        }
      } catch (error) {
        console.error(error)
      }
      if (!didUnmount) setLoading(false)
    }

    if (app_id) {
      getAllData()
    }

    return () => {
      didUnmount = true
    }
  }, [app_id])

  useEffect(() => {
    socket.on('POLL_LOCK_STATUS', (data) => {
      dispatch({
        type: VoteActions.lockPoll,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'poll', data, updateType: 'poll_lock' },
      })
    })

    socket.on('CHANGE_ACTIVE_STATUS_POLL', (data) => {
      dispatch({
        type: VoteActions.activatePoll,
        payload: data,
      })
      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'poll', data, updateType: 'poll_active' },
      })
    })

    socket.on('CREATE_POLL', (data) => {
      if (
        data?.poll?.active_status === 'true' ||
        data?.poll?.active_status === true
      ) {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'poll',
            data: data?.poll,
            updateType: 'create_poll',
          },
        })
      }

      dispatch({
        type: VoteActions.createPoll,
        payload: data?.poll,
      })
    })

    //update poll
    socket.on('UPDATE_POLL', (data) => {
      if (
        data?.poll?.active_status === 'true' ||
        data?.poll?.active_status === true
      ) {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'poll',
            data: data?.poll,
            updateType: 'update_poll',
          },
        })
      }

      dispatch({
        type: VoteActions.updatePoll,
        payload: data?.poll,
      })
    })

    socket.on('DELETE_POLL', (data) => {
      dispatch({
        type: VoteActions.deletePoll,
        payload: data.payload,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: {
          type: 'poll',
          data: data.payload,
          updateType: 'delete_poll',
        },
      })
    })

    //rating socket
    socket.on('LOCK_STATUS_RATING', (data) => {
      dispatch({
        type: VoteActions.lockRating,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'rating', data, updateType: 'rating_lock' },
      })
    })

    socket.on('ACTIVE_STATUS_RATING', (data) => {
      dispatch({
        type: VoteActions.activateRating,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'rating', data, updateType: 'rating_active' },
      })
    })

    socket.on('DELETE_RATING_POLL', (data) => {
      dispatch({
        type: VoteActions.deleteRating,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: {
          type: 'rating',
          data,
          updateType: 'delete_rating',
        },
      })
    })

    socket.on('CREATE_RATING_POLL', (data) => {
      if (data?.active_status === 'true' || data?.active_status === true) {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'rating',
            data,
            updateType: 'create_rating',
          },
        })
      }

      dispatch({
        type: VoteActions.createRating,
        payload: data,
      })
    })

    socket.on('UPDATE_RATING_POLL', (data) => {
      if (data?.active_status === true || data?.active_status === 'true') {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'rating',
            data,
            updateType: 'update_rating',
          },
        })
      }
      dispatch({
        type: VoteActions.updateRating,
        payload: data,
      })
    })

    //word cloud socket
    socket.on('LOCK_STATUS_WC', (data) => {
      dispatch({
        type: VoteActions.lockWC,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'wc', data, updateType: 'wc_lock' },
      })
    })

    socket.on('ACTIVE_STATUS_WC', (data) => {
      dispatch({
        type: VoteActions.activateWC,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'wc', data, updateType: 'wc_active' },
      })
    })

    socket.on('DELETE_WORD_CLOUD', (data) => {
      dispatch({
        type: VoteActions.deleteWC,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: {
          type: 'wc',
          data,
          updateType: 'delete_wc',
        },
      })
    })

    socket.on('CREATE_WORD_CLOUD', (data) => {
      if (data?.active_status === 'true' || data?.active_status === true) {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'wc',
            data,
            updateType: 'create_wc',
          },
        })
      }

      dispatch({
        type: VoteActions.createWC,
        payload: data,
      })
    })

    socket.on('UPDATE_WORD_CLOUD', (data) => {
      if (data?.active_status === true || data?.active_status === 'true') {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'wc',
            data,
            updateType: 'update_wc',
          },
        })
      }
      dispatch({
        type: VoteActions.updateWC,
        payload: data,
      })
    })

    socket.on('QUIZ_LOCK_STATUS', (data) => {
      dispatch({
        type: VoteActions.lockQuiz,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'quiz', data, updateType: 'quiz_lock' },
      })
    })

    socket.on('MYQUIZ_ACTIVE_STATUS', (data) => {
      dispatch({
        type: VoteActions.activateQuiz,
        payload: data,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: { type: 'quiz', data, updateType: 'quiz_active' },
      })
      setResetQuizOpt(resetQuizOpt + 1)
    })

    socket.on('ACTIVE_STATUS_QUIZ_QUESTION', (data) => {
      setResetQuizOpt(resetQuizOpt + 1)

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: {
          type: 'quiz',
          data: data.updatedEvent,
          updateType: 'quiz_question',
        },
      })
    })

    socket.on('UPDATE_QUIZ_QUESTION', (data) => {
      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: {
          type: 'quiz',
          data,
          updateType: 'update_quiz_question',
        },
      })
    })

    socket.on('DELETE_QUIZ', (data) => {
      setResetQuizOpt(resetQuizOpt + 1)

      dispatch({
        type: VoteActions.deleteQuiz,
        payload: data.payload,
      })

      dispatch({
        type: VoteActions.updateActiveEvent,
        payload: {
          type: 'quiz',
          data: data.payload,
          updateType: 'delete_quiz',
        },
      })
    })

    socket.on('CREATE_QUIZ', (data) => {
      if (
        data?.quiz?.active_status === 'true' ||
        data?.quiz?.active_status === true
      ) {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'quiz',
            data: data?.quiz,
            updateType: 'create_quiz',
          },
        })
      }

      dispatch({
        type: VoteActions.createQuiz,
        payload: data?.quiz,
      })
    })
    //update quiz
    socket.on('UPDATE_QUIZ', (data) => {
      if (
        data?.quiz?.active_status === 'true' ||
        data?.quiz?.active_status === true
      ) {
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'quiz',
            data: data?.quiz,
            updateType: 'update_quiz',
          },
        })
      }

      dispatch({
        type: VoteActions.updateQuiz,
        payload: data?.quiz,
      })
    })
  }, [])

  return (
    <>
      <div className="pollVote__container">
        {state.activeEvent.type === '' ||
        state.activeEvent.event.length === 0 ? (
          !loading ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <img src={QuestionImg} alt="question" width="100%" />
              <p>There is no active poll or quiz right now</p>
            </Grid>
          ) : (
            <ShimmerCard />
          )
        ) : (
          <>
            {state?.activeEvent?.type === 'poll' && !loading && (
              <VotingPoll app_url={app_url} app_id={app_id} />
            )}
            {state?.activeEvent?.type === 'quiz' && !loading && (
              <VotingQuiz resetOpt={resetQuizOpt} />
            )}
            {state?.activeEvent?.type === 'wc' && !loading && (
              <VotingWC
                wordCloud={state?.activeEvent?.event[0]}
                socket={socket}
                app_id={app_id}
              />
            )}
            {state?.activeEvent?.type === 'rating' && !loading && (
              <VotingRating app_id={app_id} socket={socket} />
            )}
          </>
        )}
      </div>
    </>
  )
}
