import { customAlphabet } from 'nanoid'
import { createContext, useReducer, useMemo } from 'react'
// import { isScreenSharingUid } from '../utils/agoraLiveStreamConfig'
import Record from '../components/images/record.svg'
import GoLive from '../components/images/go-live.svg'
import EndLive from '../components/images/stop-live.svg'
import VideoOff from '../components/images/video-off.svg'
import VideoOn from '../components/images/video-on.svg'
import MicMute from '../components/images/mic-mute.svg'
import MicUnmute from '../components/images/mic-unmute.svg'
import ShareMonitorOff from '../components/images/stop-share.svg'
import ShareMonitor from '../components/images/share-monitor.svg'
import { useContext } from 'react'

const uid2 = customAlphabet('123567890', 7)
const marqeeUid = Number(5 + uid2())
// add marqee uid to window
if (window?.agoraUids) {
  window.agoraUids = [...window.agoraUids, marqeeUid]
} else {
  window.agoraUids = [marqeeUid]
}

const initialIconState = [
  {
    id: 0,
    controllerIcon: <img src={MicMute} alt="mic mute" height={22} width={22} />,
    iconText: 'Unmute',
    type: 'mic',
    status: true,
  },
  {
    id: 1,
    controllerIcon: (
      <img src={VideoOff} alt="video off" height={22} width={22} />
    ),
    iconText: 'Start Video',
    type: 'cam',
    status: false,
  },
  {
    id: 2,
    controllerIcon: (
      <img src={ShareMonitorOff} alt="share off" height={22} width={22} />
    ),
    iconText: 'Share',
    type: 'share',
    status: false,
  },
  {
    id: 3,
    controllerIcon: <img src={Record} alt="record" height={22} width={22} />,
    iconText: 'Record',
    type: 'record',
    status: false,
  },
  {
    id: 4,
    controllerIcon: <img src={GoLive} alt="go live" height={22} width={22} />,
    iconText: 'Go Live',
    type: 'live',
    status: false,
  },
]
const initialState = {
  menuClick: false,
  activeCardMenu: -1,
  layout: {
    type: 'SINGLE_USER',
    isPadding: false,
  },
  allowedUids: [],
  screenShareData: {
    isScreenSharing: false,
    uid: 0,
  },
  iconStatus: initialIconState,
  rtmpCardSelectData: {
    isYoutTubeSelected: false,
    isFacebookSelected: false,
    isTwitchSelected: false,
    isTwitterSelected: false,
    isLinkedInSelected: false,
  },
  accessToken: '',
  marqeeUid,
  isMarqeeSelected: false,
}

export const Actions = {
  toggleMenu: 'TOGGLE_MENU',
  changeCardMenu: 'CHANGE_CARD_MENU',
  changeLayout: 'CHANGE_LAYOUT',
  addAllowedUid: 'ADD_ALLOWED_UID',
  removeAllowedUid: 'REMOVE_ALLOWED_UID',
  addRemotePlayers: 'ADD_REMOTE_PLAYERS',
  removeRemotePlayers: 'REMOVE_REMOTE_PLAYERS',
  screenSharing: 'SCREEN_SHARING',
  resetIconStatus: 'RESET_ICON_STATUS',
  updateRtmpSelectedCardData: 'UPDATE_RTMP_SELECTED_CARD_DATA',
  addAccessToken: 'ADD_ACCESS_TOKEN',
  updateIsMarqeeSelected: 'UPDATE_IS_MARQEE_SELECTED',
  mute: 'MUTE',
  unmute: 'UNMUTE',
  cameraOn: 'CAMERA_ON',
  cameraOff: 'CAMERA_OFF',
  share: 'SHARE',
  unShare: 'UNSHARE',
  startRecording: 'START_RECORDING',
  stopRecording: 'STOP_RECORDING',
  goLive: 'GO_LIVE',
  endLive: 'END_LIVE',
}

export const LiveEventContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.mute:
      return mute(state)
    case Actions.unmute:
      return unmute(state)
    case Actions.cameraOff:
      return cameraOff(state)
    case Actions.cameraOn:
      return cameraOn(state)
    case Actions.unShare:
      return unShare(state)
    case Actions.share:
      return share(state)
    case Actions.stopRecording:
      return stopRecording(state)
    case Actions.startRecording:
      return startRecording(state)
    case Actions.endLive:
      return endLive(state)
    case Actions.goLive:
      return goLive(state)
    case Actions.toggleMenu:
      return {
        ...state,
        menuClick: action.payload,
        activeCardMenu: action.payload === false ? -1 : state.activeCardMenu,
      }
    case Actions.changeCardMenu:
      return { ...state, activeCardMenu: action.payload.id }
    case Actions.changeLayout:
      return {
        ...state,
        layout: {
          type: action.payload.layoutType,
          isPadding: action.payload.isPadding,
        },
      }
    case Actions.addAllowedUid:
      return addAllowedUid(state, action.payload)
    case Actions.removeAllowedUid:
      return removeAllowedUid(state, action.payload)
    case Actions.screenSharing:
      return {
        ...state,
        screenShareData: {
          isScreenSharing: action.payload.isScreenSharing,
          uid: action.payload.uid,
        },
      }
    case Actions.resetIconStatus:
      return {
        ...state,
        iconStatus: initialIconState,
      }
    case Actions.updateRtmpSelectedCardData:
      return {
        ...state,
        rtmpCardSelectData: {
          ...state.rtmpCardSelectData,
          isYoutTubeSelected: action.payload.isYoutTubeSelected,
          //TODO:add more keys
        },
      }
    case Actions.addAccessToken:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      }
    case Actions.updateIsMarqeeSelected:
      return {
        ...state,
        isMarqeeSelected: action.payload,
      }
    default:
      return state
  }
}

const mute = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'mic'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={MicMute} alt="mic mute" height={22} width={22} />
            ),
            iconText: 'Unmute',
            status: true,
          }
        : iconStatus
    ),
  }
}

const unmute = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'mic'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={MicUnmute} alt="mic unmute" height={22} width={22} />
            ),
            iconText: 'Mute',
            status: false,
          }
        : iconStatus
    ),
  }
}

const cameraOff = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'cam'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={VideoOff} alt="video off" height={22} width={22} />
            ),
            iconText: 'Start Video',
            status: false,
          }
        : iconStatus
    ),
  }
}

const cameraOn = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'cam'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={VideoOn} alt="video on" height={22} width={22} />
            ),
            iconText: 'Stop Video',
            status: true,
          }
        : iconStatus
    ),
  }
}

const unShare = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'share'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img
                src={ShareMonitorOff}
                alt="screen share off"
                height={22}
                width={22}
              />
            ),
            iconText: 'Share',
            status: false,
          }
        : iconStatus
    ),
  }
}

const share = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'share'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img
                src={ShareMonitor}
                alt="screen share on"
                height={22}
                width={22}
              />
            ),
            iconText: 'Unshare',
            status: true,
          }
        : iconStatus
    ),
  }
}

const stopRecording = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'record'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={Record} alt="record" height={22} width={22} />
            ),
            iconText: 'Record',
            status: false,
          }
        : iconStatus
    ),
  }
}

const startRecording = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'record'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={Record} alt="record" height={22} width={22} />
            ),
            iconText: 'Stop',
            status: true,
          }
        : iconStatus
    ),
  }
}

const endLive = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'live'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={GoLive} alt="go live" height={22} width={22} />
            ),
            iconText: 'Go Live',
            status: false,
          }
        : iconStatus
    ),
  }
}

const goLive = (state) => {
  return {
    ...state,
    iconStatus: state.iconStatus.map((iconStatus) =>
      iconStatus.type === 'live'
        ? {
            ...iconStatus,
            controllerIcon: (
              <img src={EndLive} alt="go live" height={22} width={22} />
            ),
            iconText: 'End Live',
            status: true,
          }
        : iconStatus
    ),
  }
}

const addAllowedUid = (state, uid) => {
  return {
    ...state,
    allowedUids: [...state.allowedUids, uid],
  }
}
const removeAllowedUid = (state, uid) => {
  return {
    ...state,
    allowedUids: state.allowedUids.filter((allowedUid) => allowedUid !== uid),
  }
}

export const LiveEventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(() => ({ state, dispatch }), [state])

  return (
    <LiveEventContext.Provider value={value}>
      {children}
    </LiveEventContext.Provider>
  )
}
export const useLiveEvent = () => useContext(LiveEventContext)
