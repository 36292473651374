import { useState, useEffect, useRef } from 'react'
import { nanoid } from 'nanoid/async'
import moment from 'moment'
import { isToday, isYesterday } from 'date-fns'
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { SidebarPanel } from '.'
import styles from '../../../styles/streamRightSidebar.module.css'
import { RSTextField } from '../../customComponent/RSTextField'
import { SendIconBtn } from '../../customComponent/SendIconBtn'
import { useSocket } from '../../../context/socket.context'
import { useAuth } from '../../../context/auth.context'
import useMessageSeen from '../../../hooks/useMessageSeen'
import { useChat } from '../../../context/Chat.context'

export const isTodayOrYesterday = (currentDate) => {
  if (isToday(new Date(currentDate))) {
    return 'Today'
  }
  if (isYesterday(new Date(currentDate))) {
    return 'Yesterday'
  }
  return currentDate
}

export default function Chats({ setTab }) {
  const [selectedUser, setSelectedUser] = useState(null)
  const { authUser } = useAuth()
  const { state: users } = useChat()

  const handleClose = () => {
    setSelectedUser(null)
  }

  if (!users) return <p>Loading...</p>
  return (
    <SidebarPanel title="Chats" setTab={setTab}>
      {!selectedUser ? (
        <Box sx={{ height: 'calc(100% - 3rem)', overflow: 'auto' }}>
          {users
            .filter((user) => user.userid !== authUser.id)
            .map((user) => (
              <UserList
                key={user.userid}
                user={user}
                setSelectedUser={setSelectedUser}
              />
            ))}
        </Box>
      ) : (
        <ChatPanel selectedUser={selectedUser} handleClose={handleClose} />
      )}
    </SidebarPanel>
  )
}

// helper function to get unseen message count
export const getUnSeenMsgCount = (messages) => {
  let count = 0
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i]
    if (!message.fromSelf && !message.is_seen) count++
    else break
  }
  return count
}

function UserList({ user, setSelectedUser }) {
  const handleUserSelect = () => {
    setSelectedUser(user)
  }

  let unSeenMsgCount = getUnSeenMsgCount(user.messages)

  return (
    <Box className={styles.UserList} onClick={handleUserSelect}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Box
            className={styles.Badge}
            sx={{ backgroundColor: user.connected ? 'green' : 'lightgray' }}
          ></Box>
        }
      >
        <Badge
          badgeContent={unSeenMsgCount > 0 ? unSeenMsgCount : null}
          color="primary"
        >
          <Avatar
            alt={user.first_name}
            src={user.profile_pic}
            sx={{ width: 30, height: 30 }}
          />
        </Badge>
      </Badge>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <Typography
          fontWeight={600}
          color="#7B6666"
          // width="55%"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {user.first_name} {user.last_name}
        </Typography>
        {/* <Typography
          variant="body2"
          fontSize="small"
          color="rgba(123, 102, 102, 0.4)"
        >
          &#10625; 2:30 PM
        </Typography> */}
      </Box>
    </Box>
  )
}

function ChatPanel({ selectedUser, handleClose }) {
  const socket = useSocket()
  const [message, setMessage] = useState('')
  const { state, dispatch } = useChat()
  const [chats, setChats] = useState([])
  const [unSeenMsgCount, setUnSeenMsgCount] = useState(0)
  const ref = useRef()

  useEffect(() => {
    const data = state.find(
      (user) => user.userid === selectedUser.userid
    ).messages
    setChats(data)
    const count = getUnSeenMsgCount(data)
    setUnSeenMsgCount(count)
  }, [selectedUser.userid, state])

  useMessageSeen(chats, selectedUser.userid, socket)

  const handleMessage = (e) => {
    setMessage(e.target.value)
  }
  const sendMsg = async () => {
    const msg = message.trim()
    if (msg === '') return
    const id = await nanoid()
    const createdAt = new Date().getTime()
    socket.emit('private message', {
      id,
      content: msg,
      to: {
        id: selectedUser.userid,
      },
      create_date: createdAt,
    })
    const chat = {
      id,
      message: msg,
      fromSelf: true,
      create_date: createdAt,
    }
    dispatch({
      type: 'create',
      payload: { chat, from: { id: selectedUser.userid } },
    })
    setMessage('')
    const scrollToHeight = ref.current.scrollHeight
    ref.current.scrollTop = scrollToHeight
  }

  let prevDate = ''
  const chatUI = chats.map((chat) => {
    const currentDate = moment(chat.create_date).format('L')
    let uniqueDate
    if (currentDate !== prevDate) {
      prevDate = currentDate
      uniqueDate = isTodayOrYesterday(currentDate)
    }
    if (chat.fromSelf) {
      return (
        <div key={chat.id}>
          {uniqueDate && <DateSeperator date={uniqueDate} />}
          <SelfChatRow message={chat.message} createdAt={chat.create_date} />
        </div>
      )
    }
    return (
      <div key={chat.id} id={chat.id}>
        {uniqueDate && <DateSeperator date={uniqueDate} />}
        <ChatRow message={chat.message} createdAt={chat.create_date} />
      </div>
    )
  })

  return (
    <>
      <Box className={styles.Chats}>
        <Box className={styles.ChatHeader}>
          <Badge
            badgeContent={unSeenMsgCount > 0 ? unSeenMsgCount : null}
            color="primary"
          >
            <Avatar
              alt={selectedUser.first_name}
              src={selectedUser.profile_pic}
              sx={{ width: 32, height: 32 }}
            />
          </Badge>

          <Typography variant="h6" lineHeight="1.25rem">
            {selectedUser.first_name} {selectedUser.last_name}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} size="small">
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box
        ref={ref}
        sx={{
          height: 'calc(100% - 8rem)',
          overflow: 'auto',
          padding: '0 0.25rem',
        }}
      >
        {chatUI}
      </Box>
      <RSTextField
        size="small"
        multiline
        className={styles.RSTextField}
        placeholder="Type here"
        value={message}
        onChange={handleMessage}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SendIconBtn onClick={sendMsg} />
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

const SelfChatRow = ({ message, createdAt }) => {
  const theme = useTheme()
  return (
    <Box className={styles.SelfChatRow}>
      <Box
        className={styles.SelfChatBubble}
        sx={{
          backgroundColor: `${theme.palette.primary.main}22`,
          '&::before': {
            borderTop: `8px solid ${theme.palette.primary.main}22`,
          },
        }}
      >
        <small>{message}</small>
      </Box>
      <small className={styles.time}>
        {moment(createdAt).format('hh:mm a')}
      </small>
    </Box>
  )
}
const ChatRow = ({ message, createdAt }) => {
  const theme = useTheme()
  return (
    <Box className={styles.ChatRow}>
      <Box
        className={styles.ChatBubble}
        sx={{
          backgroundColor: `${theme.palette.primary.main}22`,
          '&::before': {
            borderTop: `8px solid ${theme.palette.primary.main}22`,
          },
        }}
      >
        <small>{message}</small>
      </Box>
      <small className={styles.time}>
        {moment(createdAt).format('hh:mm a')}
      </small>
    </Box>
  )
}

export const DateSeperator = ({ date }) => {
  return (
    <Box className={styles.DateSeperator}>
      <span></span>
      <small>{date}</small>
      <span></span>
    </Box>
  )
}
