import { useDispatch } from 'react-redux'
import { openSnackbar } from '../actions'

export default function useCopy() {
  const reduxDispatch = useDispatch()

  function copy(url) {
    navigator.clipboard.writeText(url).then(
      () => {
        /* clipboard successfully set */
        reduxDispatch(openSnackbar('info', 'Copied!'))
      },
      () => {
        /* clipboard write failed */
        reduxDispatch(openSnackbar('error', 'Failed to Copy!'))
      }
    )
  }
  return { copy }
}
