import Skeleton from '@mui/material/Skeleton'
import Container from '@mui/material/Container'
import styles from '../../styles/stream.module.css'
import Box from '@mui/material/Box'

function SponsorExhibitorsSkeleton() {
  return (
    <Container sx={{ marginTop: '0 !important' }}>
      <Box className={styles.streamHeaderBox} sx={{ p: 3 }}>
        <Skeleton variant="rectangular" width={210} height={60} />
      </Box>
      <section id="sponsor" className={styles.sponsorSection}>
        <Skeleton variant="rounded" width={512} height={256} />
      </section>
    </Container>
  )
}

export default SponsorExhibitorsSkeleton
