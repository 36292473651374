import { combineReducers } from 'redux'
import pollReducer from './pollReducer'
import quizReducer from './quizReducer'
import activeEvent from './activeEventReducer'
import leaderboard from './leaderboardReducer'
import authReducer from './authReducer'
import eventsReducer from './eventsReducer'
import themeReducer from './themeReducer'
import featureSettingsReducer from './featureSettingsReducer'
import ratingPollReducer from './ratingPollReducer'
import wordCloudReducer from './wordCloudReducer'
import snackbarReducer from './snackbarReducer'
import pptReducer from './pptReducer'
import presentationsReducer from './presentationsReducer'

const allReducers = combineReducers({
  polls: pollReducer,
  quizzes: quizReducer,
  ratingPolls: ratingPollReducer,
  activeEvent,
  leaderboard,
  auth: authReducer,
  events: eventsReducer,
  theme: themeReducer,
  featureSettings: featureSettingsReducer,
  wordCloud: wordCloudReducer,
  snackbar: snackbarReducer,
  ppt: pptReducer,
  presentations: presentationsReducer,
})

export default allReducers
