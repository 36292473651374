import { Link, useLocation } from 'react-router-dom'
import EventIcon from './customComponent/icons/EventIcon'
import LiveHelp from '@mui/icons-material/LiveHelp'
import { useDomain } from '../context/domain.context'
import logoImg from '../webMOBI/img/logo.png'

function Sidebar() {
  let location = useLocation()
  const { brandLogo } = useDomain()

  return (
    <div className="sidebar">
      <Link to="/events">
        {brandLogo ? (
          <img
            src={brandLogo}
            alt="logo"
            width="100%"
            style={{ maxWidth: '80px', marginTop: '0.5rem' }}
          />
        ) : (
          <img
            src={logoImg}
            alt="logo"
            width="100%"
            style={{ maxWidth: '80px', marginTop: '1.375rem' }}
          />
        )}
      </Link>
      <Link to="/events">
        <button
          className={
            'sidebar-option ' +
            (location.pathname.includes('/events')
              ? 'sidebar-option-active'
              : null)
          }
        >
          <EventIcon />
          <span>Event</span>
        </button>
      </Link>
      <Link to="/help" data-testid="help">
        <button
          className={
            'sidebar-option ' +
            (location.pathname === '/help' ? 'sidebar-option-active' : null)
          }
        >
          <LiveHelp fontSize="large" />
          <span>Help Center</span>
        </button>
      </Link>
    </div>
  )
}

export default Sidebar
