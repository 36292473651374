import React, {createContext, useContext, useReducer } from "react";
//Creating data layer
export const StateContext = createContext();
//Wrapping app and provide Data layer
export const StateProvider = ({ reducer, initialState, children }) => (
    <StateContext.Provider value={useReducer(reducer,initialState)}>
        {children}
    </StateContext.Provider>
);
//Pull information from the data layer
export const useStateValue = () => useContext(StateContext);