import { createContext, useState, useEffect } from 'react'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { useMemo } from 'react'

const token = process.env.REACT_APP_FP_TOKEN

export const VisitorContext = createContext('')

export const VisitorProvider = ({ children }) => {
  const [visitorId, setVisitorId] = useState('')

  useEffect(() => {
    let willUnmount = false
    FingerprintJS.load({
      token,
    })
      .then((fp) => fp.get())
      .then((result) => {
        if (!willUnmount) setVisitorId(result?.visitorId)
      })
      .catch((error) => console.error(error))

    return () => {
      willUnmount = true
    }
  }, [])

  const value = useMemo(() => ({ visitorId }), [visitorId])
  return (
    <VisitorContext.Provider value={value}>{children}</VisitorContext.Provider>
  )
}
