import { IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

export const SendIconBtn = (props) => {
  return (
    <IconButton size="small" {...props}>
      <SendIcon fontSize="small" sx={{ transform: 'rotate(-45deg)' }} />
    </IconButton>
  )
}
