import axios from 'axios'

const healthAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
})

const checkAxios = axios.create({
  baseURL: process.env.REACT_APP_CHECK_URL,
})

export { healthAxios, checkAxios }
