import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { checkAxios, healthAxios } from "../../axiosConfig";

import { useTheme, Typography, Divider, Container } from "@mui/material";
import Style from "../../styles/regAttendee.module.css";
import Spotregstyle from "../../styles/spotregister.module.css";

import { getCurrentZoneDateTime } from "../../utils/getCurrentZoneDateTime";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  Button,
} from "@material-ui/core";

import getGlobalSurveyData from "./GlobalSurvey";

const SurveyWeb = () => {
  const [surveyData, setSurveyData] = useState([]);
  const [responses, setResponses] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [appId, setappId] = useState("");
  const [eventdate, seteventdate] = useState("");
  const [checkvalue, setcheckvalue] = useState("");
  const [eventData, seteventData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const theme = useTheme();

  const { app_url } = useParams();

  useEffect(() => {
    const fetchSurveyData = async () => {
      const result = await getGlobalSurveyData(app_url);

      if (result) {
        const { surveyData, appId, eventdate, checkvalue, eventData } = result;

        setappId(appId);
        seteventdate(eventdate);
        setcheckvalue(checkvalue);
        seteventData(eventData);

        setStartDate(getCurrentZoneDateTime(eventData.startdate, "DD.MM.YYYY"));
        setEndDate(getCurrentZoneDateTime(eventData.enddate, "DD.MM.YYYY"));

        const transformedData = surveyData.items.map((item) => ({
          id: item.id,
          label: item.question,
          type:
            item.type === "multiple"
              ? "multiple"
              : item.type === "messagebox"
              ? "open"
              : "single",
          options: item.answer,
          detail: item.detail === "yes",
        }));

        setSurveyData(transformedData);

        const initialResponses = transformedData.reduce((acc, question) => {
          acc[question.id] = {
            answer: question.options.length > 0 ? question.options[0] : "", // Default to first option or empty string if no options
            detail: "",
          };
          return acc;
        }, {});

        setResponses(initialResponses);
      }
    };

    fetchSurveyData();
  }, [app_url]);

  const handleRadioChange = (questionId, event) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], answer: event.target.value },
    }));
  };

  const extractDay = (date) => {
    return date.split(".")[0];
  };

  const extractMonthYear = (date) => {
    const parts = date.split(".");
    const monthNumber = parseInt(parts[1], 10);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${months[monthNumber - 1]} ${parts[2]}`;
  };

  const handleCheckboxChange = (questionId, option, event) => {
    // Ensure the state for this question's answers is an array
    const currentAnswers = Array.isArray(responses[questionId]?.answer)
      ? responses[questionId].answer
      : [];

    const updatedOptions = event.target.checked
      ? [...currentAnswers, option] // Add the option if checked
      : currentAnswers.filter((ans) => ans !== option); // Remove the option if unchecked

    setResponses((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], answer: updatedOptions },
    }));
  };

  const handleDetailChange = (questionId, event) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], detail: event.target.value },
    }));
  };

  const handleSubmit = async () => {
    const formattedQuestions = surveyData.map(({ id, label }) => {
      const response = responses[id];
      return response.detail
        ? [label, response.answer, "detail", response.detail]
        : [label, response.answer];
    });

    const feedback = JSON.stringify(formattedQuestions);

    try {
      console.log({
        feedback: feedback,
        email: email || "anonymous",
        username: name || "anonymous",
        checkvalue: checkvalue,
        appId: appId,
        submissiondate: new Date().toISOString(),
        eventdate: eventdate,
        userid: "anonymous",
        survey_type: "global",
        flag: "update",
      });

      const response = await checkAxios.post(`/api/event/appfeedback`, {
        feedback: feedback,
        email: email || "anonymous",
        username: name || "anonymous",
        checkvalue: checkvalue,
        appId: appId,
        submissiondate: new Date().toISOString(),
        eventdate: eventdate,
        userid: "anonymous",
        survey_type: "global",
        flag: "update",
        default_id: "",
      });

      console.log("Feedback submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <Container>
      <div className={Style.RegMain}>
        <div className={Style.leftSide}>
          <div
            className={Style.eventTicketInfo}
            style={{ backgroundColor: "#E0E0E0", padding: "20px 40px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                color: "white",
              }}
            >
              <img
                src={
                  eventData?.appLoadingImage
                    ? eventData?.appLoadingImage
                    : "https://webmobi.s3.amazonaws.com/nativeapps/devwebmobitechconference2018/download__1_.jpg"
                }
                alt="Event image"
                style={{ borderRadius: "20px", width: "100%", height: "auto" }}
              />

              <h2 style={{ color: "#333333" }}>{eventData?.appName}</h2>

              <div className={Style.About}>
                <p
                  style={{
                    lineHeight: "1.75",
                    letterSpacing: "0.5px",
                    color: "#333333", // Changed from white to dark grey
                  }}
                  dangerouslySetInnerHTML={{
                    __html: eventData?.appDescription,
                  }}
                />
              </div>

              <div
                className={Style.flexContainer}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  className={Style.dateContainer}
                  style={{
                    backgroundColor: "white",
                    padding: "16px 25px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    color="#787A8F"
                    gutterBottom
                  >
                    Start Date
                  </Typography>

                  <Divider />

                  <Typography
                    sx={{ fontSize: 40, textAlign: "center" }}
                    variant="h2"
                    component="div"
                    color={theme.palette.primary.main}
                  >
                    {extractDay(startDate)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                    color="#787A8F"
                  >
                    {extractMonthYear(startDate)}
                  </Typography>
                </div>

                <div
                  className={Style.feeContainer}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    padding: "20px 40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                    borderRadius: "12px",
                    border: "1px solid white",
                  }}
                >
                  <h3
                    style={{ fontSize: "1.1rem", textAlign: "center" }}
                    color="white"
                  >
                    Registration fees
                  </h3>
                  <h2
                    style={{
                      fontSize: "2rem",
                      textAlign: "center",
                      fontWeight: "bolder",
                    }}
                    color="white"
                  >
                    FREE
                  </h2>
                </div>

                <div
                  className={Style.dateContainer}
                  style={{
                    backgroundColor: "white",
                    padding: "16px 25px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                    color="#787A8F"
                    gutterBottom
                  >
                    End Date
                  </Typography>

                  <Divider />

                  <Typography
                    sx={{ fontSize: 40, textAlign: "center" }}
                    variant="h2"
                    component="div"
                    color={theme.palette.primary.main}
                  >
                    {extractDay(endDate)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                    color="#787A8F"
                  >
                    {extractMonthYear(endDate)}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ margin: "5px" }}>
          <Typography
            variant="h4"
            style={{ margin: "20px 0", textAlign: "center" }}
          >
            Survey Feedback
          </Typography>
          {surveyData.map(({ id, label, type, options, detail }) => (
            <div key={id} style={{ marginBottom: "20px" }}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">{label}</FormLabel>

                {type === "single" && (
                  <RadioGroup
                    aria-label={label}
                    name={`question_${id}`}
                    onChange={(e) => handleRadioChange(id, e)}
                    value={responses[id]?.answer || ""}
                  >
                    {options.map((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>
                )}

                {type === "multiple" && (
                  <FormControl component="fieldset" fullWidth>
                    {options.map((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            checked={
                              responses[id]?.answer.includes(option) || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(id, option, e)
                            }
                            name={`question_${id}_${idx}`}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </FormControl>
                )}

                {type === "open" && (
                  <TextField
                    label="Details"
                    onChange={(e) => handleDetailChange(id, e)}
                    variant="outlined"
                    fullWidth
                    value={responses[id]?.detail || ""}
                  />
                )}

                {detail && type !== "open" && (
                  <TextField
                    label="Please specify"
                    onChange={(e) => handleDetailChange(id, e)}
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: "10px" }}
                    value={responses[id]?.detail || ""}
                  />
                )}
              </FormControl>
            </div>
          ))}

          <TextField
            label="Email (optional)"
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            style={{ marginTop: "10px" }}
            placeholder="Enter your email" // Placeholder text instead of showing 'anonymous'
          />
          <TextField
            label="Name (optional)"
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
            style={{ marginTop: "20px" }}
            placeholder="Enter your name" // Placeholder text instead of showing 'anonymous'
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: "20px" }}
          >
            Submit Survey
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default SurveyWeb;
