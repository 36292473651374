import React, { useEffect, useState } from 'react'
import styles from '../../styles/stream.module.css'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Radio, TextField } from '@mui/material'
import getIcon from '../../webMOBI/components/getIcon'
import { useAppFeedBack, useFeedBackCheck } from '../../hooks/streamHook'
import { useDispatch } from 'react-redux'
import { openSnackbar } from '../../actions'
import { useStateValue } from '../StateProvider'
import moment from 'moment'
import StreamMain from './StreamMain'
import { getCurrentZoneDateTime } from '../../utils/getCurrentZoneDateTime'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Dialog } from '@mui/material'

export const StreamSurvey = ({ surveyData, data }) => {
  const [{ user }] = useStateValue()
  const reduxDispatch = useDispatch()
  const { mutateAsync: appFeedBackMutate } = useAppFeedBack()
  const [questions, setQuestions] = useState([])
  const [loading, setLoading] = useState(false)
  const { data: feedBackCheck } = useFeedBackCheck(
    data?.appId,
    sessionStorage.getItem('username') ||
      `${user.first_name} ${user.last_name}`,
    sessionStorage.getItem('email') || user?.email,
    getCurrentZoneDateTime(data.startdate, 'YYYY-MM-DD'),
    sessionStorage.getItem('userId') || user?.userId,
    surveyData?.checkvalue
  )
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (feedBackCheck?.responseString === 'Feedback Submitted') {
      setIsOpen(true)
    }
  }, [feedBackCheck])
  useEffect(() => {
    const surveyQuestions = surveyData?.items.map((data) => ({
      ...data,
      options: data.answer.map((ans) => ({ value: ans, isCorrect: false })),
    }))
    setQuestions(surveyQuestions)
  }, [surveyData])

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleAnswer = (questionId, optionIndex) => {
    setQuestions((ques) =>
      ques.map((question) =>
        question.id === questionId
          ? {
              ...question,
              options:
                question.type === 'single'
                  ? question.options.map((option, i) =>
                      i === optionIndex
                        ? { ...option, isCorrect: !option.isCorrect }
                        : { ...option, isCorrect: false }
                    )
                  : question.options.map((option, i) =>
                      i === optionIndex
                        ? { ...option, isCorrect: !option.isCorrect }
                        : option
                    ),
            }
          : question
      )
    )
  }

  const handleFeedback = (e, questionId) => {
    setQuestions((ques) =>
      ques.map((question) =>
        question.id === questionId
          ? {
              ...question,
              ans: e.target.value,
            }
          : question
      )
    )
  }

  const handleAppFeedBack = async (e) => {
    e.preventDefault()
    const appfeedbackPayload = {
      flag: 'update',
      appId: data?.appId,
      feedback: JSON.stringify(
        questions.map((ques) => {
          let selectedOption = ques.options.filter((option) => option.isCorrect)
          selectedOption = selectedOption.map((option) => option.value)
          return [ques.question, ...selectedOption, 'details', ques.ans]
        })
      ),

      username:
        sessionStorage.getItem('username') ||
        `${user.first_name} ${user.last_name}`,
      email: sessionStorage.getItem('email') || user?.email,
      submissiondate: new Date().getTime(),
      eventdate: getCurrentZoneDateTime(data.startdate, 'YYYY-MM-DD'),
      userid: sessionStorage.getItem('userId') || user?.userId,
      agenda_id: '',
      survey_type: surveyData?.sub_type,
      default_id: surveyData?.checkvalue,
    }
    setLoading(true)
    try {
      const response = await appFeedBackMutate(appfeedbackPayload)
      if (response.response) {
        reduxDispatch(openSnackbar('success', response.responseString))
      } else reduxDispatch(openSnackbar('error', response.responseString))
    } catch (error) {
      console.error(error)
      reduxDispatch(openSnackbar('error', error?.message))
    }
    setLoading(false)
  }

  return (
    <>
      <StreamMain icon={getIcon(surveyData.iconCls)} title={surveyData.caption}>
        {questions.length > 0 && (
          <form onSubmit={handleAppFeedBack}>
            {questions.map((data, i) => (
              <div key={i}>
                <SurveyQuestions
                  question={data}
                  handleAnswer={handleAnswer}
                  handleFeedback={handleFeedback}
                />
              </div>
            ))}

            <div className={styles.surveyBtn}>
              <LoadingButton
                variant="contained"
                size="large"
                type="submit"
                loading={loading}
                disabled={
                  feedBackCheck?.responseString === 'Feedback Submitted'
                }
              >
                SUBMIT
              </LoadingButton>
            </div>
          </form>
        )}
      </StreamMain>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You ave already submitted this feedback.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
const SurveyQuestions = ({ question, handleAnswer, handleFeedback }) => {
  if (question.type === 'messagebox') {
    return (
      <div className={styles.surveyMain}>
        <h5>{question.question}</h5>
        <TextField
          multiline
          fullWidth
          size="small"
          placeholder="Feedback"
          onChange={(e) => handleFeedback(e, question.id)}
        />
      </div>
    )
  } else {
    return (
      <div className={styles.surveyMain}>
        <h5>{question.question}</h5>
        {question.answer.map((val, i) => (
          <div className={styles.radioSelect} key={i}>
            {question.type === 'single' ? (
              <Radio
                checked={val.isCorrect}
                onChange={() => handleAnswer(question.id, i)}
                value={val}
              />
            ) : (
              <Checkbox
                checked={val.isCorrect}
                onChange={() => handleAnswer(question.id, i)}
              />
            )}
            <label htmlFor="first">{val}</label>
          </div>
        ))}
        {question.detail === 'yes' && (
          <TextField
            multiline
            fullWidth
            size="small"
            placeholder="Feedback"
            onChange={(e) => handleFeedback(e, question.id)}
          />
        )}
      </div>
    )
  }
}
