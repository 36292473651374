import {
  addPoll,
  addQuiz,
  addRatingPolls,
  addActiveEvent,
  changeActiveStatusForPoll,
  changeActiveStatusForQuiz,
  changeActiveStatusForQuizQn,
  addWC,
  changeWCActiveStatus,
  openSnackbar,
  updateQuizSlideVote,
  updateSlide,
  addActiveSlide,
  increaseNoOfSlide,
} from '../actions'
import { checkAxios, healthAxios } from '../axiosConfig'
import validator from 'validator'

export const changeActiveStateOfQuizInDb = async (app_id, quiz_id) => {
  const axiosRes = await healthAxios({
    url: `/changequizactivestatus?app_id=${app_id}&quiz_id=${quiz_id}`,
    method: 'GET',
    withCredentials: true,
  })
  return axiosRes
}

export const changeActiveStateOfPollInDb = async (app_id, poll_id) => {
  const res = await healthAxios.post(
    '/changeactivestatus',
    {
      app_id,
      poll_id,
    },
    { withCredentials: true }
  )
  return res
}

export const changeQuizQuestionActiveStatusInDb = async (
  question_id,
  app_id
) => {
  const res = await healthAxios({
    url: `/changequizquesactivestatus?question_id=${question_id}&&appId=${app_id}`,
    method: 'GET',
    withCredentials: true,
  })
  return res
}

//change active status of word cloud
export const changeActiveStatusOfWC = async (id) => {
  const axiosRes = await healthAxios({
    url: '/changewcactivestatus',
    method: 'GET',
    params: {
      id,
    },
    withCredentials: true,
  })
  return axiosRes
}

export const loadEvent = async (appId, didCancel, reduxDispatch) => {
  try {
    const result = await healthAxios.get(`/getpolls?app_id=${appId}`)
    // ------------- adding poll and quiz data-------------------
    if (!didCancel) {
      reduxDispatch(addPoll(result?.data?.Polls))
    }
    // const quizRes = await loadQuizPolls(appId, didCancel, reduxDispatch);
    const quizRes = await healthAxios.get(`/getallquiz?app_id=${appId}`)
    // ------------- adding poll and quiz data-------------------
    if (!didCancel) {
      reduxDispatch(addQuiz(quizRes?.data?.data))
    }

    //-----word cloud data--------
    const wc = await healthAxios({
      url: '/getwordcloud',
      method: 'GET',
      params: {
        app_id: appId,
      },
    })
    if (wc.status === 200) {
      reduxDispatch(addWC(wc?.data?.wordCloud))
    } else {
      reduxDispatch(addWC([]))
    }
    // adding rating polls
    const ratingRes = await healthAxios.get(`/get-rating-polls?app_id=${appId}`)
    // ------------- adding poll and quiz data-------------------
    if (!didCancel) {
      reduxDispatch(addRatingPolls(ratingRes.data.ratings))
    }
    // -------------adding active event if any ------------------
    const activePollEvent = result?.data?.Polls.filter((element, index) => {
      return element.active_status === 'true' || element.active_status === true
    })

    const activeQuizEvent = quizRes?.data?.data.filter((element) => {
      return element.active_status === 'true' || element.active_status === true
    })

    let activeWCEvent = []
    if (wc.status === 200) {
      activeWCEvent = wc?.data?.wordCloud?.filter((word) => {
        return word?.active_status === 'true' || word?.active_status === true
      })
    }

    const activeRatingEvent = ratingRes?.data?.ratings.filter((element) => {
      return element.active_status === 'true' || element.active_status === true
    })

    if (
      activePollEvent.length > 0 &&
      activeQuizEvent.length === 0 &&
      activeRatingEvent.length === 0 &&
      activeWCEvent.length === 0 &&
      !didCancel
    ) {
      reduxDispatch(addActiveEvent('poll', activePollEvent))
    }
    if (
      activePollEvent.length === 0 &&
      activeRatingEvent.length === 0 &&
      activeWCEvent.length === 0 &&
      activeQuizEvent.length > 0 &&
      !didCancel
    ) {
      reduxDispatch(addActiveEvent('quiz', activeQuizEvent))
    }
    if (
      activePollEvent.length === 0 &&
      activeQuizEvent.length === 0 &&
      activeRatingEvent.length === 0 &&
      activeWCEvent.length > 0 &&
      !didCancel
    ) {
      console.log('active word cloud', activeWCEvent)
      reduxDispatch(addActiveEvent('WC', activeWCEvent))
    }
    if (
      activePollEvent.length === 0 &&
      activeQuizEvent.length === 0 &&
      activeWCEvent.length === 0 &&
      activeRatingEvent.length > 0 &&
      !didCancel
    ) {
      reduxDispatch(addActiveEvent('rating', activeRatingEvent))
    }
    if (
      activePollEvent.length === 0 &&
      activeQuizEvent.length === 0 &&
      activeRatingEvent.length === 0 &&
      activeWCEvent.length === 0
    ) {
      reduxDispatch(addActiveEvent('', []))
    }
  } catch (error) {
    console.log(error.message)
    throw new Error('Error while loading event', error.message)
  }
  return true
}

// reload poll data if create and updated

export const loadEventMCQPolls = async (
  appId,
  reduxDispatch,
  activeEventType,
  isDeactivated
) => {
  try {
    const result = await healthAxios.get(`/getpolls?app_id=${appId}`)
    // ------------- adding poll data-------------------
    if (result.data.response) {
      reduxDispatch(addPoll(result.data.Polls))
      // -------------adding active event if any ------------------
      const activePollEvent = result.data.Polls.filter((element, index) => {
        return (
          element.active_status === 'true' || element.active_status === true
        )
      })
      if (activePollEvent.length > 0) {
        reduxDispatch(addActiveEvent('poll', activePollEvent))
      } else if (activeEventType === 'poll' && !isDeactivated) {
        reduxDispatch(addActiveEvent('', []))
      }
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

// reload quiz data if created and updated

export const loadQuizPolls = async (
  appId,
  reduxDispatch,
  activeEventType,
  isDeactivated
) => {
  // <-------------- get quiz:axios call -------------------->
  try {
    const quizRes = await healthAxios.get(`/getallquiz?app_id=${appId}`)
    // ------------- adding quiz data-------------------
    if (quizRes.data.response) {
      reduxDispatch(addQuiz(quizRes.data.data))

      const activeQuizEvent = quizRes.data.data.filter((element) => {
        return (
          element.active_status === 'true' || element.active_status === true
        )
      })
      // console.log(activeEventType, isDeactivated);
      if (activeQuizEvent.length > 0) {
        reduxDispatch(addActiveEvent('quiz', activeQuizEvent))
      } else if (activeEventType === 'quiz' && !isDeactivated) {
        reduxDispatch(addActiveEvent('', []))
      }
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updatePoll = async (poll_id, appId, reduxDispatch) => {
  try {
    console.log(poll_id, appId)
    const axiosRes = await healthAxios.get(
      `/getpollbyid?app_id=${appId}&poll_id=${poll_id}`
    )
    console.log(axiosRes)
    let status = axiosRes.data.Poll[0].active_status
    console.log(status)
    reduxDispatch(changeActiveStatusForPoll(poll_id, status))
    if (status === 'false' || status === false) {
      reduxDispatch(addActiveEvent('', []))
    } else {
      console.log(status)
    }
  } catch (error) {
    console.log('update poll', error.message)
  }
}

// deactivate event if any

export const deactivateEvent = async (
  activeEvent,
  app_id,
  reduxDispatch,
  mySocket,
  activeSlide
) => {
  if (activeEvent.type === 'poll') {
    try {
      const res = await changeActiveStateOfPollInDb(
        app_id,
        activeEvent.event[0].poll_id
      )
      if (res.data.response) {
        mySocket.emit('CHANGE_ACTIVE_STATUS_POLL', {
          pollId: activeEvent.event[0].poll_id,
          status: false,
          roomId: app_id,
        })
        reduxDispatch(
          changeActiveStatusForPoll(activeEvent.event[0].poll_id, false)
        )
        mySocket.emit('CHANGE_ACTIVE_EVENT', {
          type: '',
          event: [],
          roomId: app_id,
        })
        reduxDispatch(addActiveEvent('', []))
      }
    } catch (error) {
      // throw new Error('Error while deactivating multiple choice poll')
      throw error
    }
  } else if (activeEvent.type === 'quiz') {
    // make all question inactive
    const activeQn = activeEvent.event[0].question_array.find(
      (question) => question.isActive === true || question.isActive === 1
    )
    if (activeQn) {
      let updatedEvent = [
        {
          ...activeEvent.event[0],
          question_array: activeEvent.event[0].question_array.map(
            (question) => ({
              ...question,
              isActive: false,
              activated_at: new Date().toISOString(),
            })
          ),
        },
      ]
      try {
        await healthAxios.get(
          `/changequizquesactivestatus?question_id=${activeQn.question_id}&&appId=${app_id}`,
          { withCredentials: true }
        )
        mySocket.emit('ACTIVE_STATUS_QUIZ_QUESTION', {
          quizId: activeEvent.event[0].quiz_id,
          updatedEvent,
          roomId: app_id,
        })
        reduxDispatch(
          changeActiveStatusForQuizQn(
            activeEvent.event[0].quiz_id,
            updatedEvent
          )
        )
        // update slide quiz question active status
        if (activeSlide && Object.keys(activeSlide).length) {
          reduxDispatch(
            updateQuizSlideVote({
              quiz_id: activeEvent.event[0].quiz_id,
              question_id: activeQn.question_id,
              isActive: false,
            })
          )
        }
      } catch (error) {
        throw error
      }
    }
    try {
      await changeActiveStateOfQuizInDb(app_id, activeEvent.event[0].quiz_id)
      mySocket.emit('MYQUIZ_ACTIVE_STATUS', {
        quizId: activeEvent.event[0].quiz_id,
        status: false,
        roomId: app_id,
      })
      reduxDispatch(
        changeActiveStatusForQuiz(activeEvent.event[0].quiz_id, false)
      )
      mySocket.emit('CHANGE_ACTIVE_EVENT', {
        type: '',
        event: [],
        roomId: app_id,
      })
      reduxDispatch(addActiveEvent('', []))
    } catch (error) {
      throw error
    }
  } else if (activeEvent.type === 'rating') {
    try {
      const res = await healthAxios.get(
        `/change-rating-status?_id=${activeEvent.event[0]._id}`,
        { withCredentials: true }
      )
      if (res.data.response) {
        mySocket.emit('ACTIVE_STATUS_RATING', {
          roomId: res.data.rating?.app_id,
          payload: res.data.rating,
        })
        reduxDispatch({
          type: 'UPDATE_RATING_STATUS',
          payload: res.data.rating,
        })
        mySocket.emit('CHANGE_ACTIVE_EVENT', {
          type: '',
          event: [],
          roomId: app_id,
        })
        reduxDispatch(addActiveEvent('', []))
      }
    } catch (error) {
      throw error
    }
  } else if (activeEvent.type === 'WC') {
    try {
      //deactivate wordcloud in db
      const axiosRes = await changeActiveStatusOfWC(activeEvent.event[0].id)
      if (axiosRes?.status === 201) {
        //update redux store
        mySocket.emit('ACTIVE_STATUS_WC', {
          roomId: axiosRes?.data?.app_id,
          id: axiosRes?.data?.id,
          status: axiosRes?.data?.status,
        })
        reduxDispatch(
          changeWCActiveStatus(axiosRes?.data?.id, axiosRes?.data?.status)
        )
        mySocket.emit('CHANGE_ACTIVE_EVENT', {
          type: '',
          event: [],
          roomId: app_id,
        })
        reduxDispatch(addActiveEvent('', []))
      }
    } catch (error) {
      throw error
    }
  }
}

// remove auth and localstorage data
export const logoutOnSessionExpire = (reduxDispatch, dispatch, history) => {
  window.localStorage.removeItem('persist:root')
  window.sessionStorage.removeItem('location')
  // window.localStorage.removeItem('from')
  dispatch({
    type: 'SET_USER',
    user: null,
  })
  reduxDispatch(openSnackbar('error', 'Session expired. Please login again.'))

  history.replace('/')
}

export const uploadImg = async (appUrl, user_id, imgData, reduxDispatch) => {
  const axiosRes = await checkAxios({
    url: '/event/uploadmisc',
    method: 'POST',
    data: {
      appUrl: appUrl ?? 'slide image',
      filedata: imgData.base64Data,
      contenttype: imgData.type,
      file_name: imgData.name,
      userid: user_id,
    },
  })
  let { response, responseString } = axiosRes.data
  if (!response) {
    reduxDispatch(openSnackbar('error', responseString))
    return ''
  }
  return axiosRes?.data?.responseString?.Location
}

export const createContentSlide = async (
  appUrl,
  app_id,
  user_id,
  presentation_id,
  slideData,
  reduxDispatch,
  socket
) => {
  let body = {
    app_id,
    user_id,
    presentation_id,
    ...slideData,
  }
  if (body?.imgData?.name && body?.imgData?.type && body?.imgData?.base64Data) {
    let newUrl = await uploadImg(appUrl, user_id, body.imgData, reduxDispatch)
    if (!newUrl || !validator.isURL(newUrl)) {
      reduxDispatch(openSnackbar('error', 'Invalid Image url'))
      return
    }
    body.url = newUrl
    body.imgData = null
  }
  try {
    const res = await healthAxios.post('/createnewslide', body, {
      withCredentials: true,
    })
    if (res.status === 201) {
      let { message, slide } = res.data
      socket.emit('ADD_NEW_SLIDE', {
        roomId: app_id,
        payload: slide,
      })

      reduxDispatch(updateSlide({ ...slide, update: true }))
      reduxDispatch(addActiveSlide({ ...slide, update: true }))
      // update no of slide for slideComponent
      reduxDispatch(increaseNoOfSlide(presentation_id))
      reduxDispatch(openSnackbar('success', message))
    }
  } catch (error) {
    reduxDispatch(
      openSnackbar(
        'error',
        error?.response?.data.message || 'Failed to create slide'
      )
    )
  }
}
