import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useChat } from '../context/Chat.context'
import { useDiscussion } from '../context/Discussion.context'

const useChatEffect = (socket, appId) => {
  const queryClient = useQueryClient()
  const { dispatch } = useDiscussion()
  const { dispatch: dispatchChat } = useChat()

  useEffect(() => {
    if (!appId) return
    // on connect or reconnect
    socket.on('connect', () => {
      console.log('socket reconnected: ', socket.id)
      socket.emit('joinForChat')
      socket.emit('joinForDiscussion', socket.auth.appId)
      socket.emit('QandA_event', appId)
      socket.emit('ADMIN_ROOM', appId)
    })
  }, [socket, appId])

  useEffect(() => {
    let ignore = false

    socket.on('users', (users) => {
      // console.log('users', users)
      const getUserData = (userId) => {
        const user = users.find((user) => user.userId === userId)
        if (user) {
          return {
            ...user,
            messages: user.messages.map((msg) =>
              msg.sender_id === socket.auth.userId
                ? {
                    ...msg,
                    message: msg.message_body,
                    fromSelf: true,
                    create_date: Number(msg.create_date),
                  }
                : {
                    ...msg,
                    message: msg.message_body,
                    fromSelf: false,
                    create_date: Number(msg.create_date),
                  }
            ),
          }
        }
        return { messages: [] }
      }
      const userData = queryClient.getQueryData('attendeeData')
      const chats =
        userData &&
        userData.users?.map((user) => ({
          userid: user.userid,
          first_name: user.first_name,
          last_name: user.last_name,
          profile_pic: user.profile_pic,
          ...getUserData(user.userid),
        }))
      if (!ignore) dispatchChat({ type: 'add', payload: chats })
    })

    socket.on('discussion messages', (messages) => {
      if (!ignore) dispatch({ type: 'add', payload: messages })
    })

    socket.on('private message', ({ id, content, from, to, create_date }) => {
      const chat = {
        id,
        message: content,
        fromSelf: from.id === socket.auth.userId ? true : false,
        create_date,
      }
      if (!ignore) {
        dispatchChat({
          type: 'create',
          payload: { chat, from: from.id === socket.auth.userId ? to : from },
        })
      }
    })

    socket.on('update seen', ({ id, from, to }) => {
      if (!ignore) {
        dispatchChat({
          type: 'seen',
          payload: {
            id,
            userId: from.id === socket.auth.userId ? to.id : from.id,
          },
        })
      }
    })

    socket.on('group discussion', ({ id, content, from, create_date }) => {
      if (!ignore)
        dispatch({
          type: 'create',
          payload: { id, message: content, from, create_date },
        })
    })

    socket.on('user connected', ({ userId, connected }) => {
      if (!ignore) {
        dispatchChat({ type: 'connected', payload: { userId, connected } })
      }
    })

    socket.on('user disconnected', ({ userId }) => {
      if (!ignore) {
        dispatchChat({
          type: 'connected',
          payload: { userId, connected: false },
        })
      }
    })

    socket.on('disconnect', () => {
      if (!ignore) {
        dispatchChat({
          type: 'connected',
          payload: { userId: socket.auth.userId, connected: false },
        })
      }
    })
    return () => {
      ignore = true
    }
  }, [socket, queryClient, dispatch, dispatchChat])
}
export default useChatEffect
