export const addPoll = (pollData) => {
  return {
    type: 'ADD_POLL',
    payload: pollData,
  }
}

export const createNewPoll = (pollData) => {
  return {
    type: 'CREATE_NEW_POLL',
    payload: pollData,
  }
}

export const addQuiz = (quizData) => {
  return {
    type: 'ADD_QUIZZES',
    payload: quizData,
  }
}

export const addAQuiz = (payload) => {
  return {
    type: 'ADD_QUIZ',
    payload,
  }
}

export const addRatingPolls = (ratings) => {
  return {
    type: 'ADD_RATING_POLL',
    payload: ratings,
  }
}

export const changeLockStatusForQuiz = (quiz_id, status) => {
  return {
    type: 'QUIZ_LOCK_STATUS',
    payload: { quiz_id, status },
  }
}

export const changeActiveStatusForQuiz = (quiz_id, status) => {
  return {
    type: 'QUIZ_ACTIVE_STATUS',
    payload: { quiz_id, status },
  }
}
export const changeActiveStatusForQuizQn = (quiz_id, updatedQuiz) => {
  return {
    type: 'QN_STATUS_UPDATE',
    payload: { quiz_id, updatedQuiz },
  }
}
export const changeLockStatusForPoll = (poll_id, status) => {
  return {
    type: 'POLL_LOCK_STATUS',
    payload: { poll_id, status },
  }
}

export const changeActiveStatusForPoll = (poll_id, status) => {
  return {
    type: 'POLL_ACTIVE_STATUS',
    payload: { poll_id, status },
  }
}

export const addActiveEvent = (type, event) => {
  return {
    type: 'ADD_ACTIVE_EVENT',
    payload: {
      type,
      event,
    },
  }
}

export const addLiveQuizQuestion = (payload) => {
  return {
    type: 'SET_LIVE_QUESTION',
    payload: payload,
  }
}

// leaderboard action creater

export const setLeaderboardData = (payload) => {
  return {
    type: 'SET_LIVE_LEADERBOARD',
    payload,
  }
}

//reset leader board data in leaderboard reducer state
export const resetLeaderBoardData = () => {
  return {
    type: 'RESET_LIVE_LEADERBOARD',
  }
}

export const updateQuizQuestion = (quizId, questionId, status) => {
  return {
    type: 'UPDATE_QUIZ_QUESTION',
    payload: { questionId, quizId, status },
  }
}

//update active events
export const updateActiveEvents = (votes) => {
  return {
    type: 'UPDATE_ACTIVE_EVENT',
    payload: {
      votes,
    },
  }
}
export const updateQuizActiveEvent = (payload) => {
  return {
    type: 'UPDATE_QUIZ_ACTIVE_EVENT',
    payload,
  }
}

export const updatePollData = (pollData) => {
  return {
    type: 'UPDATE_POLL_DATA',
    payload: {
      pollData,
    },
  }
}

//change poll order action
export const changePollOrder = (pollData) => {
  return {
    type: 'CHANGE_POLL_ORDER',
    payload: {
      pollData,
    },
  }
}

//change quiz order action
export const changeQuizOrder = (quizData) => {
  return {
    type: 'CHANGE_QUIZ_ORDER',
    payload: {
      quizData,
    },
  }
}

//change rating order action
export const changeRatingOrder = (ratingData) => {
  return {
    type: 'CHANGE_RATING_ORDER',
    payload: {
      ratingData,
    },
  }
}

//change wordcloud order action
export const changeWCOrder = (wcData) => {
  return {
    type: 'CHANGE_WC_ORDER',
    payload: {
      wcData,
    },
  }
}

//update quiz data from socket
export const updateQuizData = (payload) => {
  return {
    type: 'UPDATE_QUIZ_DATA',
    payload,
  }
}

export const setEventsData = (events) => {
  return {
    type: 'SET_EVENTS',
    payload: events,
  }
}

export const deleteEvent = (app_id) => {
  return {
    type: 'DELETE_EVENT',
    payload: app_id,
  }
}

export const setEventData = (event) => {
  return {
    type: 'SET_EVENT',
    payload: event,
  }
}

export const setTheme = (themeData) => {
  return {
    type: 'SET_THEME',
    payload: themeData,
  }
}

// --------------- word cloud actions ---------
export const addWC = (wc) => {
  return {
    type: 'ADD_WC',
    payload: wc,
  }
}

export const createWC = (wc) => {
  return {
    type: 'CREATE_WC',
    payload: wc,
  }
}

export const deleteWc = (id) => {
  return {
    type: 'DELETE_WC',
    payload: id,
  }
}

export const changeWCLockStatus = (id, status) => {
  return {
    type: 'CHANGE_WC_LOCK_STATUS',
    payload: { id, status },
  }
}

export const changeWCActiveStatus = (id, status) => {
  return {
    type: 'CHANGE_WC_ACTIVE_STATUS',
    payload: { id, status },
  }
}

export const updateWordCloud = (payload) => {
  return {
    type: 'UPDATE_WC',
    payload,
  }
}

export const openSnackbar = (type, message) => {
  return {
    type: 'OPEN_SNACKBAR',
    payload: {
      type,
      message,
    },
  }
}

export const closeSnackbar = () => {
  return {
    type: 'CLOSE_SNACKBAR',
  }
}

export const resetPoll = (payload) => ({
  type: 'RESET_POLL',
  payload,
})

export const resetActiveEvent = (payload) => ({
  type: 'RESET_ACTIVE_EVENT',
  payload,
})

export const updateQuiz = (payload) => ({
  type: 'UPDATE_QUIZ',
  payload,
})

// payload should be as array
export const addSlides = (payload) => ({
  type: 'ADD_SLIDES',
  payload,
})

// payload should be as object
export const addSlide = (payload) => ({
  type: 'ADD_SLIDE',
  payload,
})

// payload should be as object
export const updateSlide = (payload) => ({
  type: 'UPDATE_SLIDE',
  payload,
})

export const updateSlideData = (payload) => ({
  type: 'UPDATE_SLIDE_DATA',
  payload,
})

// payload should be as slide id
export const deleteSlide = (payload) => ({
  type: 'DELETE_SLIDE',
  payload,
})

export const addActiveSlide = (payload) => ({
  type: 'ADD_ACTIVE_SLIDE',
  payload,
})
// payload should be poll_id or quiz id and active_status
export const updateSlideActiveStatus = (id, active_status) => ({
  type: 'UPDATE_SLIDE_ACTIVE_STATUS',
  payload: { id, active_status },
})

export const updateSlideVote = (payload) => ({
  type: 'UPDATE_SLIDE_VOTE',
  payload,
})

export const updateQuizSlideVote = (payload) => ({
  type: 'UPDATE_QUIZ_SLIDE_VOTE',
  payload,
})
export const deactivatePollSlides = () => ({
  type: 'DEACTIVATE_POLL_SLIDES',
})

export const addPpts = (payload) => ({
  type: 'ADD_PPTS',
  payload,
})

export const createPpt = (payload) => ({
  type: 'CREATE_PPT',
  payload,
})

export const updatePpt = (payload) => ({
  type: 'UPDATE_PPT',
  payload,
})

// payload should be ppt id
export const deletePPT = (payload) => ({
  type: 'DELETE_PPT',
  payload,
})

export const addActivePpt = (payload) => ({
  type: 'ADD_ACTIVE_PPT',
  payload,
})

// payload should be ppt id
export const increaseNoOfSlide = (id, num) => ({
  type: 'INCREASE_NO_OF_SLIDE',
  payload: {
    id,
    num: num ?? 1,
  },
})

// payload should be ppt id
export const decreaseNoOfSlide = (payload) => ({
  type: 'DECREASE_NO_OF_SLIDE',
  payload,
})

// Q&A actions

export const addQnAs = (payload) => ({
  type: 'ADD_QNAS',
  payload,
})

export const createQnA = (payload) => ({
  type: 'CREATE_QNA',
  payload,
})

export const updateQnA = (payload) => ({
  type: 'UPDATE_QNA',
  payload,
})

export const deleteQnA = (payload) => ({
  type: 'DELETE_QNA',
  payload,
})

export const updateFeatureSettings = (payload) => ({
  type: 'UPDATE_FEATURE_SETTINGS',
  payload,
})
