import { useCallback } from 'react'
import { healthAxios } from '../axiosConfig'
import { useStateValue } from '../components/StateProvider'

export default function usePlanInfo() {
  const [, dispatch] = useStateValue()

  const getPlanInfo = useCallback(
    async (userRes) => {
      const planRes = await healthAxios({
        url: '/plan-information',
        method: 'POST',
        data: { email: userRes.data.data.email },
        withCredentials: true,
      })
      dispatch({
        type: 'SET_USER',
        user: {
          token: userRes.data.data.token,
          userId: userRes.data.data.event_user_id,
          email: userRes.data.data.email,
          first_name: userRes.data.data.first_name,
          last_name: userRes.data.data.last_name,
          username: userRes.data.data.first_name,
          planType: userRes.data.data.planType,
          dashboardUserId: userRes.data.data.dashboard_user_id,
          profile_pic: userRes.data.data.profile_pic,
          planDetails: planRes.data.planData,
        },
      })
      // initialize pendo
      const pendo = window.pendo
      pendo.initialize({
        visitor: {
          id: userRes.data.data.dashboard_user_id,
        },

        account: {
          id: userRes.data.data.dashboard_user_id,
        },
      })
    },
    [dispatch]
  )
  return { getPlanInfo }
}
