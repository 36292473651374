import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createQnA, getQnA } from '../apis/qna.api'

export const useQnAFetch = (appId) => {
  return useQuery('QnAs', () => getQnA(appId), {
    refetchOnWindowFocus: false,
  })
}

export const useCreateQnA = () => {
  const queryClient = useQueryClient()
  return useMutation(createQnA, {
    onSuccess: (data) => {
      queryClient.setQueryData('QnAs', (state) => {
        const isExistQnA =
          state[state.length - 1].question_id === data.question_id
        if (isExistQnA) {
          return state
        }
        return [...state, data]
      })
    },
  })
}

export const useQnA = () => {
  const queryClient = useQueryClient()

  const create = useCallback(
    (payload) => {
      queryClient.setQueryData('QnAs', (data) => {
        const isExistQnA =
          data[data.length - 1].question_id === payload.question_id
        if (isExistQnA) {
          return data
        }
        return [...data, payload]
      })
    },
    [queryClient]
  )

  const update = useCallback(
    (payload) => {
      queryClient.setQueryData('QnAs', (data) =>
        data.map((qna) =>
          qna.question_id === payload.question_id ? { ...qna, ...payload } : qna
        )
      )
    },
    [queryClient]
  )

  const _delete = useCallback(
    (payload) => {
      queryClient.setQueryData('QnAs', (data) =>
        data.filter((qna) => qna.question_id !== payload.question_id)
      )
    },
    [queryClient]
  )

  return { create, update, _delete }
}
