import { healthAxios } from '../axiosConfig'

export const getQnA = async (appId) => {
  try {
    const { data } = await healthAxios({
      url: `/getallquestions?app_id=${appId}`,
      method: 'GET',
      withCredentials: true,
    })
    return data.questions
  } catch (error) {
    throw error
  }
}

export const createQnA = async (payload) => {
  try {
    const { data } = await healthAxios({
      url: '/createquestion',
      method: 'POST',
      data: {
        ...payload,
        no_of_question: Math.floor(new Date().getTime() / 1000),
      },
    })
    return data.data
  } catch (error) {
    throw error
  }
}
