import { useContext, createContext, useEffect } from 'react'
import io from 'socket.io-client'

const serverUrl = `${process.env.REACT_APP_SOCKET_SERVER_URL}`

const mySocket = io(serverUrl, {
  transports: ['websocket'],
  withCredentials: true,
  autoConnect: false,
})

const SocketContext = createContext({})

export const SocketProvider = (props) => {
  useEffect(() => {
    mySocket.on('connection', (data) => {
      console.log(`socket say: `, data)
    })
    return () => {
      mySocket.disconnect()
      mySocket.off()
    }
  }, [])

  return (
    <SocketContext.Provider value={mySocket}>
      {props.children}
    </SocketContext.Provider>
  )
}

export const useSocket = () => useContext(SocketContext)
