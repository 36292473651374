import { createContext, useState, useEffect, useMemo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { healthAxios } from '../axiosConfig'
import { openSnackbar } from '../actions'

export const DomainContext = createContext('')

const domains = ['https://app.webmobi.com', 'https://app.ngage.ai']

export const DomainProvider = ({ children }) => {
  const [clientUrl, setClientUrl] = useState('')
  const [brandLogo, setBrandLogo] = useState('')
  const [brandName, setBrandName] = useState('')
  const reduxDispatch = useDispatch()

  // domain check side effect
  useEffect(() => {
    //get domain name
    const domainName = window.location.hostname
    let link;
    if (process.env.REACT_APP_NODE_ENV === 'dev') {
      link = `http://${domainName}`
    } else {
      link = `https://${domainName}`
    }

    //added this domain
    if (domains.includes(link)) {
      setClientUrl(link)
      return
    }

    if (
      process.env.REACT_APP_CLIENT_URL === link ||
      link === 'http://localhost'
    ) {
      setClientUrl(process.env.REACT_APP_CLIENT_URL)
      return
    }

    //api call to validate domain name
    healthAxios
      .get('/get-validate-domain-name', {
        params: {
          domainName: link,
        },
      })
      .then((response) => {
        //store domain name in the context
        if (response.status === 200) {
          const {
            name,
            brandLogo,
            customDomain: domainName,
          } = response?.data?.domainInfo
          setClientUrl(domainName)
          setBrandLogo(brandLogo)
          setBrandName(name)
        }
      })
      .catch((error) => {
        setClientUrl(process.env.REACT_APP_CLIENT_URL)
        reduxDispatch(
          openSnackbar(
            'error',
            error?.response?.data?.message ?? error?.message
          )
        )
        // redirect to the main application
        window.location.href = process.env.REACT_APP_CLIENT_URL
      })
  }, [reduxDispatch])

  const value = useMemo(
    () => ({ clientUrl, brandLogo, brandName }),
    [clientUrl, brandLogo, brandName]
  )
  return (
    <DomainContext.Provider value={value}>{children}</DomainContext.Provider>
  )
}

export const useDomain = () => useContext(DomainContext)
