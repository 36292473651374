import { Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { openSnackbar } from '../../../actions'
import { healthAxios } from '../../../axiosConfig'
import { useAuth } from '../../../context/auth.context'
import { useStateValue } from '../../StateProvider'

export const LogoutMenu = ({ anchorEl, setAnchorEl }) => {
  const { app_url } = useParams()
  const history = useHistory()
  const open = Boolean(anchorEl)
  const reduxDispatch = useDispatch()
  const [{ user }, dispatch] = useStateValue()
  const [isLoading, setIsLoading] = useState(false)
  const { setAuthUser } = useAuth()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    // logout from main app
    if (user) {
      try {
        setIsLoading(true)
        const axiosRes = await healthAxios({
          url: '/logout',
          withCredentials: true,
        })
        if (axiosRes.status === 200) {
          setAuthUser((user) => ({ ...user, isAuthenticated: false }))
          dispatch({
            type: 'SET_USER',
            user: null,
          })
          window.localStorage.removeItem('persist:root')
          window.sessionStorage.removeItem('location')
          window.localStorage.removeItem('from')
          window.localStorage.removeItem('presentationData')
          history.replace(`/${app_url}/stream/login`)
        }
      } catch (error) {
        console.log(error)
        reduxDispatch(openSnackbar('error', 'Failed to logout'))
        setIsLoading(false)
      }
    } else {
      setAuthUser((user) => ({ ...user, isAuthenticated: false }))
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userId')
      sessionStorage.removeItem('email')
      history.replace(`/${app_url}/stream/login`)
    }
  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleLogout} disabled={isLoading}>
        Logout
      </MenuItem>
    </Menu>
  )
}
