import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share'

export default function Social({ shareLink }) {
  return (
    <div className="social">
      <div className="whatsapp">
        <WhatsappShareButton url={shareLink}>
          <WhatsappIcon round={true} size={30} />
        </WhatsappShareButton>
      </div>
      <div className="linkedin">
        <LinkedinShareButton url={shareLink}>
          <LinkedinIcon round={true} size={30} />
        </LinkedinShareButton>
      </div>
      <div className="facebook">
        <FacebookShareButton url={shareLink}>
          <FacebookIcon round={true} size={30} />
        </FacebookShareButton>
      </div>
    </div>
  )
}
