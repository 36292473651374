export const initialState = {
  user: null,
  event: null,
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      }
    case 'SET_EVENT':
      return {
        ...state,
        event: action.event,
      }
    default:
      return { ...state }
  }
}
export default reducer
