import { createContext, useContext, useMemo, useReducer } from 'react'

const DiscussionContext = createContext([])

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return action.payload.map(
        ({ id, message, sender_id, sender_name, create_date }) => ({
          id,
          message,
          from: { id: sender_id, name: sender_name },
          create_date,
        })
      )

    case 'create':
      return [...state, action.payload]
    default:
      console.log('Invalid action type')
      break
  }
}

export const DiscussionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, [])

  const value = useMemo(() => ({ state, dispatch }), [state])
  return (
    <DiscussionContext.Provider value={value}>
      {children}
    </DiscussionContext.Provider>
  )
}

export const useDiscussion = () => useContext(DiscussionContext)
