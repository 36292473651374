import { checkAxios, healthAxios } from '../axiosConfig'

export const getTickets = async (app_id) => {
  try {
    const res = await healthAxios.get('/ticket/get', {
      params: {
        app_id,
      },
    })
    if (res.status === 200) return res.data.tickets
  } catch (error) {
    console.log(error.message)
    throw error
  }
  return []
}

export const checkoutTicket = (payload) => {
  return healthAxios({
    method: 'POST',
    url: '/ticket/checkout',
    headers: { 'Content-Type': 'application/json' },
    data: payload,
  })
}

export const createTicket = (payload) => {
  return healthAxios({
    method: 'POST',
    url: '/ticket/create',
    headers: { 'Content-Type': 'application/json' },
    data: payload,
    withCredentials: true,
  })
}
export const updateTicket = (payload) => {
  return healthAxios({
    method: 'POST',
    url: '/ticket/update',
    headers: { 'Content-Type': 'application/json' },
    data: payload,
    withCredentials: true,
  })
}

export const updateTicketAvailable = (payload) => {
  return healthAxios({
    method: 'POST',
    url: '/ticket/update-available',
    headers: { 'Content-Type': 'application/json' },
    data: payload,
  })
}

export const deleteTicket = async (payload) => {
  return healthAxios({
    method: 'POST',
    url: '/ticket/delete',
    headers: { 'Content-Type': 'application/json' },
    data: payload,
    withCredentials: true,
  })
}

export const updateTicketStatus = async (statusUpdateTicketPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/add_tickets',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...statusUpdateTicketPayload,
      tickets_array: JSON.stringify(statusUpdateTicketPayload.tickets_array),
    },
  })
  const payload = {
    ...statusUpdateTicketPayload,
    ...statusUpdateTicketPayload.tickets_array[0],
    ticket_id: statusUpdateTicketPayload.tickets_array[0].ticket_id,
    title: statusUpdateTicketPayload.tickets_array[0].ticket_name,
  }
  delete payload.tickets_array
  return {
    response: data.response,
    payload: payload,
    ticket_id: payload.ticket_id,
  }
}

// **************Form Questions******************//
export const getFormQues = async (appId) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/event/registration_questions?appid=${appId}`,
  })
  // console.log('getAppDataJson', data)
  return data.responseString
}
// *************Add Heading****************
export const addHeading = async (addHeadingPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/register_ques',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...addHeadingPayload,
    },
  })

  return { ...data, payload: addHeadingPayload }
}

// ***********Create Edit Form Questions
export const createEditFormQuestions = async (
  createEditFormQuestionsPayload
) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/register_ques',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...createEditFormQuestionsPayload,
      answer: JSON.stringify(createEditFormQuestionsPayload.answer),
    },
  })

  return { ...data, payload: createEditFormQuestionsPayload }
}

export const deleteQuestion = async (delPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/register_ques',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...delPayload,
    },
  })
  return data
}
//********Get Cupon Code*********** */

export const getCupons = async (appId) => {
  try {
    const { data } = await healthAxios({
      method: 'GET',
      url: `/ticket-coupons?appId=${appId}`,
      withCredentials: true,
    })
    return data.coupons
  } catch (error) {
    throw error
  }
}

// *********Create Cupan Code**************
export const createCupon = async (createCuponPayload) => {
  const { data } = await healthAxios({
    method: 'POST',
    url: '/ticket-coupon',
    headers: { 'Content-Type': 'application/json' },
    data: createCuponPayload,
    withCredentials: true,
  })
  return data
}

// *********Delete Cupan Code**************
export const deleteCupon = async (deleteCuponPayload) => {
  const { data } = await healthAxios({
    method: 'POST',
    url: '/delete-ticket-coupon',
    headers: { 'Content-Type': 'application/json' },
    data: deleteCuponPayload,
    withCredentials: true,
  })
  return data
}

//********Get Formdata*********** */

export const getFormData = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/registration_response?appid=${appId}`,
    })
    return data.responseString
  } catch (error) {
    throw error
  }
}
// get reports
export const getReportData = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/reportpdf?appid=${appId}`,
    })
    return data.responseString
  } catch (error) {
    throw error
  }
}

// get payment data
export const getPaymentData = async (app_id) => {
  try {
    const { data } = await healthAxios({
      method: 'GET',
      url: '/ticket/payment',
      params: {
        app_id,
      },
      withCredentials: true,
    })
    return data.payments
  } catch (error) {
    throw error
  }
}

export const refund = async (payload) => {
  return healthAxios.post('/stripe/refund', payload, { withCredentials: true })
}
