import { useContext } from 'react'
import { IconButton, Box, Container } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import styles from '../../styles/stream.module.css'
import { DrawerContext } from './Stream'
import { UserAvatarWithBadge } from './rightSidebar'

const StreamMain = ({ icon, title, children }) => {
  const { handleOpenDrawer, handleExpand } = useContext(DrawerContext)
  return (
    <Container>
      <Box className={styles.streamHeaderBox}>
        <IconButton
          onClick={handleOpenDrawer}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <span className={styles.icon}>{icon}</span>
        <h1>{title}</h1>
        <IconButton
          sx={{ display: { xs: 'flex', sm: 'none' } }}
          size="small"
          onClick={handleExpand}
        >
          <UserAvatarWithBadge userImg={''} size="large" />
        </IconButton>
      </Box>
      <Box className={styles.streamContent}>{children}</Box>
    </Container>
  )
}

export default StreamMain
