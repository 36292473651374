import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import '../../styles/shimmerCard.css'

export default function ShimmerCard() {
  return (
    <div className="shimmerCard__container">
      <div className="shimmerCard__body">
        <Skeleton height="30px" width="200px" animation="wave" />
        <Skeleton height="60px" animation="wave" />
        <Skeleton height="60px" animation="wave" />
        <Skeleton height="60px" animation="wave" />
        <div className="shimmerCard__center">
          <Skeleton height="45px" width="70px" animation="wave" />
        </div>
      </div>
    </div>
  )
}
