import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import '../../styles/rightSideBar.css'
import { useStateValue } from '../StateProvider'
import PersonIcon from '@mui/icons-material/Person'
import { IconButton } from '@mui/material'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import Button from '@mui/material/Button'
import { checkAxios, healthAxios } from '../../axiosConfig'
import { useSelector } from 'react-redux'
import Loader from '../customComponent/Loader'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default function ProfileModal({ open, handleClose }) {
  const classes = useStyles()
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [snackBar, setSnackBar] = useState({
    type: 'success',
    message: '',
    open: false,
  })
  const [{ user }, dispatch] = useStateValue()
  const [formData, setFormData] = useState({
    imgUrl: user && user?.profile_pic !== undefined ? user?.profile_pic : '',
    img: '',
    firstName: user?.first_name ?? '',
    lastName: user?.last_name ?? '',
    email: user?.email ?? '',
    password: '',
  })
  const myEvent = useSelector((state) => state.events)

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar((prev) => {
      return {
        type: 'success',
        message: '',
        open: false,
      }
    })
    handleClose()
  }

  //function to convert string to base64 encoded
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  //verify axios res
  const verifyAxios = (axiosRes) => {
    if (axiosRes?.status === 200 || axiosRes?.status === 201) {
      return true
    }
    return false
  }

  const handleImgUpload = async (e) => {
    try {
      setBackdropOpen(true)
      let fileType = await e.target.files[0].type

      const supportedMimeTypes = ['image/jpg', 'image/jpeg']
      //allowing only jpg or jpeg format
      if (!supportedMimeTypes.includes(fileType)) {
        setBackdropOpen(false)
        setSnackBar((prev) => {
          return {
            message: 'Unsupported file format. Please upload a jpg or jpeg',
            type: 'error',
            open: true,
          }
        })
        return
      }

      fileType = fileType.split('/')[1]

      const base64file = await getBase64(e.target.files[0])

      // upload the file
      const axiosRes = await checkAxios({
        url: '/event/uploadmisc',
        method: 'POST',
        data: {
          appUrl: myEvent?.event?.appUrl ?? 'profile pic',
          filedata: base64file,
          contenttype: fileType,
          file_name: e?.target?.files[0]?.name,
          userid: user?.userId,
        },
      })

      if (verifyAxios(axiosRes)) {
        //update user profile pic in database
        const axiosRes2 = await healthAxios({
          url: '/updateuserprofile',
          method: 'POST',
          data: {
            email: formData.email,
            profile_pic: axiosRes?.data?.responseString?.Location,
          },
        })

        if (verifyAxios(axiosRes2)) {
          dispatch({
            type: 'SET_USER',
            user: {
              ...user,
              profile_pic: axiosRes2?.data?.user?.profile_pic,
            },
          })
          setFormData((prev) => {
            return {
              ...prev,
              imgUrl: axiosRes2?.data?.user?.profile_pic,
              img: e.target.files[0],
            }
          })
          setSnackBar((prev) => {
            return {
              message: axiosRes2?.data?.message,
              type: 'success',
              open: true,
            }
          })
          setBackdropOpen(false)
        }
      }
    } catch (error) {
      setBackdropOpen(false)
      console.log(error)
    }
  }

  const handleProfileUpdate = async (e) => {
    e.preventDefault()
    if (
      formData.firstName === user.first_name &&
      formData.lastName === user.last_name
    ) {
      return
    }
    setBackdropOpen(true)
    try {
      const axiosRes = await healthAxios({
        url: '/updateuserprofile',
        method: 'POST',
        data: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
      })
      if (axiosRes.status === 200 || axiosRes.status === 201) {
        dispatch({
          type: 'SET_USER',
          user: {
            ...user,
            first_name: axiosRes?.data?.user?.firstName,
            last_name: axiosRes?.data?.user.lastName,
            username: axiosRes?.data?.user?.firstName,
          },
        })
        setSnackBar((prev) => {
          return {
            message: axiosRes?.data?.message,
            type: 'success',
            open: true,
          }
        })
      }
      setBackdropOpen(false)
    } catch (error) {
      setBackdropOpen(false)
      console.log(error)
    }
  }

  return (
    <>
      <Loader open={backdropOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <Fade in={open} style={{ width: '90%' }}> */}
        <div className={classes.paper}>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={5000}
            onClose={handleSnackBarClose}
          >
            <Alert
              onClose={handleSnackBarClose}
              severity={snackBar.type}
              variant="filled"
            >
              {snackBar.message}
            </Alert>
          </Snackbar>

          <div className="profileModal">
            <div className="profileModal__avatarContainer">
              {user?.profile_pic ? (
                <img src={user?.profile_pic} alt="profile" />
              ) : (
                <PersonIcon className="profileModal__avatarIcon" />
              )}
              <div className="profileModal__addImg">
                <IconButton aria-label="camera icon">
                  <form
                    encType="multipart/form-data"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <label htmlFor="profile-picture">
                      <AddAPhotoIcon className="profileModal__cameraIcn" />
                      <input
                        type="file"
                        id="profile-picture"
                        onChange={handleImgUpload}
                        style={{ display: 'none' }}
                      />
                    </label>
                  </form>
                  {/*  */}
                </IconButton>
              </div>
            </div>
            <div className="profileModal__form">
              <form
                className={`${classes.root}`}
                autoComplete="on"
                onSubmit={handleProfileUpdate}
              >
                <div className="formGroup__wrapper">
                  <div className="formGroup">
                    <label htmlFor="first name">First Name</label>
                    <div>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="John"
                        value={formData.firstName}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return {
                              ...prev,
                              firstName: e.target.value,
                            }
                          })
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="formGroup">
                    <label htmlFor="last name">Last Name</label>
                    <div>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Doe"
                        value={formData.lastName}
                        onChange={(e) => {
                          setFormData((prev) => {
                            return {
                              ...prev,
                              lastName: e.target.value,
                            }
                          })
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="formGroup">
                  <label htmlFor="email">Email</label>
                  <div>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="JohnDoe@Ngage.ai.com"
                      value={formData.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="btn_group">
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </Modal>
    </>
  )
}
