const initialState = []

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_QUIZZES':
      return [...action.payload]
    case 'ADD_QUIZ':
      return [...state, action.payload]
    case 'DELETE_QUIZ':
      return state.filter((quiz) => quiz.quiz_id !== action.payload)
    case 'UPDATE_QUIZ':
      return state.map((quiz) =>
        quiz.quiz_id === action.payload.quiz_id
          ? { ...quiz, ...action.payload }
          : quiz
      )
    case 'QUIZ_LOCK_STATUS':
      return changeLockStatus(state, action.payload)
    case 'QUIZ_ACTIVE_STATUS':
      return changeActiveStatus(state, action.payload)
    case 'QN_STATUS_UPDATE':
      return changeActiveStatusOfQN(state, action.payload)
    case 'UPDATE_QUIZ_QUESTION':
      return updateQuizQuestion(state, action.payload)
    case 'UPDATE_QUIZ_DATA':
      return updateQuizData(state, action.payload)
    case 'CHANGE_QUIZ_ORDER':
      return action?.payload?.quizData
    default:
      return state
  }
}
//----------change lock status for quiz-------------
function changeLockStatus(state, payload) {
  const newState = [...state]

  const elementIndex = state.findIndex((element) => {
    return element?.quiz_id === payload?.quiz_id
  })
  newState[elementIndex].lock_status = payload.status
  return newState
}
// --------------change active status for quiz---------------
function changeActiveStatus(state, payload) {
  const newState = [...state]

  const elementIndex = state.findIndex((element) => {
    return element?.quiz_id === payload?.quiz_id
  })
  newState[elementIndex].active_status = payload.status

  return newState
}

const changeActiveStatusOfQN = (state, payload) => {
  const newState = state.map((quiz) =>
    quiz.quiz_id === payload.quiz_id ? payload.updatedQuiz[0] : quiz
  )
  return newState
}

const updateQuizQuestion = (state, payload) => {
  const { questionId, quizId, status } = payload
  let cloneState = [...state]

  for (let quizObj of cloneState) {
    if (quizObj.quiz_id === quizId) {
      for (let question of quizObj.question_array) {
        if (question.question_id === questionId) {
          question.isActive = status
          break
        }
      }
    }
  }
  return cloneState
}

//update quiz data
const updateQuizData = (state, payload) => {
  let cloneState = [...state]
  cloneState.forEach((quiz) => {
    if (quiz.quiz_id === payload.quiz_id) {
      quiz.question_array.forEach((question, i, questionArray) => {
        if (question.question_id === payload.question_id) {
          questionArray[i] = { ...question, ...payload }
        }
      })
    }
  })

  return cloneState
}

export default quizReducer
