import { checkAxios } from '../webMOBI/axiosConfig'

export const appFeedBack = async (appfeedbackPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/appfeedback',
    headers: { 'Content-Type': 'application/json' },
    data: appfeedbackPayload,
  })
  return { ...data, appfeedbackPayload }
}

export const appFeedBackCheck = async (
  appId,
  username,
  email,
  date,
  userid,
  default_id
) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/appfeedback',
    headers: { 'Content-Type': 'application/json' },
    data: {
      flag: 'check',
      appId,
      feedback: '',
      username,
      email,
      submissiondate: new Date().getTime(),
      eventdate: date,
      userid,
      agenda_id: '',
      survey_type: 'global',
      default_id,
    },
  })
  return data
}
