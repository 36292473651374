import { useState, createContext, useMemo } from 'react'

export const LoadingContext = createContext(false)

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const value = useMemo(() => ({ loading, setLoading }), [loading])
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}
