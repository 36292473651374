import React, { useEffect, useState } from 'react'
import '../../styles/mobileHeader.css'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import { deepPurple } from '@mui/material/colors'
import { useStateValue } from '../StateProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  rounded: {
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
}))

export default function MobileHeader({ drawerState }) {
  const classes = useStyles()
  const [{ user }] = useStateValue()
  const [myUser, setMyUser] = useState({
    profile_pic: user?.profile_pic,
    first_name: user?.first_name,
    email: user?.email,
  })

  useEffect(() => {
    setMyUser((prev) => {
      return {
        ...prev,
        profile_pic: user?.profile_pic,
        first_name: user?.first_name,
        email: user?.email,
      }
    })
  }, [user])
  return (
    <section className="mobileHeader">
      {/* ------ mobile header container -------- */}
      <div className="mobileHeader__container">
        {/* -------- mobile header menu ----------- */}
        <div className="mobileHeader__menu">
          <IconButton
            onClick={() => {
              drawerState(true)
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </div>
        {/* -------- mobile header title ----------- */}
        <div className="mobileHeader__title">
          <p className="title">admin dashboard</p>
        </div>
        {/* -------- mobile header profile ----------- */}
        <div className="mobileHeader__avatar">
          {myUser?.profile_pic ? (
            <Avatar
              alt="Remy Sharp"
              src={`${myUser?.profile_pic}`}
              variant="rounded"
              className={classes.rounded}
            />
          ) : (
            <Avatar variant="rounded" className={classes.rounded}>
              {myUser?.first_name !== undefined
                ? myUser?.first_name?.[0]
                : myUser?.email?.[0]}
            </Avatar>
          )}
        </div>
      </div>
    </section>
  )
}
