import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const RSTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingRight: '0.5rem',
    fontSize: '0.875rem',
    '& fieldset': {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '0.625rem',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}))
