import { useRef, createContext } from 'react'

export const SlideRefContext = createContext('')

export const SlideRefProvider = ({ children }) => {
  const slideRef = useRef('')
  return (
    <SlideRefContext.Provider value={slideRef}>
      {children}
    </SlideRefContext.Provider>
  )
}
