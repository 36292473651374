import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { healthAxios } from '../../axiosConfig'
import { useDispatch } from 'react-redux'
import { openSnackbar } from '../../actions'

export default function ResendEmailModal({ modalOpen, setModalOpen, email }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const reduxDispatch = useDispatch()

  useEffect(() => {
    if (modalOpen) {
      handleOpen()
    }
  }, [modalOpen])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setModalOpen(false)
  }

  const handleCancel = () => {
    handleClose()
  }

  const handleEmail = async () => {
    try {
      setLoading(true)
      const { data } = await healthAxios({
        url: '/users/resend-verification-email',
        method: 'GET',
        params: {
          email,
        },
      })
      setLoading(false)
      reduxDispatch(openSnackbar('success', data.message))
    } catch (error) {
      const message = error?.response?.data?.errors?.length
        ? error?.response?.data?.errors
        : 'Something went wrong!'
      setLoading(false)
      reduxDispatch(openSnackbar('error', message))
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Email Verification</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Unable to log in. Please verify your email to log in.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleEmail} disabled={loading}>
          {loading ? 'Sending...' : 'Resend Email'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
