import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStateValue } from './StateProvider'

const ProtectedRoute = ({ children, ...rest }) => {
  const [{ user }] = useStateValue()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          window.sessionStorage.setItem('location', location.pathname)
          return children
        } else {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />
        }
      }}
    />
  )
}
export default ProtectedRoute
