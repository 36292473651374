import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import PollIcon from '@mui/icons-material/Poll'
import VideocamIcon from '@mui/icons-material/Videocam'
import TimelineIcon from '@mui/icons-material/Timeline'
import PeopleIcon from '@mui/icons-material/People'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { healthAxios } from '../../axiosConfig'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ProfileModal from './ProfileModal'
import { useStateValue } from '../StateProvider'
import SettingsIcon from '@mui/icons-material/Settings'
import { openSnackbar } from '../../actions'

export default function NavLinks({ getLinkVal, handleHideDrawer, type = '' }) {
  const [menuName, setMenuName] = useState('live event')
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = React.useState(false)
  const [, dispatch] = useStateValue()
  const reduxDispatch = useDispatch()

  const handleLinkVal = useCallback(
    (val) => {
      getLinkVal(val)
    },
    [getLinkVal]
  )

  useEffect(() => {
    if (location.pathname === '/events') {
      handleMenuName('switch event')
      handleLinkVal(0)
    }
    if (location.pathname === '/help') {
      handleMenuName('help center')
    }
  }, [location.pathname, handleLinkVal])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleMenuName = (menuName) => {
    if (menuName) setMenuName(menuName)
  }

  //logout handler
  const handleLogout = async () => {
    try {
      const axiosRes = await healthAxios({
        url: '/logout',
        withCredentials: true,
      })

      if (axiosRes.status === 403 || axiosRes.status === 200) {
        window.localStorage.removeItem('persist:root')
        window.sessionStorage.removeItem('location')
        window.localStorage.removeItem('from')
        localStorage.removeItem('activeSlideId')
        window.localStorage.removeItem('presentationData')
        dispatch({
          type: 'SET_USER',
          user: null,
        })
        history.replace('/')
      }
    } catch (error) {
      console.error(error)
      reduxDispatch(openSnackbar('error', 'Failed to logout'))
    }
  }

  return (
    <>
      <ProfileModal open={open} handleClose={handleClose} />
      <li
        className={`${menuName === 'switch event' ? 'menuActive' : 'null'}`}
        onClick={() => {
          handleMenuName('switch event')
          handleHideDrawer()
          history.push('/events')
        }}
      >
        <SwapHorizIcon className="drawer__linkIcons" />
        <span className="drawer__linkName">switch event</span>
      </li>
      {type === 'event' && (
        <>
          <li
            className={`${menuName === 'live event' ? 'menuActive' : 'null'}`}
            onClick={() => {
              handleMenuName('live event')
              handleLinkVal(0)
              handleHideDrawer()
            }}
          >
            <VideocamIcon className="drawer__linkIcons" />
            <span className="drawer__linkName">
              live event<sup style={{ color: 'red' }}>BETA</sup>
            </span>
          </li>
          <li
            className={`${menuName === 'live stream' ? 'menuActive' : 'null'}`}
            onClick={() => {
              handleMenuName('live stream')
              handleLinkVal(1)
              handleHideDrawer()
            }}
          >
            <LiveTvIcon className="drawer__linkIcons" />
            <span className="drawer__linkName">live stream</span>
          </li>
          <li
            className={`${menuName === 'live poll' ? 'menuActive' : 'null'}`}
            onClick={() => {
              handleMenuName('live poll')
              handleLinkVal(2)
              handleHideDrawer()
            }}
          >
            <PollIcon className="drawer__linkIcons" />
            <span className="drawer__linkName">live polls</span>
          </li>
          <li
            className={`${menuName === 'audience q&a' ? 'menuActive' : 'null'}`}
            onClick={() => {
              handleMenuName('audience q&a')
              handleLinkVal(3)
              handleHideDrawer()
            }}
          >
            <QuestionAnswerIcon className="drawer__linkIcons" />
            <span className="drawer__linkName">audience q&amp;a</span>
          </li>
          <li
            className={`${menuName === 'team' ? 'menuActive' : 'null'}`}
            onClick={() => {
              handleMenuName('team')
              handleLinkVal(5)
              handleHideDrawer()
            }}
          >
            <PeopleIcon className="drawer__linkIcons" />
            <span className="drawer__linkName">team</span>
          </li>
          <li
            className={`${menuName === 'analytics' ? 'menuActive' : 'null'}`}
            onClick={() => {
              handleMenuName('analytics')
              handleLinkVal(6)
              handleHideDrawer()
            }}
          >
            <TimelineIcon className="drawer__linkIcons" />
            <span className="drawer__linkName">analytics</span>
          </li>
        </>
      )}
      <li
        className={`${menuName === 'help center' ? 'menuActive' : 'null'}`}
        onClick={() => {
          handleMenuName('help center')
          history.push('/help')
          handleHideDrawer()
        }}
      >
        <LiveHelpIcon className="drawer__linkIcons" />
        <span className="drawer__linkName">help center</span>
      </li>
      <li
        className={`${menuName === 'profile' ? 'menuActive' : 'null'}`}
        onClick={() => {
          handleMenuName('profile')
          handleOpen()
        }}
      >
        <AccountCircleIcon className="drawer__linkIcons" />
        <span className="drawer__linkName">profile</span>
      </li>
      <li
        className={`${menuName === 'account_settings' ? 'menuActive' : 'null'}`}
        onClick={() => {
          history.push('/account-settings')
          handleMenuName('account_settings')
          handleHideDrawer()
        }}
      >
        <SettingsIcon />
        <span className="drawer__linkName">account settings</span>
      </li>
      <li
        className={`${menuName === 'logout' ? 'menuActive' : 'null'}`}
        onClick={() => {
          handleMenuName('logout')
          handleLogout()
          handleHideDrawer()
        }}
      >
        <ExitToAppIcon className="drawer__linkIcons" />
        <span className="drawer__linkName">logout</span>
      </li>
    </>
  )
}
