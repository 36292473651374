const initialState = {
  type: 'success',
  open: false,
  message: '',
}
const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_SNACKBAR':
      return {
        type: action.payload.type,
        open: true,
        message: action.payload.message,
      }
    case 'CLOSE_SNACKBAR':
      return {
        type: 'success',
        open: false,
        message: '',
      }
    default:
      return state
  }
}

export default snackbarReducer
