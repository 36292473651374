import { checkAxios } from '../axiosConfig'

export const publishApp = async (publishAppData) => {
  try {
    const { data } = await checkAxios({
      method: 'POST',
      url: '/event/app_publish',
      headers: { 'Content-Type': 'application/json' },
      data: { ...publishAppData },
    })
    return data
  } catch (error) {
    throw error
  }
}

export const fetchJsonVersion = async (appUrl) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/event/jsonversion?appUrl=${appUrl}`,
  })
  return data
}
