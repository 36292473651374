const initialState = {
  type: '',
  event: [],
}
const activeEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ACTIVE_EVENT':
      return {
        type: action.payload.type,
        event: action.payload.event,
      }
    case 'UPDATE_ACTIVE_EVENT':
      return updateActiveEvent(state, action.payload)
    case 'UPDATE_QUIZ_ACTIVE_EVENT':
      if (state.event[0]?.quiz_id === action.payload.quiz_id) {
        let updatedQuestions = state.event[0].question_array.map((question) =>
          question.question_id === action.payload.question_id
            ? { ...question, ...action.payload }
            : question
        )

        return {
          type: state.type,
          event: [{ ...state.event[0], question_array: updatedQuestions }],
        }
      } else {
        return state
      }
    case 'RESET_ACTIVE_EVENT':
      return resetActiveEvent(state, action.payload)
    default:
      return state
  }
}

const updateActiveEvent = (state, payload) => {
  let cloneState = { type: state.type, event: [...state.event] }
  if (cloneState.type === 'poll' && payload.votes.length > 0) {
    for (let element of payload.votes) {
      if (element.poll_id === cloneState.event[0].poll_id) {
        cloneState.event[0].total_votes = element.total_votes
        cloneState.event[0].votes = element.votes
      }
    }
  }
  return cloneState
}

const resetActiveEvent = (state, payload) => {
  return {
    type: state.type,
    event: [{ ...state.event[0], ...payload }],
  }
}

export default activeEventReducer
