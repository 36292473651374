import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import moment from 'moment'
import { Avatar, Box, InputAdornment, Typography } from '@mui/material'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { SidebarPanel } from '.'
import styles from '../../../styles/streamRightSidebar.module.css'
import { RSTextField } from '../../customComponent/RSTextField'
import { SendIconBtn } from '../../customComponent/SendIconBtn'
import { useCreateQnA, useQnAFetch } from '../../../hooks/qna.hooks'
import { useAuth } from '../../../context/auth.context'

export default function QnA({ setTab }) {
  const queryClient = useQueryClient()
  const appId = queryClient.getQueryData('appData')?.appId
  const { authUser } = useAuth()
  const ref = useRef()
  const { data, isLoading, isError, error } = useQnAFetch(appId)
  const [text, setText] = useState('')
  const { mutateAsync: createQnA } = useCreateQnA()

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const sendMsg = () => {
    const question = text.trim()
    if (question === '') return
    createQnA({
      app_id: appId,
      question,
      created_by: authUser.name,
      userId: authUser.id,
    })
    setText('')
    const scrollToHeight = ref.current.scrollHeight
    setTimeout(() => {
      ref.current.scrollTop = scrollToHeight
    }, 1500)
  }

  if (isLoading)
    return (
      <Typography variant="body2" textAlign="center">
        Loading...
      </Typography>
    )
  else if (isError) {
    return (
      <Typography variant="body2" textAlign="center">
        {error}
      </Typography>
    )
  }
  return (
    <SidebarPanel title="Q &amp; A" setTab={setTab}>
      <Box
        sx={{
          height: 'calc(100% - 5.5rem)',
          overflow: 'auto',
          padding: '0 0.25rem',
          scrollBehavior: 'smooth',
        }}
        ref={ref}
      >
        {data.map((qna) => (
          <QnaList key={qna.question_id} qna={qna} />
        ))}
      </Box>
      <RSTextField
        size="small"
        multiline
        className={styles.RSTextField}
        placeholder="Type here"
        value={text}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SendIconBtn onClick={sendMsg} />
            </InputAdornment>
          ),
        }}
      />
    </SidebarPanel>
  )
}

function QnaList({ qna }) {
  return (
    <Box className={styles.QnaList}>
      {qna.profileImg ? (
        <Avatar alt="User" src={qna.profileImg} />
      ) : (
        <AccountCircleRoundedIcon fontSize="large" />
      )}
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box className={styles.QnaListHeader}>
          <Typography variant="body2" fontWeight={600} color="#7B6666">
            {qna.created_by}
          </Typography>
          <Typography variant="body2" fontSize="small" className={styles.time}>
            &#10625; {moment(qna.createdAt).format('hh:mm a')}
          </Typography>
        </Box>
        <Typography variant="body2" color="#7B6666" fontSize="small">
          {qna.question}
        </Typography>
      </Box>
    </Box>
  )
}
