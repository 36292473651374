import { checkAxios } from '../axiosConfig'

export const fetchAttendee = async (appId) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/user/discovery_app_users?appid=${appId}`,
  })
  return {
    ...data.responseString,
    users: data.responseString.users.map((user) => ({ ...user, messages: [] })),
  }
}

export const RegisterAttendee = async (regAttendeePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/regusers',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...regAttendeePayload,
      regUsers: JSON.stringify(regAttendeePayload.regUsers),
    },
  })
  return { ...data, regAttendeePayload }
}

export const attendeeXlsxUpload = async (uploadXlsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/regusers',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...uploadXlsPayload,
      regUsers: JSON.stringify(uploadXlsPayload.regUsers),
    },
  })
  return { ...data, ...uploadXlsPayload }
}
export const updateAttendeeUser = async (updateAttendeePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/update_profile',
    headers: { 'Content-Type': 'application/json' },
    data: updateAttendeePayload,
  })
  return { ...data, ...updateAttendeePayload }
}

export const verifyAttendeeUser = async (verifyAttendeePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/verifyRegistration',
    headers: { 'Content-Type': 'application/json' },
    data: verifyAttendeePayload,
  })
  return { ...data, ...verifyAttendeePayload }
}

export const deleteAttendeeUser = async (deleteAttendeePayload) => {
  const { data } = await checkAxios({
    method: 'DELETE',
    url: `/user/delete_users?appid=${deleteAttendeePayload.appid}&userid=${
      deleteAttendeePayload.userid
    }&users=${JSON.stringify(deleteAttendeePayload.users)}`,
    headers: { 'Content-Type': 'application/json' },
  })
  return { ...data, ...deleteAttendeePayload }
}

///To geneRate Pdf
export const generatePdf = async (generatePdfPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/pdfgeneration',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...generatePdfPayload,
    },
  })
  return { ...data, ...generatePdfPayload }
}

//Checked In Attendee User
export const checkinUser = async (chekInUserPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/checkin_users',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...chekInUserPayload,
    },
  })
  return { ...data, ...chekInUserPayload }
}

//Export Checked In User
export const exportCheckIn = async (exportCheckinPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/checkin_status_report',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...exportCheckinPayload,
    },
  })
  return { ...data, ...exportCheckinPayload }
}

// /////////Fetch Data for User Admin Lists
export const userAdminList = async (appId, dashboardUserId) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/list_admin',
    headers: { 'Content-Type': 'application/json' },
    data: {
      userid: dashboardUserId,
      appid: appId,
    },
  })
  return { ...data }
}
///Post Api For Admin Setting in Attendees
export const adminSetting = async (adminSettinPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/event_settings',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...adminSettinPayload,
    },
  })
  return { ...data, ...adminSettinPayload }
}

////Delete Admin User and Dashboard user in...Attendeed
export const deleteAdmin = async (delAdminPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/remove_admin',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...delAdminPayload,
    },
  })
  return { ...data, ...delAdminPayload }
}

////Email Cofig
export const emailConfig = async (emailConfigPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/email_config',
    headers: { 'Content-Type': 'application/json' },
    data: emailConfigPayload,
  })
  return data
}

// -----to get templates
export const getTemplates = async (appid, userid, type) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/event/event_messages?appid=${appid}&type=${type}&userid=${userid}&role_id=6`,
    headers: { 'Content-Type': 'application/json' },
  })
  console.log('data template', data)
  return data
}
// ------to update templates
export const updateTemplate = async (updateTemplatePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/new_event_template',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...updateTemplatePayload,
    },
  })

  return { ...data, ...updateTemplatePayload }
}
// -------to update campaign
export const updateCampaign = async (updateCampaignPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/new_event_message',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...updateCampaignPayload,
    },
  })

  return { ...data, ...updateCampaignPayload }
}
// -------to create new campaign
export const createCampaign = async (createCampaignPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/new_event_message',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...createCampaignPayload,
    },
  })

  return { ...data, ...createCampaignPayload }
}
// ------to send campaign email
export const sendCampaign = async (sendCampaignPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/send_event_message',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...sendCampaignPayload,
    },
  })

  return { ...data, ...sendCampaignPayload }
}
// --------to delete campaign

// export const deleteCampaign = async (appId, msgId) => {
//   const { data } = await checkAxios.delete(
//     `/event/delete_messages?appid=${appId}&message_ids=[${msgId}]`
//   )

//   return { ...data }
// }
export const deleteCampaign = async (deleteCampaignPayload) => {
  const { data } = await checkAxios({
    method: 'DELETE',
    url: `/event/delete_messages?appid=${
      deleteCampaignPayload.appid
    }&message_ids=[${JSON.stringify(deleteCampaignPayload.message_ids)}]`,
    headers: { 'Content-Type': 'application/json' },
  })
  return { ...data, ...deleteCampaignPayload }
}

// /////////Fetch Data for OutBound email
export const outBoundEmail = async (appId, email, dashboardUserId) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/email_config',
    headers: { 'Content-Type': 'application/json' },
    data: {
      appid: appId,
      flag: 'show',
      support_email: '',
      send_registration_email: '',
      loggedin_email: email,
      userid: dashboardUserId,
    },
  })
  if (data.response) return data.responseString
  return []
}

// ///////personalized schedule////////////

export const manageSchedule = async (manageSchedulePayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/manage_schedule_dashboard',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...manageSchedulePayload,
      schedules: JSON.stringify(manageSchedulePayload.schedules),
    },
  })
  return { ...data, ...manageSchedulePayload }
}
