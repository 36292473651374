const initialState = []

const ratingPollReducer = (state = initialState, action) => {
  let newState
  switch (action.type) {
    case 'ADD_RATING_POLL':
      return [...action.payload]
    case 'CREATE_RATING_POLL':
      return [...state, action.payload]
    case 'DELETE_RATING_POLL':
      newState = state.filter((poll) => poll._id !== action.payload)
      return newState
    case 'UPDATE_RATING_LOCK_STATUS':
      newState = state.map((rating) =>
        rating._id === action.payload._id ? action.payload : rating
      )
      return newState
    case 'UPDATE_RATING_STATUS':
      newState = state.map((rating) =>
        rating._id === action.payload._id ? action.payload : rating
      )
      return newState
    case 'UPDATE_RATING_POLL':
      newState = state.map((rating) =>
        rating._id === action.payload._id ? action.payload : rating
      )
      return newState
    case 'CHANGE_RATING_ORDER':
      return action?.payload?.ratingData
    default:
      return state
  }
}

export default ratingPollReducer
