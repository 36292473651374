const initialState = { ppts: [], activePpt: {} }
const presentationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_PPTS':
      return { ...state, ppts: [...action.payload] }
    case 'CREATE_PPT':
      return { ...state, ppts: [...state.ppts, action.payload] }
    case 'UPDATE_PPT':
      return {
        ppts: updatePpts(state.ppts, action.payload),
        activePpt: updateActivePpt(state.activePpt, action.payload),
      }
    case 'DELETE_PPT':
      return {
        ...state,
        ppts: state.ppts.filter((ppt) => ppt.id !== action.payload),
      }
    case 'ADD_ACTIVE_PPT':
      return { ...state, activePpt: { ...action.payload } }
    case 'INCREASE_NO_OF_SLIDE':
      return {
        ...state,
        ppts: increaseNoOfSlide(state.ppts, action.payload),
      }
    case 'DECREASE_NO_OF_SLIDE':
      return {
        ...state,
        ppts: decreaseNoOfSlide(state.ppts, action.payload),
      }
    default:
      return state
  }
}

const updatePpts = (ppts, payload) => {
  return ppts.map((ppt) =>
    ppt.id === payload.id ? { ...ppt, ...payload } : ppt
  )
}

const updateActivePpt = (activePpt, payload) => {
  if (activePpt?.id === payload.id) {
    return { ...activePpt, ...payload }
  }
  return activePpt
}

const increaseNoOfSlide = (ppts, { id, num }) => {
  return ppts.map((ppt) =>
    ppt.id === id ? { ...ppt, no_of_slides: ppt.no_of_slides + num } : ppt
  )
}

const decreaseNoOfSlide = (ppts, id) => {
  return ppts.map((ppt) =>
    ppt.id === id
      ? {
          ...ppt,
          no_of_slides:
            ppt.no_of_slides > 0 ? ppt.no_of_slides - 1 : ppt.no_of_slides,
        }
      : ppt
  )
}

export default presentationsReducer
