import React, { useState, useEffect, useContext } from 'react'
import { healthAxios } from '../axiosConfig'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useStateValue } from './StateProvider'
import queryString from 'query-string'
import { DomainContext } from '../context/domain.context'
// ------mui components imports:start---------
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
// ------mui imports:end---------
// --------- image imports:start ---------
import Unlock from './images/unlock.svg'
// --------- image imports:end ---------

//--------styles import:start--------
import '../styles/newLogin.css'
import '../styles/resetPassword.css'
import Loader from './customComponent/Loader'
import { useDispatch } from 'react-redux'
import { openSnackbar } from '../actions'
//--------styles import:end--------

// --- login 1:start(using email and pass) ---
const LoginOne = () => {
  // --- states ---
  const [user, setUser] = useState({
    email: '',
    passkey: '',
    action: 'generate',
    deviceType: 'web',
  })
  const [openSpinner, setOpenSpinner] = React.useState(false)

  const reduxDispatch = useDispatch()

  //--- form submit handler----
  const handelFormSubmit = async (e) => {
    e.preventDefault()
    setOpenSpinner(true)
    try {
      const { data } = await healthAxios({
        url: '/passwordchange',
        method: 'POST',
        data: {
          email: user.email,
        },
      })
      setOpenSpinner(false)
      reduxDispatch(openSnackbar('success', data.message))
    } catch (error) {
      setOpenSpinner(false)
      let text = error?.response?.data?.errors?.length
        ? error.response.data.errors[0].message
        : 'Something went wrong!'

      reduxDispatch(openSnackbar('error', text))
    }
  }

  return (
    <>
      <Loader open={openSpinner} />
      <form
        className="formGroup__form"
        autoComplete="off"
        onSubmit={handelFormSubmit}
      >
        {/* --- email input:start --- */}
        <div className="inputGroup">
          <label htmlFor="email">Email</label>
          <TextField
            className="inputGroup__input"
            id="email"
            variant="filled"
            name="email"
            type="email"
            placeholder="John@mendios.com"
            value={user.email}
            onChange={(e) => {
              setUser((prev) => {
                return { ...prev, email: e.target.value }
              })
            }}
            required={true}
          />
        </div>
        {/* --- email input:end --- */}
        <div className="btnGroup">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className="btnGroup__btn"
          >
            Get Reset Link
          </Button>
        </div>
      </form>
    </>
  )
}
// --- login 1:end(using email and pass) ---

// -------- NewLogin component:start --------
function ForgotPassword() {
  // --------- state:start ---------
  const { search } = useLocation()
  const { user_id, email, dashboardUserId } = queryString.parse(search)
  const [, dispatch] = useStateValue()
  const history = useHistory()
  const { brandName } = useContext(DomainContext)
  // --------- state:end ---------

  // --------- side effects:start ---------
  useEffect(() => {
    if (user_id && email) {
      dispatch({
        type: 'SET_USER',
        user: {
          userId: user_id,
          email: email,
          dashboardUserId: dashboardUserId,
        },
      })
      history.replace('/events')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // --------- jsx:start ---------
  return (
    <section className="newLogin">
      {/* ------ header:start ------  */}
      <header className="header">
        <ul className="header__list">
          <li className="header__item brand">
            {brandName ? brandName : 'Ngage.ai'}
          </li>
          <li className="header__item btnGroup hide--sm show--lg">
            <Button
              variant="outlined"
              color="primary"
              className="btnGroup__btn"
              onClick={() => {
                return history.push('/join-event')
              }}
            >
              Join an event ? Click here !
            </Button>
          </li>
        </ul>
      </header>
      {/* ------ header:end ------  */}
      {/* ------- landing:start ------- */}
      <div className="landing">
        {/* ------ landing img:start ------- */}
        <div className="landing__imgWrapper">
          <img src={Unlock} alt="unlock account" />
        </div>
        {/* ------ landing img:end ------- */}

        {/* ----- formGroup:start -----  */}
        <div className="formGroup">
          <LoginOne />
        </div>
        {/* ----- formGroup:end -----  */}
      </div>
      {/* ------- landing:end ------- */}
      {/* --- sign up message:start --- */}
      <div className="joinEvent--sm">
        <Button variant="outlined" color="primary" className="btnGroup__btn">
          Join an event ? Click here !
        </Button>
      </div>
      <div className="signupDiv">
        <div className="signupDiv__circle">
          <div id="main__circle">
            <div className="signupDiv__msg">
              <h2>Log In</h2>
              <br />
              <h2>to your Account</h2>
              <p className="loginOpt">
                or <Link to="/register">Create a new account</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* --- sign up message:end --- */}
    </section>
  )
  // --------- jsx:end ---------
}
// -------- NewLogin component:end --------

export default ForgotPassword
