import moment from 'moment'

export const getCurrentZoneDateTime = (date, format = 'YYYY-MM-DD') => {
  return moment(date + new Date(date).getTimezoneOffset() * 60 * 1000).format(
    format
  )
}
export const getZoneDateTime = (date) => {
  return date + new Date(date).getTimezoneOffset() * 60 * 1000
}
