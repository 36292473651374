import { Box } from '@mui/material'
import { useQueryClient } from 'react-query'
import { SidebarPanel } from '.'
import Poll from '../../Homepage/Poll'

export default function Polls({ setTab }) {
  const queryClient = useQueryClient()
  const appId = queryClient.getQueryData('appData')?.appId
  return (
    <SidebarPanel title="Polls" setTab={setTab}>
      <Box sx={{ height: 'calc(100% - 3rem)', overflow: 'auto' }}>
        <Poll app_id={appId} />
      </Box>
    </SidebarPanel>
  )
}
