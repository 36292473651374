import React from 'react'
import Typography from '@mui/material/Typography'
import { Container } from '@mui/material'
import '../styles/dashboard.css'
function Dashboard() {
  return (
    <div className="dashboard">
      <Container>
        <Typography variant="h5" component="h2" fontWeight={600}>
          Admin Dashboard
        </Typography>
      </Container>
    </div>
  )
}

export default Dashboard
