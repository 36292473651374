import axios from 'axios'

const healthAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
})

const checkAxios = axios.create({
  baseURL: process.env.REACT_APP_CHECK_URL,
})

const awsAxios = axios.create({
  baseURL: `${process.env.REACT_APP_S3_AWS_URI}/webmobi`,
})

export { healthAxios, checkAxios, awsAxios }
