const initialState = { slides: [], activeSlide: {} }
const pptReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_SLIDES':
      return { ...state, slides: [...action.payload] }
    case 'ADD_SLIDE':
      return { ...state, slides: [...state.slides, { ...action.payload }] }
    case 'UPDATE_SLIDE':
      return { ...state, slides: updateSlides(state, action.payload) }
    case 'UPDATE_SLIDE_VOTE':
      return { ...state, slides: updateSlideVote(state, action.payload) }
    case 'UPDATE_QUIZ_SLIDE_VOTE':
      return {
        ...state,
        slides: updateQuizSlideVote(state, action.payload),
      }
    case 'UPDATE_SLIDE_ACTIVE_STATUS':
      return updateSlideActiveStatus(state, action.payload)
    case 'DEACTIVATE_POLL_SLIDES':
      return deactivatePollSlides(state)
    case 'DELETE_SLIDE':
      return { ...state, slides: deleteSlide(state, action.payload) }
    case 'ADD_ACTIVE_SLIDE':
      return { ...state, activeSlide: { ...action.payload } }
    case 'UPDATE_SLIDE_DATA':
      return { ...state, slides: updateSlideData(state, action.payload) }
    default:
      return state
  }
}

// update slides
const updateSlides = (state, payload) => {
  return state.slides.map((slide) =>
    slide.id === payload.id ? payload : slide
  )
}
// delete slide where payload as slide id
const deleteSlide = (state, payload) => {
  return state.slides.filter((slide) => slide.id !== payload)
}
// update active status of slide where payload as object
const updateSlideActiveStatus = (state, payload) => {
  return {
    ...state,
    slides: state.slides.map((slide) =>
      slide.poll_id === payload.id
        ? { ...slide, active_status: payload.active_status }
        : slide
    ),
  }
}
const updateSlideVote = (state, payload) => {
  return state.slides.map((slide) =>
    slide.poll_id === payload.poll_id
      ? { ...slide, ...payload, id: slide.id }
      : slide
  )
}
// update voting data of slide where payload as object
const updateQuizSlideVote = (state, payload) => {
  return state.slides.map((slide) =>
    slide.poll_id === payload.quiz_id
      ? {
          ...slide,
          question_array: slide.question_array.map((question) =>
            question.question_id === payload.question_id
              ? { ...question, ...payload }
              : question
          ),
        }
      : slide
  )
}

const data = ['polls', 'quizzes', 'wordCloud', 'ratingPolls']
const deactivatePollSlides = (state) => {
  return {
    ...state,
    slides: state.slides.map((val) => {
      if (data.includes(val.type)) return { ...val, active_status: false }
      return val
    }),
  }
}
const updateSlideData = (state, payload) => {
  return state.slides.map((slide) =>
    slide.id === payload.id ? { ...slide, ...payload } : slide
  )
}

export default pptReducer
