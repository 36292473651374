let initialState = {
  isActive: false,
  quizId: null,
  data: [],
}

const leaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LIVE_LEADERBOARD':
      return {
        ...state,
        ...action.payload,
      }
    case 'RESET_LIVE_LEADERBOARD':
      return {
        isActive: false,
        quizId: null,
        data: [],
      }
    default:
      return state
  }
}

export default leaderboardReducer
