const featureSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_FEATURE_SETTINGS':
      return {
        ...action.payload,
        livestreamSettings: {
          zoom: action.payload.zoom,
          youtube: action.payload.youtube,
          facebook: action.payload.facebook,
          twitch: action.payload.twitch,
          vimeo: action.payload.vimeo,
        },
      }
    case 'UPDATE_FEATURE_SETTINGS':
      return {
        ...state,
        ...action.payload,
      }
    case 'UPDATE_LIVESTREAM_SETTINGS':
      return {
        ...state,
        livestreamSettings: {
          ...state.livestreamSettings,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export default featureSettingsReducer
