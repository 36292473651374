import Skeleton from '@mui/material/Skeleton'
import Container from '@mui/material/Container'
import Style from '../../styles/regAttendee.module.css'
import styles from '../../styles/stream.module.css'
import Box from '@mui/material/Box'

function StreamSpeakerSkeleton() {
  return (
    <Container sx={{ marginTop: '0 !important' }}>
      <Box className={styles.streamHeaderBox} sx={{ p: 3 }}>
        <Skeleton variant="rectangular" width={210} height={60} />
      </Box>
      <section id="speaker" className={Style.speakerSection}>
        <div>
          <div className={Style.img}>
            <Skeleton
              variant="rounded"
              width={300}
              height={350}
              sx={{ borderRadius: '25px' }}
            />
          </div>
        </div>
      </section>
    </Container>
  )
}

export default StreamSpeakerSkeleton
