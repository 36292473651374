import { createContext, useContext, useMemo, useReducer } from 'react'

const ChatContext = createContext([])

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return action.payload

    case 'create':
      const { chat, from } = action.payload
      return state.map((user) =>
        user.userid === from.id
          ? {
              ...user,
              messages: [...user.messages, chat],
            }
          : user
      )
    case 'connected':
      const { userId, connected } = action.payload
      return state.map((user) =>
        user.userid === userId
          ? {
              ...user,
              connected,
            }
          : user
      )
    case 'seen':
      return state.map((user) =>
        user.userid === action.payload.userId
          ? {
              ...user,
              messages: user.messages.map((message) =>
                message.id === action.payload.id
                  ? { ...message, is_seen: true }
                  : message
              ),
            }
          : user
      )
    default:
      console.error('Invalid action type')
      break
  }
}

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, [])

  const value = useMemo(() => ({ state, dispatch }), [state])
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}

export const useChat = () => useContext(ChatContext)
