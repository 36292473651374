import React from 'react'
import '../styles/loader.css'
import myLoaderImgmp4 from './images/page-loader.mp4'
import myLoaderImgwebm from './images/page-loader.webm'

const MyLoader = () => {
  return (
    <div className="myLoader">
      <video autoPlay loop muted playsInline>
        <source src={myLoaderImgmp4} type="video/mp4" />
        <source src={myLoaderImgwebm} type="video/webm" />
      </video>
    </div>
  )
}

export default MyLoader
