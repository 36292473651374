import axios from "axios";

// GlobalSurvey.js
export const getGlobalSurveyData = async (appUrl) => {
  const baseUrl = "https://webmobi.s3.amazonaws.com/nativeapps/";
  const url = `${baseUrl}${appUrl}/appData.json`;
  const { data } = await axios.get(url);

  const globalSurveyTab = data.events[0].tabs.find(
    (tab) => tab.type === "survey" && tab.sub_type === "global"
  );

  if (globalSurveyTab) {
    // Return both the globalSurveyTab and the parts of appData that you need.
    return {
      surveyData: globalSurveyTab,
      appId: data.appId,
      eventdate: data.startdate,
      checkvalue: globalSurveyTab.checkvalue,
      eventData: data,
    };
  } else {
    return null;
  }
};

export default getGlobalSurveyData;
