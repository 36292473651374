import { Route, Redirect, useParams } from 'react-router-dom'
import { useAuth } from '../../context/auth.context'
import { useStateValue } from '../StateProvider'

const StreamProtectedRoute = ({ children, ...rest }) => {
  const { app_url } = useParams()
  const { authUser } = useAuth()
  const [{ user }] = useStateValue()
  const token = sessionStorage.getItem('token')

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          authUser.isAuthenticated ||
          (token && token !== 'undefined') ||
          user
        ) {
          return children
        } else {
          return (
            <Redirect
              to={{
                pathname: `/${app_url}/stream/login`,
                state: { from: location },
              }}
            />
          )
        }
      }}
    />
  )
}
export default StreamProtectedRoute
