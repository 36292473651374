import React, { useEffect, useState, useContext } from 'react'
import Avatar from '@mui/material/Avatar'
import { useStateValue } from './StateProvider'
import { checkAxios, healthAxios } from '../axiosConfig'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { makeStyles } from '@mui/styles'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useDispatch, useSelector } from 'react-redux'
import { openSnackbar } from '../actions'
import { SettingIcon } from './customComponent/icons/SettingIcon'
import PaymentIcon from '@mui/icons-material/Payment'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import LiveMenu from './images/live-event-menu.svg'
import {
  LiveEventContext,
  Actions as LiveEventAction,
} from '../context/liveEvent.context'
import '../styles/rightSideBar.css'
import Loader from './customComponent/Loader'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  rounded: {
    backgroundColor: '#9627ea',
  },
}))

// MAIN COMPONENT
function RightSidebar({ currentTab = null }) {
  const [{ user }, dispatch] = useStateValue()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [activeMenuItem, setActiveMenuItem] = useState('')
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [backdropOpen, setBackdropOpen] = React.useState(false)
  const [formData, setFormData] = useState({
    imgUrl: user && user?.profile_pic !== undefined ? user?.profile_pic : '',
    img: '',
    firstName: user?.first_name ?? '',
    lastName: user?.last_name ?? '',
    email: user?.email ?? '',
    password: '',
  })
  const [snackBar, setSnackBar] = useState({
    type: 'success',
    message: '',
    open: false,
  })
  const [deferedPrompt, setDeferedPrompt] = useState(null)
  // const [didUnmount, setDidUnmount] = useState(false)
  const reduxDispatch = useDispatch()
  const history = useHistory()
  const { event } = useSelector((state) => state.events)
  const { state: liveEventState, dispatch: liveEventDispatch } =
    useContext(LiveEventContext)

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      // console.info("before install prompt fired");
      setDeferedPrompt(event)
      return false
    })
  }, [])

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar((prev) => {
      return {
        type: 'success',
        message: '',
        open: false,
      }
    })
    handleModalClose()
  }

  const handleModalOpen = () => {
    setOpen(true)
  }

  const handleModalClose = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  //---handle menu---
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const onClickStripe = async () => {
    if (!event.appId) return
    try {
      const { data } = await healthAxios.post(
        '/stripe/connect',
        {
          appId: event.appId,
        },
        { withCredentials: true }
      )
      window.location.href = data.redirectUrl
    } catch (error) {
      reduxDispatch(openSnackbar('error', error.message))
    }
  }

  const handleActiveMenu = (menuName) => {
    setActiveMenuItem(menuName)
    switch (menuName) {
      case 'profile':
        handleModalOpen()
        break
      case 'logout':
        healthAxios({
          url: '/logout',
          withCredentials: true,
        })
          .then((axiosRes) => {
            if (axiosRes.status === 403 || axiosRes.status === 200) {
              window.localStorage.removeItem('persist:root')
              window.sessionStorage.removeItem('location')
              window.localStorage.removeItem('from')
              localStorage.removeItem('activeSlideId')
              window.localStorage.removeItem('presentationData')
              // setMySocket({})
              dispatch({
                type: 'SET_USER',
                user: null,
              })
              history.replace('/')
            }
          })
          .catch((err) => {
            console.log('error')
            window.localStorage.removeItem('persist:root')
            window.sessionStorage.removeItem('location')
            window.localStorage.removeItem('from')
            localStorage.removeItem('activeSlideId')
            window.localStorage.removeItem('presentationData')
            // setMySocket({})
            dispatch({
              type: 'SET_USER',
              user: null,
            })
            history.replace('/')
          })
        break
      case 'plan_info':
        history.push('/account-settings')
        break
      case 'subscription':
        history.push('/subscription')
        break
      case 'stream_usage':
        history.push('/stream-usage')
        break
      case 'invoices':
        history.push('/invoices')
        break
      default:
        break
    }
  }

  //profile update handler
  const handleProfileUpdate = async (e) => {
    e.preventDefault()
    if (
      formData.firstName === user.first_name &&
      formData.lastName === user.last_name
    ) {
      return
    }
    setBackdropOpen(true)
    try {
      const axiosRes = await healthAxios({
        url: '/updateuserprofile',
        method: 'POST',
        data: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        },
      })
      if (axiosRes.status === 200 || axiosRes.status === 201) {
        dispatch({
          type: 'SET_USER',
          user: {
            ...user,
            first_name: axiosRes?.data?.user?.firstName,
            last_name: axiosRes?.data?.user.lastName,
            username: axiosRes?.data?.user?.firstName,
          },
        })
        setSnackBar((prev) => {
          return {
            message: axiosRes?.data?.message,
            type: 'success',
            open: true,
          }
        })
      }
      setBackdropOpen(false)
    } catch (error) {
      setBackdropOpen(false)
      console.log(error)
    }
  }

  //function to convert string to base64 encoded
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  //verify axios res
  const verifyAxios = (axiosRes) => {
    if (axiosRes?.status === 200 || axiosRes?.status === 201) {
      return true
    }
    return false
  }

  const handleImgUpload = async (e) => {
    try {
      setBackdropOpen(true)
      let fileType = await e.target.files[0].type

      const supportedMimeTypes = ['image/jpg', 'image/jpeg']
      //allowing only jpg or jpeg format
      if (!supportedMimeTypes.includes(fileType)) {
        setBackdropOpen(false)
        setSnackBar((prev) => {
          return {
            message: 'Unsupported file format. Please upload a jpg or jpeg',
            type: 'error',
            open: true,
          }
        })
        return
      }

      fileType = fileType.split('/')[1]

      const base64file = await getBase64(e.target.files[0])

      // upload the file
      const axiosRes = await checkAxios({
        url: '/event/uploadmisc',
        method: 'POST',
        data: {
          appUrl: event?.appUrl ?? 'profile pic',
          filedata: base64file,
          contenttype: fileType,
          file_name: e?.target?.files[0]?.name,
          userid: user?.userId,
        },
      })

      if (verifyAxios(axiosRes)) {
        //update user profile pic in database
        const axiosRes2 = await healthAxios({
          url: '/updateuserprofile',
          method: 'POST',
          data: {
            email: formData.email,
            profile_pic: axiosRes?.data?.responseString?.Location,
          },
        })

        if (verifyAxios(axiosRes2)) {
          dispatch({
            type: 'SET_USER',
            user: {
              ...user,
              profile_pic: axiosRes2?.data?.user?.profile_pic,
            },
          })
          setFormData((prev) => {
            return {
              ...prev,
              imgUrl: axiosRes2?.data?.user?.profile_pic,
              img: e.target.files[0],
            }
          })
          setSnackBar((prev) => {
            return {
              message: axiosRes2?.data?.message,
              type: 'success',
              open: true,
            }
          })
          setBackdropOpen(false)
        }
      }
    } catch (error) {
      setBackdropOpen(false)
      console.log(error)
    }
  }

  const handleInstall = () => {
    if (deferedPrompt) {
      deferedPrompt.prompt()
      deferedPrompt.userChoice.then((choiceResult) => {
        console.info('user choice', choiceResult.outcome)
        if (choiceResult.outcome === 'dismissed') {
          console.info('user dismissed')
        } else {
          console.info('user accepted')
        }
      })
      setDeferedPrompt(null)
    }
  }

  //toggle live menu click
  const handleLiveMenuClick = () => {
    liveEventDispatch({
      type: LiveEventAction.toggleMenu,
      payload: !liveEventState.menuClick,
    })
    liveEventDispatch({
      type: LiveEventAction.changeCardMenu,
      payload: {
        id: -1,
      },
    })
  }

  // show live event menu conditionally
  const showLiveEventMenu = () => {
    if (currentTab !== 0) return false
    else return true
  }

  return (
    <div className="right-sidebar">
      <Loader open={backdropOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Snackbar
              open={snackBar.open}
              autoHideDuration={5000}
              onClose={handleSnackBarClose}
            >
              <Alert
                onClose={handleSnackBarClose}
                severity={snackBar.type}
                variant="filled"
              >
                {snackBar.message}
              </Alert>
            </Snackbar>
            <div className="profileModal">
              <div className="profileModal__avatarContainer">
                {user?.profile_pic ? (
                  <img src={user?.profile_pic} alt="user profile" />
                ) : (
                  <PersonIcon className="profileModal__avatarIcon" />
                )}
                <div className="profileModal__addImg">
                  <IconButton aria-label="camera icon">
                    <form
                      encType="multipart/form-data"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <label htmlFor="profile-picture">
                        <AddAPhotoIcon
                          className="profileModal__cameraIcn"
                          style={{ cursor: 'pointer' }}
                        />
                        <input
                          type="file"
                          id="profile-picture"
                          onChange={handleImgUpload}
                          style={{ display: 'none' }}
                        />
                      </label>
                    </form>
                    {/*  */}
                  </IconButton>
                </div>
              </div>
              <div className="profileModal__form">
                <form
                  className={`${classes.root}`}
                  autoComplete="on"
                  onSubmit={handleProfileUpdate}
                >
                  <div className="formGroup__wrapper">
                    <div className="formGroup">
                      <label htmlFor="first name">First Name</label>
                      <div>
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder="John"
                          value={formData.firstName}
                          onChange={(e) => {
                            setFormData((prev) => {
                              return {
                                ...prev,
                                firstName: e.target.value,
                              }
                            })
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="formGroup">
                      <label htmlFor="last name">Last Name</label>
                      <div>
                        <input
                          type="text"
                          name="lastName"
                          id="lastName"
                          placeholder="Doe"
                          value={formData.lastName}
                          onChange={(e) => {
                            setFormData((prev) => {
                              return {
                                ...prev,
                                lastName: e.target.value,
                              }
                            })
                          }}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="formGroup">
                    <label htmlFor="email">Email</label>
                    <div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="JohnDoe@webmobi.com"
                        value={formData.email}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="btn_group">
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {user?.profile_pic ? (
        <Avatar
          component={Button}
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          data-testid="profileMenuBtn"
          alt="Remy Sharp"
          src={user?.profile_pic}
          variant="rounded"
          className={classes.rounded}
          sx={{ p: 0, minWidth: '2.675rem', height: '2.675rem' }}
        />
      ) : (
        <Avatar
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          data-testid="profileMenuBtn"
          variant="rounded"
          className={classes.rounded}
          sx={{ p: 0, minWidth: '2.675rem', height: '2.675rem' }}
        >
          {user?.first_name !== undefined
            ? user?.first_name?.[0]
            : user?.email?.[0]}
        </Avatar>
      )}
      {deferedPrompt && (
        <IconButton onClick={handleInstall}>
          <GetAppIcon fontSize="large" color="primary" />
        </IconButton>
      )}
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="rightSideBar__menu"
      >
        <MenuItem
          className={`rightSideBar__menuItem ${
            activeMenuItem === 'profile'
              ? 'rightSideBar__menuItem--active'
              : null
          }`}
          onClick={() => handleActiveMenu('profile')}
        >
          <ListItemIcon>
            <PersonIcon
              fontSize="small"
              className={`${
                activeMenuItem === 'profile' ? 'menu__icon--active' : null
              }`}
            />
          </ListItemIcon>
          <Typography variant="inherit">Profile</Typography>
        </MenuItem>
        {/* MENU ITEM FOR PLAN INOF */}
        <MenuItem
          className={`rightSideBar__menuItem ${
            activeMenuItem === 'plan_info'
              ? 'rightSideBar__menuItem--active'
              : null
          }`}
          onClick={() => handleActiveMenu('plan_info')}
        >
          <ListItemIcon>
            <SettingIcon activeMenuItem={activeMenuItem} />
          </ListItemIcon>
          <Typography variant="inherit" data-testid="account-setting">
            Account Setting
          </Typography>
        </MenuItem>
        {/* subscription  */}
        <MenuItem
          className={`rightSideBar__menuItem ${
            activeMenuItem === 'subscription'
              ? 'rightSideBar__menuItem--active'
              : null
          }`}
          onClick={() => handleActiveMenu('subscription')}
        >
          <ListItemIcon>
            <CardMembershipIcon
              className={
                activeMenuItem === 'subscription' ? 'menu__icon--active' : ''
              }
            />
          </ListItemIcon>
          <Typography variant="inherit" data-testid="subscription">
            Subscription
          </Typography>
        </MenuItem>
        {/* stream usage */}
        <MenuItem
          className={`rightSideBar__menuItem ${
            activeMenuItem === 'stream_usage'
              ? 'rightSideBar__menuItem--active'
              : null
          }`}
          onClick={() => handleActiveMenu('stream_usage')}
        >
          <ListItemIcon>
            <DataUsageIcon
              className={
                activeMenuItem === 'stream_usage' ? 'menu__icon--active' : ''
              }
            />
          </ListItemIcon>
          <Typography variant="inherit" data-testid="stream-usage">
            Stream Usage
          </Typography>
        </MenuItem>
        {event?.appId && (
          <MenuItem
            className={`rightSideBar__menuItem ${
              activeMenuItem === 'stripe'
                ? 'rightSideBar__menuItem--active'
                : null
            }`}
            onClick={onClickStripe}
          >
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <Typography variant="inherit" data-testid="account-setting">
              Connect Stripe
            </Typography>
          </MenuItem>
        )}
        {/* Invoice */}
        {/* <MenuItem
          className={`rightSideBar__menuItem ${
            activeMenuItem === 'invoices'
              ? 'rightSideBar__menuItem--active'
              : null
          }`}
          onClick={() => handleActiveMenu('invoices')}
        >
          <ListItemIcon >
            <ReceiptIcon
              className={
                activeMenuItem === 'invoices' ? 'menu__icon--active' : ''
              }
            />
          </ListItemIcon>
          <Typography variant="inherit" data-testid="invoices">
            Invoices
          </Typography>
        </MenuItem> */}
        <MenuItem
          className={`rightSideBar__menuItem ${
            activeMenuItem === 'logout'
              ? 'rightSideBar__menuItem--active'
              : null
          }`}
          onClick={() => handleActiveMenu('logout')}
        >
          <ListItemIcon>
            <ExitToAppIcon
              fontSize="small"
              className={`${
                activeMenuItem === 'logout' ? 'menu__icon--active' : null
              }`}
            />
          </ListItemIcon>
          <Typography variant="inherit" data-testid="logoutBtn">
            Logout
          </Typography>
        </MenuItem>
      </Menu>
      {showLiveEventMenu() && (
        <div
          className={`live-event-menu ${
            liveEventState?.menuClick ? 'menu-active' : 'null'
          }`}
          onClick={handleLiveMenuClick}
        >
          <img src={LiveMenu} alt="live menu" height={31} width={23} />
        </div>
      )}
      {/* <HelpIcon className="right-sidebar-icon" />
      <SettingsIcon className="right-sidebar-icon" /> */}
    </div>
  )
}

export default RightSidebar
