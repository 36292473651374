const initialState = []

const pollReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_POLL':
      return [...action.payload]
    case 'DELETE_POLL':
      const newState = state.filter((poll) => poll.poll_id !== action.payload)
      return newState
    case 'POLL_LOCK_STATUS':
      return changeLockStatus(state, action.payload)
    case 'POLL_ACTIVE_STATUS':
      return changeActiveStatus(state, action.payload)
    case 'UPDATE_POLL_DATA':
      return updatePollData(state, action.payload)
    case 'RESET_POLL':
      return resetPoll(state, action.payload)
    case 'CHANGE_POLL_ORDER':
      return action?.payload?.pollData
    case 'CREATE_NEW_POLL':
      return [...state, action.payload]
    default:
      return state
  }
}

function changeLockStatus(state, payload) {
  const newState = [...state]

  const elementIndex = state.findIndex((element) => {
    return element?.poll_id === payload?.poll_id
  })
  newState[elementIndex].lock_status = payload.status
  return newState
}

function changeActiveStatus(state, payload) {
  const newState = [...state]

  const elementIndex = state.findIndex((element) => {
    return element?.poll_id === payload?.poll_id
  })

  if (elementIndex !== -1) newState[elementIndex].active_status = payload.status
  return newState
}

const updatePollData = (state, payload) =>
  state.map((poll) =>
    poll.poll_id === payload.pollData.poll_id
      ? { ...poll, ...payload.pollData }
      : poll
  )

const resetPoll = (state, payload) => {
  return state.map((poll) =>
    poll.poll_id === payload.poll_id ? { ...poll, ...payload } : poll
  )
}

export default pollReducer
