import React, { useState, useRef, useEffect, useContext } from 'react'
import '../../styles/drawer.css'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import SettingsIcon from '@mui/icons-material/Settings'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NavLinks from './NavLinks'
import moment from 'moment'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import PresentToAllRoundedIcon from '@mui/icons-material/PresentToAllRounded'
import { useSocket } from '../../context/socket.context'
import { DomainContext } from '../../context/domain.context'
import { openSnackbar } from '../../actions'
import useCopy from '../../hooks/useCopy'

export default function Drawer({
  drawerState,
  toggleDrawer,
  linkValueHandler,
  type = '',
}) {
  const { name } = useParams()
  const myActiveEvents = useSelector((state) => state.activeEvent)
  const { event } = useSelector((state) => state.events)
  const history = useHistory()
  const reduxDispatch = useDispatch()
  const [currTab, setCurrTab] = useState(0)
  const location = useLocation()
  const drawerRef = useRef()
  const drawerContainerRef = useRef()
  const mySocket = useSocket()
  const { clientUrl, brandName } = useContext(DomainContext)
  const { copy } = useCopy()

  //hide drawer
  useEffect(() => {
    if (drawerRef && drawerRef.current)
      drawerRef.current.addEventListener('click', hideDrawer)
  }, [])

  //hide drawer handler
  const hideDrawer = (e) => {
    if (!drawerContainerRef.current.contains(e.target)) handleHideDrawer()
  }

  const handleHideDrawer = () => {
    drawerState(false)
  }

  const getLinkVal = (val) => {
    setCurrTab(val)
    linkValueHandler(val)
  }

  //handle share
  const handleShare = () => {
    handleHideDrawer()
    if (currTab === 2) {
      return history.push(`/join-event/${event?.appUrl}`)
    }
    if (currTab === 3) {
      window.sessionStorage.setItem('location', `/join-event/${event?.appUrl}`)
      copy(`${clientUrl}/join-event/${event?.appUrl}?slide=true`)
      return
    }

    if (!myActiveEvents.type) {
      return reduxDispatch(openSnackbar('error', '⚠ No Active Events!'))
    }
    window.sessionStorage.setItem('location', `/join-event/${event?.appUrl}`)
    copy(`${clientUrl}/join-event/${event?.appUrl}`)
  }

  // handle presentation
  const handlePresentation = () => {
    let activeEventType = myActiveEvents.type
    if (activeEventType === 'poll' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activepolls`, {
        from: location.pathname,
      })
    }
    if (activeEventType === 'WC' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activewordcloud`, {
        from: location.pathname,
      })
    }
    if (activeEventType === 'rating' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activerating`, {
        from: location.pathname,
      })
    }
    if (activeEventType === 'rating' && currTab === 3) {
      return history.push(`/events/${name}/activerating`, {
        from: 'qna',
      })
    }
    if (activeEventType === 'poll' && currTab === 3) {
      return history.push(`/events/${name}/activepolls`, {
        from: 'qna',
      })
    }
    if (activeEventType === 'WC' && currTab === 3) {
      return history.push(`/events/${name}/activewordcloud`, {
        from: 'qna',
      })
    }
    if (activeEventType === 'quiz' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activequizzes`, {
        from: location.pathname,
      })
    }

    if (activeEventType === 'quiz' && currTab === 3) {
      return history.push(`/events/${name}/activequizzes`, {
        from: 'qna',
      })
    }

    //currTab = 3
    if (currTab === 4) {
      //make full screen
      return handleFullScreen()
    }

    if (!activeEventType) {
      let appUrl
      if (name) {
        appUrl = name
      } else {
        appUrl = event.appUrl
      }
      return history.push(`/events/${appUrl}/join-webmobi`, {
        from: location.pathname,
      })
    }
  }

  //handle full screen
  const handleFullScreen = () => {
    const elem = document.getElementById('slideContainer')
    if (!elem) return
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (elem.requestFullscreen) elem.requestFullscreen()
      else if (elem.mozRequestFullScreen)
        /* Firefox */
        elem.mozRequestFullScreen()
      else if (elem.webkitRequestFullscreen)
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      else if (elem.msRequestFullscreen)
        /* IE/Edge */ elem.msRequestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }
    mySocket.emit('SLIDE_FULL_SCREEN', {
      roomId: event?.appId,
    })
  }

  //handle setting
  const handleSetting = () => {
    //change the route to setting page
    history.push(`/events/${name}/setting/eventSettings/general`, {
      eventName: event.appUrl,
    })
  }

  return (
    <section
      ref={drawerRef}
      className={`drawer ${toggleDrawer ? '' : 'hideDrawer'}`}
    >
      <div ref={drawerContainerRef} className="drawer__container">
        <div className="drawer__header">
          <IconButton onClick={handleHideDrawer}>
            <ChevronLeftIcon className="leftArrowIcon" />
          </IconButton>
          <p>{brandName ? brandName : 'Ngage.ai'}</p>
          {name && type === 'event' && (
            <IconButton
              className="settingBtn"
              onClick={() => {
                handleSetting()
                handleHideDrawer()
              }}
            >
              <SettingsIcon className="settingIcn" />
            </IconButton>
          )}
        </div>
        {/* meeting information */}
        {name && (
          <div className="drawer__meeting">
            <p>
              {event?.appName}
              {' - '} #{name}
            </p>
            <span>
              {moment(event?.startdate).format('MMM Do YYYY')} {' - '}
              {moment(event?.enddate).format('MMM Do YYYY')}
            </span>
            <br />
            {type === 'event' && (
              <div className="drawer__actions">
                <IconButton
                  className="settingBtn ml-auto"
                  onClick={handleShare}
                  sx={{ color: 'white' }}
                >
                  <ShareRoundedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  className="settingBtn"
                  onClick={handlePresentation}
                  sx={{ color: 'white' }}
                >
                  <PresentToAllRoundedIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </div>
        )}
        {/* nav links */}
        <ul className="drawer__list">
          <NavLinks
            getLinkVal={getLinkVal}
            handleHideDrawer={handleHideDrawer}
            type={type}
          />
        </ul>
        <p className="drawer__about">
          <a href="https://ngage.ai/about-us" rel="noreferrer" target="_blank">
            About ngage.ai
          </a>
        </p>
      </div>
    </section>
  )
}
