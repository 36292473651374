import React, { useState, useEffect, useContext } from 'react'
import { healthAxios } from '../axiosConfig'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useStateValue } from './StateProvider'
import queryString from 'query-string'
import { openSnackbar } from '../actions'
import { useDispatch } from 'react-redux'
import { IFrameContext } from '../context/iframe.context'
import { DomainContext } from '../context/domain.context'
// ------mui components imports:start---------
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import ResendEmailModal from './customComponent/ResendEmailModal'
// ------mui imports:end---------

// --------- image imports:start ---------
import Unlock from './images/unlock.svg'
import googleImg from './images/google.svg'
// --------- image imports:end ---------

//--------styles import:start--------
import { makeStyles } from '@mui/styles'
import '../styles/newLogin.css'
import Loader from './customComponent/Loader'
import usePlanInfo from '../hooks/usePlanInfo'
//--------styles import:end--------

// ---- mui useStyle:start ----
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const useStylesLoginTwo = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))
// ---- mui useStyle:end ----

// --- login 1:start(using email and pass) ---
const LoginOne = (props) => {
  //--- use style ---
  const classes = useStyles()
  // --- states ---
  const [user, setUser] = useState({
    email: '',
    password: '',
    event_id: props.user.event_id,
    passkey: '',
    action: 'generate',
    deviceId: props.user.deviceId,
    deviceType: 'web',
  })
  const [openSpinner, setOpenSpinner] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  let { from } = location.state || { from: { pathname: '/events' } }
  const reduxDispatch = useDispatch()
  const { getPlanInfo } = usePlanInfo()

  //--- form submit handler----
  const handelFormSubmit = async (e) => {
    e.preventDefault()
    setOpenSpinner(true)
    try {
      const axiosRes = await healthAxios({
        url: '/login',
        method: 'POST',
        data: {
          email: user.email,
          password: user.password,
          type: 'lpadmin',
        },
        withCredentials: true,
      })
      if (axiosRes.data.response) {
        if (axiosRes?.data?.data?.email_verification === 'not verified') {
          setModalOpen(true)
          setOpenSpinner(false)
        } else {
          await getPlanInfo(axiosRes)
          setOpenSpinner(false)
          history.replace(from)
        }
      }
    } catch (error) {
      setOpenSpinner(false)
      let text =
        error?.response?.data?.responseString ||
        'incorrect username or password'
      reduxDispatch(openSnackbar('error', text))
    }
  }

  return (
    <>
      <Loader open={openSpinner} />
      <ResendEmailModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        email={user.email}
      />
      <form
        className={`${classes.root} formGroup__form`}
        autoComplete="off"
        onSubmit={handelFormSubmit}
        data-testid="form"
      >
        {/* --- email input:start --- */}
        <div className="inputGroup">
          <label htmlFor="Email" aria-labelledby="email">
            Email
          </label>
          <TextField
            className="inputGroup__input"
            id="email"
            variant="filled"
            name="email"
            type="email"
            placeholder="Johndoe@mendios.com"
            value={user.email}
            onChange={(e) => {
              setUser((prev) => {
                return { ...prev, email: e.target.value }
              })
            }}
            autoComplete="true"
            required={true}
          />
        </div>
        {/* --- email input:end --- */}
        <div className="inputGroup">
          <label htmlFor="Password" aria-labelledby="password">
            Password
          </label>
          <TextField
            className="inputGroup__input"
            id="password"
            variant="filled"
            name="password"
            type="password"
            placeholder=". . . . . . . . . . . . . . . . . . . ."
            value={user.password}
            onChange={(e) => {
              setUser((prev) => {
                return { ...prev, password: e.target.value }
              })
            }}
            autoComplete="true"
            required={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    className="login__btn"
                    data-testid="loginButton"
                  >
                    Log In
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </form>
    </>
  )
}
// --- login 1:end(using email and pass) ---

// --- login 2:start(using email and code) ---
const LoginTwo = (props) => {
  //--- use style ---
  const classes = useStylesLoginTwo()
  // ---state---
  const [spinnerOpen, setSpinnerOpen] = useState(false)
  const [user, setUser] = useState({
    email: '',
    code: '',
    event_id: props.user.event_id,
    passkey: '',
    action: 'generate',
    deviceId: props.user.deviceId,
    deviceType: 'web',
  })
  const [modalOpen, setModalOpen] = useState(false)
  const reduxDispatch = useDispatch()
  const [hideInput, setHideInput] = useState(true)
  const { getPlanInfo } = usePlanInfo()
  const location = useLocation()
  const history = useHistory()

  let { from } = location.state || { from: { pathname: '/events' } }

  //form submit handler
  const handelFormSubmit = async (e) => {
    e.preventDefault()
    setSpinnerOpen(true)
    try {
      let axiosRes

      if (hideInput === true) {
        axiosRes = await healthAxios({
          url: `/loginotp?email=${user.email}`,
          method: 'GET',
        })
        setSpinnerOpen(false)
        reduxDispatch(openSnackbar('success', axiosRes.data.message))
        setHideInput(false)
      } else {
        axiosRes = await healthAxios({
          url: `/loginotp`,
          method: 'POST',
          data: {
            email: user.email,
            otp: user.code,
          },
          withCredentials: true,
        })

        if (axiosRes?.data?.data?.email_verification === 'not verified') {
          setModalOpen(true)
          setSpinnerOpen(false)
        } else {
          await getPlanInfo(axiosRes)
          setSpinnerOpen(false)
          history.replace(from)
        }
      }

      setSpinnerOpen(false)
    } catch (error) {
      setSpinnerOpen(false)
      if (error?.response?.data?.errors?.length) {
        const text = error.response.data.errors[0].message
        reduxDispatch(openSnackbar('error', text))
      }
      console.error(error.message)
    }
  }

  return (
    <form
      className={`${classes.root} formGroup__form`}
      autoComplete="off"
      onSubmit={handelFormSubmit}
    >
      <Loader open={spinnerOpen} />
      <ResendEmailModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        email={user.email}
      />
      {/* --- email input:start --- */}
      <div className="inputGroup">
        <label htmlFor="Email">
          Enter Email Id to get the confirmation code
        </label>
        <TextField
          className="inputGroup__input"
          id="email"
          variant="filled"
          name="email"
          type="email"
          placeholder="Johndoe@mendios.com"
          value={user.email}
          onChange={(e) => {
            setHideInput(true)
            setUser((prev) => {
              return { ...prev, code: '', email: e.target.value }
            })
          }}
          InputProps={
            hideInput && {
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    className="login__btn"
                  >
                    Get Code
                  </Button>
                </InputAdornment>
              ),
            }
          }
          required={true}
          // disabled={true}
        />
      </div>
      {/* --- email input:end --- */}
      {hideInput === false && (
        <div className="inputGroup">
          <label htmlFor="Code">Code</label>
          <TextField
            className="inputGroup__input"
            id="code"
            variant="filled"
            name="code"
            type="text"
            placeholder="ZE123A"
            value={user.code}
            onChange={(e) => {
              setUser((prev) => {
                return { ...prev, code: e.target.value }
              })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    className="login__btn"
                  >
                    Log In
                  </Button>
                </InputAdornment>
              ),
            }}
            required={true}
          />
        </div>
      )}
    </form>
  )
}
// --- login 2:end(using email and code) ---

// -------- NewLogin component:start --------
function NewLogin() {
  //--- use style ---
  // --------- state:start ---------
  const [defaultForm, setDefaultForm] = useState(true)
  const [user] = useState({
    event_id: '',
    deviceId: '',
  })
  const { search } = useLocation()
  const {
    user_id,
    email,
    dashboardUserId,
    first_name,
    last_name,
    planType,
    profile_pic,
    id,
    state,
  } = queryString.parse(search)
  const [, dispatch] = useStateValue()
  const history = useHistory()
  const reduxDispatch = useDispatch()
  const [isIFrame] = useContext(IFrameContext)
  const { brandName } = useContext(DomainContext)
  // --------- state:end ---------

  // --------- side effects:start ---------
  useEffect(() => {
    if (state === 'succeeded') {
      reduxDispatch(openSnackbar('success', 'Payment successfull'))
    }
  }, [state, reduxDispatch])

  useEffect(() => {
    let didUnmount = false
    if (user_id && email && !didUnmount) {
      healthAxios({
        url: '/plan-information',
        method: 'POST',
        data: { email },
        withCredentials: true,
      })
        .then((planRes) => {
          dispatch({
            type: 'SET_USER',
            user: {
              userId: user_id,
              email,
              dashboardUserId,
              planType,
              first_name,
              last_name,
              profile_pic,
              username: first_name,
              planDetails: planRes.data.planData,
            },
          })
          //pendo code after user loggs in
          const pendo = window.pendo
          pendo.initialize({
            visitor: {
              id: dashboardUserId,
            },

            account: {
              id: dashboardUserId,
            },
          })
          if (window.localStorage.getItem('from')) {
            return history.replace(window.localStorage.getItem('from'))
          }
          history.replace('/events')
        })
        .catch((error) => {
          reduxDispatch(
            openSnackbar(
              'error',
              error?.response?.data?.responseString ?? error?.message
            )
          )
        })
    }

    return () => {
      didUnmount = true
    }
  }, [])

  useEffect(() => {
    let didUnmount = false
    //user account email verification
    if (email && id && !didUnmount) {
      healthAxios({
        url: '/verify',
        method: 'GET',
        params: {
          email,
          id,
        },
      })
        .then((res) => {
          //show alert to user
          reduxDispatch(
            openSnackbar(
              res.data?.response ? 'success' : 'error',
              res?.data?.responseString
            )
          )
          //redirection
          if (window.localStorage.getItem('from')) {
            return history.replace(window.localStorage.getItem('from'))
          }
          return history.replace('/events')
        })
        .catch((err) => {
          //show alert
          reduxDispatch(
            openSnackbar(
              'error',
              err.response.data?.responseString ?? err.message
            )
          )
        })
    }
  }, [])

  // --------- side effects:end ---------

  // --- form change handler
  const handleFormChange = () => {
    setDefaultForm((prev) => !prev)
  }

  //--- handle google login in function ---
  const handleGoogleLogin = async () => {
    window.location.href = process.env.REACT_APP_GOOGLEOAUTHURL
  }

  // --------- jsx:start ---------
  return (
    <section className="newLogin">
      {/* ------ header:start ------  */}
      <header className="header">
        <ul className="header__list">
          <li className="header__item brand">
            {brandName ? brandName : 'Ngage.ai'}
          </li>
          <li className="header__item btnGroup hide--sm show--lg">
            <Button
              variant="outlined"
              color="primary"
              className="btnGroup__btn"
              onClick={() => {
                return history.push('/join-event')
              }}
            >
              Join an event ? Click here !
            </Button>
          </li>
        </ul>
      </header>
      {/* ------ header:end ------  */}
      {/* ------- landing:start ------- */}
      <div className="landing">
        {/* ------ landing img:start ------- */}
        <div className="landing__imgWrapper">
          <img src={Unlock} alt="unlock" />
        </div>
        {/* ------ landing img:end ------- */}

        {/* ----- formGroup:start -----  */}
        <div className="formGroup">
          {defaultForm ? <LoginOne user={user} /> : <LoginTwo user={user} />}
          <div className="link__container">
            <p className={defaultForm ? 'newLogin__opt' : 'newLogin__opt2'}>
              or{' '}
              <span onClick={handleFormChange}>
                {defaultForm
                  ? 'Log In with Code'
                  : 'Log In with Email Id and Password'}
              </span>
            </p>
            {defaultForm && (
              <div className="linkWrapper">
                <Link to="/forgot-password" className="forgotPass">
                  forgot password?
                </Link>
              </div>
            )}
          </div>
          {isIFrame === false && (
            <>
              <p className="googleMsg">Or Log In with</p>

              <div className="googleicn" onClick={handleGoogleLogin}>
                <img src={googleImg} alt="google icon" />
              </div>
            </>
          )}
        </div>
        {/* ----- formGroup:end -----  */}
      </div>
      {/* ------- landing:end ------- */}
      {/* --- sign up message:start --- */}
      <div className="signupDiv--sm">
        <p className="loginOpt">
          Don't have an Account?{' '}
          <Link to="/register">Create a new account !</Link>
        </p>
      </div>
      <div className="joinEvent--sm">
        <Button
          variant="outlined"
          color="primary"
          className="btnGroup__btn"
          onClick={() => {
            return history.push('/join-event')
          }}
        >
          Join an event ? Click here !
        </Button>
      </div>
      <div className="signupDiv">
        <div className="signupDiv__circle">
          <div id="main__circle">
            <div className="signupDiv__msg">
              <h2>Log In</h2>
              <br />
              <h2>to your Account</h2>
              <p className="loginOpt">
                or <Link to="/register">Create a new account</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* --- sign up message:end --- */}
    </section>
  )
  // --------- jsx:end ---------
}
// -------- NewLogin component:end --------

export default NewLogin
