import React, { useState, useEffect, useContext } from 'react'
import { healthAxios } from '../../axiosConfig'
import QuestionImg from '../images/Questions.svg'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useStateValue } from '../StateProvider'
import { Button, IconButton } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import CheckIcon from '@mui/icons-material/Check'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import '../../styles/quizVote.css'
import { useSelector } from 'react-redux'
import { VoteContext } from '../../context/voting.context'
import { useSocket } from '../../context/socket.context'
import { VisitorContext } from '../../context/visitor.context'

import QuizTimer from './QuizTimer'
import Loader from '../customComponent/Loader'

const QuizOptions = ({ index, option, imgUrl, clicked, handleOptClick }) => {
  return (
    <li
      className="quizBox__listItem"
      key={option}
      onClick={() => {
        return handleOptClick(index, option)
      }}
    >
      {/* quiz image */}
      {imgUrl && (
        <span className="quizBox__image">
          <img src={imgUrl} alt={option} />
        </span>
      )}
      {/* custom radio button */}
      <IconButton
        className={`quizBox__iconButton ${
          clicked === true ? 'quizBox__iconButton--active' : null
        }`}
        size="small"
      >
        <CheckIcon
          className={`quizBox__icon ${
            clicked === true ? 'quizBox__icon--active' : null
          }`}
          fontSize="small"
        />
      </IconButton>
      {/* quiz question option name */}
      <span className="quizBox__option">{option}</span>
    </li>
  )
}

const VotingQuiz = ({ resetOpt }) => {
  const [state] = useContext(VoteContext)
  const [open, setOpen] = React.useState(false)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [activeQuestion, setActiveQuestion] = useState([])
  const [optClick, setOptClick] = useState([])
  const [opt, setOpt] = useState([])
  const [{ user }] = useStateValue()
  const [status, setStatus] = useState({
    msg: '',
    type: 'success',
  })
  const myAuth = useSelector((state) => state.auth)
  const socket = useSocket()
  const { visitorId } = useContext(VisitorContext)
  const [questionTime, setQuestionTime] = useState(0) //set from api
  const [timer, setTimer] = useState(0) //set throught setInterval
  const [isTimerQuiz, setIsTimerQuiz] = useState(false)

  useEffect(() => {
    setOpt([])
    if (state.activeEvent.event[0].question_array) {
      let myActiveQuesiton = state.activeEvent.event[0].question_array.filter(
        (value) => value.isActive === 1 || value.isActive === true
      )
      if (myActiveQuesiton.length > 0) {
        setActiveQuestion(myActiveQuesiton)
        let arr = []
        myActiveQuesiton[0].options.forEach((value) => {
          arr.push({ clicked: false })
        })
        setOptClick(arr)
      } else {
        setActiveQuestion([])
      }
    }
  }, [state.activeEvent.event[0], resetOpt])

  //api to set question timer
  useEffect(() => {
    const time = state.activeEvent.event[0]?.questionTime ?? 0
    setQuestionTime(time)
    setTimer(time)
    if (activeQuestion?.length > 0 && state.activeEvent.event[0].isTimerQuiz)
      setIsTimerQuiz(true)
    else setIsTimerQuiz(false)
  }, [activeQuestion])

  useEffect(() => {
    let willUnmout = false
    //socket event to update quiz question timer
    socket.on('UPDATE_QUIZ_TIMER', (timer) => {
      if (!willUnmout) setTimer(timer)
    })

    return () => {
      willUnmout = true
    }
  }, [])

  //snackbar handler
  const handleAlertOpen = () => {
    setAlertOpen(true)
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertOpen(false)
  }

  const handleOptClick = (index, value) => {
    let clone = [...optClick]
    clone[index].clicked = !clone[index].clicked
    setOptClick(clone)
    let isPresent = opt.findIndex((element) => {
      return element === value
    })
    if (isPresent !== -1) {
      let filtered = opt.filter((element, index) => {
        return isPresent !== index
      })
      setOpt(filtered)
    } else {
      setOpt((prev) => [...prev, value])
    }
  }

  const handleQuizAnsSubmit = async () => {
    try {
      handleAlertClose()
      setOpen(true)
      let axiosRes = await healthAxios({
        url: '/answerquiz',
        method: 'POST',
        data: {
          quiz_id: state.activeEvent.event[0].quiz_id,
          app_id: state.activeEvent.event[0].app_id,
          user_id: user?.userId ? user?.userId : visitorId,
          question_id: activeQuestion[0].question_id,
          answers: opt,
        },
      })
      if (axiosRes) {
        setOpen(false)

        handleAlertOpen()

        setStatus((status) => ({
          ...status,
          msg: axiosRes.data.isCorrect
            ? "Well done that's correct"
            : "That's not correct",
          type: axiosRes.data.isCorrect ? 'success' : 'error',
        }))
      }
    } catch (err) {
      setOpen(false)
      handleAlertOpen()
      setStatus((status) => ({
        ...status,
        msg: err?.response?.data?.responseString ?? err.message,
        type: 'error',
      }))
    }
  }

  return (
    <section className="quizVote">
      <Loader open={open} />
      {/* --------snackbar:start-------- */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={status.type}
          variant="filled"
        >
          {status.msg}
        </Alert>
      </Snackbar>
      {/* --------snackbar:end-------- */}
      {state.activeEvent.event[0] && (
        <div className="quizBox">
          <p className="quizBox__title">
            {state.activeEvent.event[0]?.quiz_name}
          </p>
          {/* )} */}
          {activeQuestion.length > 0 ? (
            <>
              <ul className="quizBox__list">
                {activeQuestion.length > 0 && (
                  <li className="quizBox__listItem--question">
                    <span className="quizQuestion">
                      {activeQuestion.length > 0 && activeQuestion[0].question}
                    </span>
                  </li>
                )}
                {activeQuestion.length > 0 ? (
                  activeQuestion[0].options.map((value, index) => {
                    return (
                      <QuizOptions
                        key={index}
                        index={index}
                        option={value.option}
                        imgUrl={value.imgUrl}
                        clicked={optClick[index]?.clicked}
                        handleOptClick={handleOptClick}
                      />
                    )
                  })
                ) : (
                  <div className="quizBox__vector">
                    <img src={QuestionImg} alt="question" />
                  </div>
                )}
              </ul>
              <div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                }}
                className="quizBox__btnGroup"
              >
                {Object.keys(state.activeEvent.event[0]).length > 0 && (
                  <Button
                    className={
                      state.activeEvent.event[0].lock_status === true ||
                      state.activeEvent.event[0].lock_status === 'true' ||
                      state.activeEvent.event[0].active_status === 'false' ||
                      state.activeEvent.event[0].active_status === false ||
                      opt.length === 0
                        ? 'submitBtn submitBtn--disabled'
                        : 'submitBtn'
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleQuizAnsSubmit}
                    disabled={
                      state.activeEvent.event[0].lock_status === true ||
                      state.activeEvent.event[0].lock_status === 'true' ||
                      state.activeEvent.event[0].active_status === 'false' ||
                      state.activeEvent.event[0].active_status === false ||
                      opt.length === 0
                        ? true
                        : false
                    }
                  >
                    Send
                  </Button>
                )}
                {(state.activeEvent.event[0].lock_status === true ||
                  state.activeEvent.event[0].lock_status === 'true') && (
                  <p className="quizBox__lockStatus">
                    this quiz is locked <LockIcon />
                  </p>
                )}
                {(state.activeEvent.event[0].active_status === 'false' ||
                  state.activeEvent.event[0].active_status === false) && (
                  <p className="quizBox__lockStatus">
                    this quiz is not active <PlayCircleFilledIcon />
                  </p>
                )}
                {/* auth status  */}
                <p className="anonymous-voting-text">
                  Attempting as{' '}
                  {!myAuth?.auth ? (
                    <span>Anonymous</span>
                  ) : (
                    <span>{user?.username}</span>
                  )}
                </p>

                {/* timer */}
                {isTimerQuiz &&
                  (state.activeEvent.event[0].active_status === 'true' ||
                    state.activeEvent.event[0].active_status === true) &&
                  (state.activeEvent.event[0].lock_status === false ||
                    state.activeEvent.event[0].lock_status === 'false') && (
                    <QuizTimer questionTime={questionTime} timer={timer} />
                  )}

                {/* privacy policy */}
                <a
                  href="https://ngage.ai/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#808080',
                    fontWeight: '500',
                    fontSize: '0.875rem',
                  }}
                >
                  Acceptable Use Privacy Policy
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="quizBox__vector">
                <img src={QuestionImg} alt="question image" />
              </div>
              <center>
                <h1>No Quiz Question Active</h1>
              </center>
            </>
          )}
        </div>
      )}
    </section>
  )
}

export default VotingQuiz
