import { useEffect } from 'react'

export default function useFbSDKInit() {
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        // autoLogAppEvents: true,
        cookie: true,
        xfbml: false,
        version: 'v14.0',
      })
      window.FB.getLoginStatus((response) => {
        console.log('fb-login-status', response)
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }, [])
}
