import SvgIcon from '@mui/material/SvgIcon'

function PresentIcon(props) {
  return (
    <SvgIcon {...props} viewBox="1.5 4.5 18.527 15.158">
      <path
        id="Icon_material-present-to-all_a"
        d="M 18.34269523620605 4.5 L 3.184269666671753 4.5 C 2.249500036239624 4.5 1.5 5.249499797821045 1.5 6.184269428253174 L 1.5 17.97415733337402 C 1.5 18.90892791748047 2.249500036239624 19.65842628479004 3.184269666671753 19.65842628479004 L 18.34269523620605 19.65842628479004 C 19.2774658203125 19.65842628479004 20.02696418762207 18.90892791748047 20.02696418762207 17.97415733337402 L 20.02696418762207 6.184269428253174 C 20.02696418762207 5.249499797821045 19.2774658203125 4.5 18.34269523620605 4.5 Z M 18.34269523620605 17.99100112915039 L 3.184269666671753 17.99100112915039 L 3.184269666671753 6.167426586151123 L 18.34269523620605 6.167426586151123 L 18.34269523620605 17.99099922180176 Z M 9.079214096069336 12.07921314239502 L 7.394943714141846 12.07921314239502 L 10.76348400115967 8.710674285888672 L 14.13202285766602 12.07921314239502 L 12.44775295257568 12.07921314239502 L 12.44775295257568 15.44775199890137 L 9.079214096069336 15.44775199890137 L 9.079214096069336 12.07921314239502 Z"
      ></path>
    </SvgIcon>
  )
}

export default PresentIcon
