import { TimerIcon } from '../customComponent/icons/TimerIcon'
const QuizTimer = ({ questionTime, timer }) => {
  return (
    <div className="quiz-timer">
      {/* clock icon */}
      <TimerIcon />
      {/* count down */}
      <div
        className="timer-count"
        style={{
          color: `${timer > 10 ? 'black' : 'red'}`,
        }}
      >
        {timer}sec
      </div>
      {/* progress bar */}
      <div className="timer-progress-wrapper">
        <div
          style={{
            width: `${Math.floor((100 / questionTime) * timer)}%`,
            backgroundColor: `${timer > 10 ? '#9627EA' : 'red'}`,
          }}
          className="timer-progress-bar"
        ></div>
      </div>
    </div>
  )
}

export default QuizTimer
