const initialState = []

const wordCloudReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_WC':
      return action.payload
    case 'CREATE_WC':
      return createWc(state, action)
    case 'DELETE_WC':
      return deleteWc(state, action)
    case 'CHANGE_WC_ACTIVE_STATUS':
      return changeWCActiveStatus(state, action)
    case 'CHANGE_WC_LOCK_STATUS':
      return changeWCLockStatus(state, action)
    case 'UPDATE_WC':
      return updateWordCloud(state, action)
    case 'CHANGE_WC_ORDER':
      return action?.payload?.wcData
    default:
      return state
  }
}

const createWc = (state, { payload }) => {
  const cloneState = [...state]
  cloneState.push(payload)
  return cloneState
}

const deleteWc = (state, { payload }) => {
  const cloneState = [...state]
  const updatedState = cloneState?.filter((wc) => {
    return wc.id !== parseInt(payload)
  })
  return updatedState
}

const changeWCActiveStatus = (state, { payload }) => {
  const { id, status } = payload
  const cloneState = [...state]
  for (let i = 0; i < cloneState.length; i++) {
    if (cloneState[i].id === parseInt(id)) {
      cloneState[i].active_status = status
      break
    }
  }
  return cloneState
}

const changeWCLockStatus = (state, { payload }) => {
  const { id, status } = payload
  const cloneState = [...state]
  for (let i = 0; i < cloneState.length; i++) {
    if (cloneState[i].id === parseInt(id)) {
      cloneState[i].lock_status = status
      break
    }
  }
  return cloneState
}

const updateWordCloud = (state, { payload }) => {
  const cloneState = [...state]
  const i = cloneState.findIndex((wc) => {
    return wc.id.toString() === payload.id.toString()
  })
  if (i !== -1) cloneState[i] = payload

  return cloneState
}
export default wordCloudReducer
