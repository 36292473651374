import { useMemo } from 'react'
import { useReducer, createContext } from 'react'

export const QnAContext = createContext([])

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_QNAS':
      return action.payload
    case 'CREATE_QNA':
      return createQnAQuestion(state, action.payload)
    case 'UPDATE_QNA':
      return state.map((qna) =>
        qna.question_id === action.payload.question_id
          ? { ...qna, ...action.payload }
          : qna
      )
    case 'DELETE_QNA':
      return state.filter(
        (qna) => qna.question_id !== action.payload.question_id
      )
    default:
      return state
  }
}

const createQnAQuestion = (state, payload) => {
  const index = state.findIndex(
    (qna) => qna.question_id === payload.question_id
  )
  if (index === -1) {
    return [...state, payload]
  }
  return state
}

export const QnAProvider = ({ children }) => {
  const [qna, dispatchQna] = useReducer(reducer, [])
  const value = useMemo(() => [qna, dispatchQna], [qna])
  return <QnAContext.Provider value={value}>{children}</QnAContext.Provider>
}
