import { checkAxios, healthAxios } from '../axiosConfig'

export const fetchSurveyFeedback = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/getFeedback?appId=${appId}`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const fetchSpeakerRating = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/get_speaker_ratings?appid=${appId}`,
    })
    if (data.response) return data.ratings
    return []
  } catch (error) {
    console.error(error)
  }
}

export const fetchAgendaRating = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/get_agenda_ratings?appid=${appId}`,
    })
    if (data.response) return data.ratings
    return []
  } catch (error) {
    console.error(error)
  }
}

export const fetchRegVsTicketed = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/user/loggedin_users?appid=${appId}&device_flag=2`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const NoOfUsers = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/user/loggedin_users?appid=${appId}&device_flag=1`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const AndroidNote = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/push_stats?appid=${appId}`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const loggedInReg = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/user/loggedin_users?appid=${appId}&device_flag=0`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const exportLeaderBoard = async (dashboardUserId, appId) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/event/export_leaderboard?userid=${dashboardUserId}&appid=${appId}&checkvalue=gamification1`,
    headers: { 'Content-Type': 'application/json' },
  })

  return data
}

export const fetchActivityStats = async (appId) => {
  const { data } = await checkAxios({
    method: 'GET',
    // url: '/event/activity_stats?appid=98df7a044a71a3cad6d8fef726c868bbc36b',
    url: `/event/activity_stats?appid=${appId}`,
    headers: { 'Content-Type': 'application/json' },
  })

  return data?.data
}

// /////Export survey feedBack

export const exportSurveyFeedback = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/getFeedback?appId=${appId}&export=true`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const exportSocialShare = async (appId) => {
  try {
    const { data } = await healthAxios({
      url: `/social-share/${appId}`,
      method: 'GET',
      responseType: 'arraybuffer',
    })
    const blob = new Blob([data])
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      'social-share-' + new Date().getTime() + '.xlsx'
    )
    link.click()
  } catch (error) {
    throw error
  }
}
