import { checkAxios } from '../axiosConfig'

export const editCreateAgenda = async (updateAgendaDetails) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/agenda',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...updateAgendaDetails,
      agenda_data: JSON.stringify([
        {
          ...updateAgendaDetails.agenda_data[0],
          speakerId: JSON.stringify(
            updateAgendaDetails.agenda_data[0].speakerId
          ),
        },
      ]),
    },
  })
  const payload = {
    ...updateAgendaDetails,
    ...updateAgendaDetails.agenda_data[0],
    agendaId: updateAgendaDetails.agenda_data[0].agenda_id,
  }
  delete payload.agenda_data
  delete payload.agenda_id
  return {
    response: data.response,
    payload: payload,
    type_id: payload.agendaId,
  }
}

export const deleteSession = async (deleteSessionData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: { ...deleteSessionData },
  })

  return { ...data, ...deleteSessionData }
}

///Post method for Uploading Xlsx file in Agenda

export const xlsxUpload = async (uploadXlsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/agenda',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...uploadXlsPayload,
      agenda_data: JSON.stringify(uploadXlsPayload.agenda_data),
    },
  })

  return { ...data, ...uploadXlsPayload }
}

export const exportToXlAgenda = async (exportXlsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/export_module_excel',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...exportXlsPayload,
    },
  })

  return { ...data, ...exportXlsPayload }
}
