const initialState = {
  auth: false,
  isLoading: true,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_AUTH':
      return isAuth(state, action.payload)
    case 'SET_AUTH_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}

const isAuth = (state, payload) => {
  const cloneState = { ...state }
  cloneState.auth = payload
  return cloneState
}

export default authReducer
