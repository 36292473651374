import { useState, useEffect, useContext } from 'react'
import { Button, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import LockIcon from '@mui/icons-material/Lock'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useStateValue } from '../StateProvider'
import { healthAxios } from '../../axiosConfig'
import Skeleton from '@mui/material/Skeleton'
import { VoteContext } from '../../context/voting.context'
import { VisitorContext } from '../../context/visitor.context'
import { useSelector } from 'react-redux'
import Loader from '../customComponent/Loader'

export default function VotingPoll({ app_id }) {
  const [state] = useContext(VoteContext)
  const [{ user }] = useStateValue()
  const [clickedIndex, setClickedIndex] = useState(null)
  const [voteOpt, setVoteOpt] = useState(null)
  const [open, setOpen] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    msg: '',
  })
  const myAuth = useSelector((state) => state.auth)
  const { visitorId } = useContext(VisitorContext)
  const pollId = state.activeEvent.event[0]?.poll_id

  useEffect(() => {
    setClickedIndex(null)
    setVoteOpt(null)
  }, [pollId])

  const handleVote = (index, opt) => {
    if (
      state.activeEvent.event[0]?.lock_status === false ||
      state.activeEvent.event[0]?.active_status === true
    ) {
      setClickedIndex(index)
      setVoteOpt(opt)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSnackbar((prev) => ({
      ...prev,
      open: false,
      msg: '',
    }))
    try {
      if (voteOpt) {
        setOpen(true)
        let userId
        if (user?.userId) {
          userId = user.userId
        } else {
          userId = visitorId
        }
        let axiosRes = await healthAxios({
          url: '/vote',
          method: 'post',
          data: {
            app_id: app_id,
            user_id: userId,
            poll_id: state.activeEvent.event[0]?.poll_id,
            option: voteOpt,
          },
        })
        if (axiosRes) {
          setOpen(false)
          let isCorrect = false,
            correctAns = []

          const options = axiosRes.data.data[0].options

          for (let i = 0; i < options.length; i++) {
            if (options[i].isCorrect) correctAns.push(options[i].optionValue)

            if (options[i].optionValue === voteOpt && options[i].isCorrect) {
              isCorrect = true
            }
          }

          // const isCorrect = correctAns.includes(voteOpt)

          setSnackbar((prev) => {
            return {
              ...prev,
              open: true,
              msg: isCorrect
                ? "Well done that's correct"
                : correctAns[0]
                ? "That's not correct"
                : 'Answer submitted',
              severity: isCorrect
                ? 'success'
                : correctAns[0]
                ? 'error'
                : 'success',
            }
          })
        }
      }
    } catch (error) {
      console.log(error)
      setOpen(false)
      setSnackbar((prev) => ({
        ...prev,
        open: true,
        msg: error?.response?.data?.responseString ?? 'Failed to vote',
        severity: 'error',
      }))
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbar((prev) => {
      return {
        ...prev,
        open: false,
      }
    })
  }
  return (
    <>
      <Loader open={open} />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.msg}
        </Alert>
      </Snackbar>
      {state?.activeEvent?.event?.length > 0 ? (
        <div className="pollVote">
          {state.activeEvent.event && state.activeEvent.event?.length > 0 ? (
            <p>Live Poll</p>
          ) : (
            <Skeleton height="40px" width="150px" />
          )}
          <form className="form form--poll" onSubmit={handleSubmit}>
            {state.activeEvent.event && state.activeEvent.event?.length > 0 ? (
              <p className="form__question">
                {state.activeEvent.event[0]?.question}
              </p>
            ) : (
              <Skeleton height="40px" />
            )}
            <ul className="form__list">
              {state?.activeEvent?.event &&
              state?.activeEvent?.event?.length > 0 ? (
                state?.activeEvent?.event[0]?.options.map((value, index) => {
                  return (
                    <li
                      className={`form__item ${
                        clickedIndex === index && clickedIndex !== null
                          ? 'form__item--active'
                          : null
                      }`}
                      key={index}
                      onClick={() => handleVote(index, value.optionValue)}
                    >
                      {/* image for poll */}
                      {value?.imgUrl.length > 0 && (
                        <div className="poll-image-body">
                          <img src={value.imgUrl} alt={value.optionValue} />
                        </div>
                      )}
                      {/* radio button */}
                      <IconButton
                        className={`form__iconButton ${
                          clickedIndex === index && clickedIndex !== null
                            ? 'form__iconButton--active'
                            : null
                        }`}
                        size="small"
                      >
                        <CheckIcon
                          className={`form__checkIcon ${
                            clickedIndex === index && clickedIndex !== null
                              ? 'form__checkIcon--active'
                              : null
                          }`}
                          fontSize="small"
                        />
                      </IconButton>
                      {/* option name */}
                      <span className="form__Opt">{value.optionValue}</span>
                    </li>
                  )
                })
              ) : (
                <>
                  <Skeleton animation="wave" height="50px" />
                  <Skeleton animation="wave" height="50px" />
                </>
              )}
            </ul>
            <div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="form__btnGroup"
            >
              {state.activeEvent.event &&
                state.activeEvent.event?.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    // size="small"
                    disabled={
                      state.activeEvent.event[0]?.lock_status === true ||
                      state.activeEvent.event[0]?.active_status === false
                        ? true
                        : false
                    }
                  >
                    Send
                  </Button>
                )}
              {state.activeEvent.event[0]?.lock_status === true && (
                <div className="lockStatus">
                  <LockIcon className="lockStatus__icon" />
                  <p className="lockStatus__msg">voting already closed</p>
                </div>
              )}
              {state.activeEvent.event[0]?.active_status === false && (
                <div className="lockStatus">
                  <PlayCircleFilledIcon className="lockStatus__icon" />
                  <p className="lockStatus__msg">poll in not active</p>
                </div>
              )}
              {/* auth status  */}
              <p className="anonymous-voting-text">
                Voting as{' '}
                {!myAuth?.auth ? (
                  <span>Anonymous</span>
                ) : (
                  <span>{user?.username}</span>
                )}
              </p>
              {/* privacy policy */}
              <a
                href="https://ngage.ai/privacy-policy"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: '#808080',
                  fontWeight: '500',
                  fontSize: '0.875rem',
                }}
              >
                Acceptable Use Privacy Policy
              </a>
            </div>
          </form>
        </div>
      ) : (
        <h1>no poll</h1>
      )}
    </>
  )
}
