import { useEffect } from 'react'

export default function useHelper() {
  useEffect(() => {
    //handle helper
    const showHelper = () => {
      return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = 'https://cdn.helpspace.com/widget/widget-v1.js'
        script.id = 'helpspace-widget-script'
        script.setAttribute('data-auto-init', 'true')
        script.setAttribute('data-token', process.env.REACT_APP_WIDGET_TOKEN)
        script.setAttribute(
          'data-client-id',
          process.env.REACT_APP_WIDGET_CLIENT_ID
        )
        script.setAttribute('data-widget-id', process.env.REACT_APP_WIDGET_ID)
        script.async = true
        document.body.appendChild(script)
        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }
      })
    }

    // showHelper()
    //   .then((res) => {
    //     if (res) {
    //       console.info('Help Widget loaded successfully')
    //     } else {
    //       console.info('Unable to load help widget')
    //     }
    //   })
    //   .catch((error) =>
    //     console.error('error while loading helper srcipt: ', error)
    //   )
  }, [])
}
