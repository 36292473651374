import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMusic,
  faSearch,
  faEnvelope,
  faHeart,
  faUser,
  faUsers,
  faFilm,
  faThLarge,
  faArrowTurnDown,
  faSquareCheck,
  faSquarePen,
  faSquareUpRight,
  faShareFromSquare,
  faMinus,
  faArrowTurnUp,
  faTableCellsLarge,
  faTableCells,
  faTableList,
  faCheck,
  faXmark,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faSignal,
  faGear,
  faTrashCan,
  faHouse,
  faFile,
  faClock,
  faRoad,
  faUpload,
  faInbox,
  faCirclePlay,
  faRotateRight,
  faArrowsRotate,
  faRectangleList,
  faLock,
  faFlag,
  faHeadphones,
  faVolumeOff,
  faVolumeLow,
  faVolumeHigh,
  faQrcode,
  faBarcode,
  faTag,
  faTags,
  faBook,
  faBookmark,
  faPrint,
  faCamera,
  faFont,
  faBold,
  faItalic,
  faTextHeight,
  faTextWidth,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faList,
  faOutdent,
  faIndent,
  faVideo,
  faImage,
  faPencil,
  faLocationDot,
  faCircleHalfStroke,
  faDroplet,
  faShare,
  faArrowsUpDownLeftRight,
  faBackwardStep,
  faBackwardFast,
  faBackward,
  faPlay,
  faPause,
  faStop,
  faForward,
  faFastForward,
  faForwardStep,
  faEject,
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faCircleMinus,
  faCircleCheck,
  faQuestion,
  faInfo,
  faCircleDot,
  faCircleXmark,
  faBan,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faPlus,
  faAsterisk,
  faCircleExclamation,
  faGift,
  faLeaf,
  faFire,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
  faPlane,
  faCalendarDays,
  faShuffle,
  faComment,
  faMagnet,
  faChevronUp,
  faChevronDown,
  faRetweet,
  faShoppingCart,
  faFolder,
  faFolderOpen,
  faArrowsUpDown,
  faArrowsLeftRight,
  faChartBar,
  faCameraRetro,
  faKey,
  faGears,
  faComments,
  faThumbsUp,
  faThumbsDown,
  faStarHalf,
  faRightFromBracket,
  faThumbtack,
  faArrowUpRightFromSquare,
  faRightToBracket,
  faTrophy,
  faSquarePhone,
  faUnlock,
  faCreditCard,
  faRss,
  faHardDrive,
  faBullhorn,
  faBell,
  faCertificate,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleArrowDown,
  faGlobe,
  faWrench,
  faBarsProgress,
  faFilter,
  faBriefcase,
  faMaximize,
  faLink,
  faCloud,
  faFlask,
  faScissors,
  faPaperclip,
  faFloppyDisk,
  faSquare as faSquareS,
  faBars,
  faStrikethrough,
  faWandMagicSparkles,
  faTruck,
  faMoneyBill,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faTableColumns,
  faSort,
  faSortUp,
  faSortDown,
  faUndo,
  faGauge,
  faBolt,
  faSitemap,
  faUmbrella,
  faPaste,
  faArrowRightArrowLeft,
  faCloudArrowDown,
  faCloudArrowUp,
  faUserDoctor,
  faSuitcase,
  faStethoscope,
  faCutlery,
  faTruckMedical,
  faSuitcaseMedical,
  faFighterJet,
  faSquareH,
  faPlusSquare,
  faAnglesLeft,
  faAnglesRight,
  faAnglesUp,
  faAnglesDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faDesktop,
  faLaptop,
  faTablet,
  faMobile,
  faQuoteRight,
  faSpinner,
  faCircle,
  faReply,
  faGamepad,
  faFlagCheckered,
  faCode,
  faReplyAll,
  faStarHalfStroke,
  faLocationArrow,
  faCrop,
  faUnlink,
  faExclamation,
  faSuperscript,
  faSubscript,
  faEraser,
  faMicrophone,
  faMicrophoneSlash,
  faShield,
  faFireExtinguisher,
  faRocket,
  faChevronCircleRight,
  faChevronCircleDown,
  faChevronCircleUp,
  faAnchor,
  faEllipsis,
  faEllipsisVertical,
  faSquareRss,
  faTicket,
} from '@fortawesome/free-solid-svg-icons'
import {
  faTwitterSquare,
  faFacebookSquare,
  faLinkedin,
  faGithubSquare,
  faTwitter,
  faFacebookF,
  faGithub,
  faPinterest,
  faPinterestSquare,
  faGooglePlusSquare,
  faGooglePlusG,
  faLinkedinIn,
  faMaxcdn,
  faHtml5,
  faCss3,
} from '@fortawesome/free-brands-svg-icons'

import {
  faStar,
  faHeart as faHeartO,
  faLemon,
  faSquare,
  faBookmark as faBookmarkO,
  faHandPointRight,
  faHandPointLeft,
  faHandPointUp,
  faHandPointDown,
  faCopy,
  faEnvelope as faEnvelopeO,
  faFile as faFileO,
  faBuilding,
  faHospital,
  faCircle as faCircleO,
  faFolder as faFolderO,
  faFolderOpen as faFolderOpenO,
  faPlusSquare as faPlusSquareO,
  faSmile,
  faFrown,
  faMeh,
  faKeyboard,
  faFlag as faFlagO,
  faSquareMinus,
} from '@fortawesome/free-regular-svg-icons'

const getIcon = (iconCls) => {
  switch (iconCls) {
    case 'music':
      return <FontAwesomeIcon icon={faMusic} />
    case 'search':
      return <FontAwesomeIcon icon={faSearch} />
    case 'envelope':
      return <FontAwesomeIcon icon={faEnvelope} />
    case 'heart':
      return <FontAwesomeIcon icon={faHeart} />
    case 'user':
      return <FontAwesomeIcon icon={faUser} />
    case 'group':
      return <FontAwesomeIcon icon={faUsers} />
    case 'users':
      return <FontAwesomeIcon icon={faUsers} />
    case 'film':
      return <FontAwesomeIcon icon={faFilm} />
    case 'th-large':
      return <FontAwesomeIcon icon={faThLarge} />
    case 'star':
      return <FontAwesomeIcon icon={faStar} />
    case 'level-down':
      return <FontAwesomeIcon icon={faArrowTurnDown} />
    case 'check-square':
      return <FontAwesomeIcon icon={faSquareCheck} />
    case 'pencil-square':
      return <FontAwesomeIcon icon={faSquarePen} />
    case 'external-link-square':
      return <FontAwesomeIcon icon={faSquareUpRight} />
    case 'share-square':
      return <FontAwesomeIcon icon={faShareFromSquare} />
    case 'minus':
      return <FontAwesomeIcon icon={faMinus} />
    case 'level-up':
      return <FontAwesomeIcon icon={faArrowTurnUp} />
    case 'th-large-table':
      return <FontAwesomeIcon icon={faTableCellsLarge} />
    case 'th':
      return <FontAwesomeIcon icon={faTableCells} />
    case 'th-list':
      return <FontAwesomeIcon icon={faTableList} />
    case 'check':
      return <FontAwesomeIcon icon={faCheck} />
    case 'times':
      return <FontAwesomeIcon icon={faXmark} />
    case 'search-plus':
      return <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
    case 'search-minus':
      return <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
    case 'signal':
      return <FontAwesomeIcon icon={faSignal} />
    case 'cog':
      return <FontAwesomeIcon icon={faGear} />
    case 'trash-o':
      return <FontAwesomeIcon icon={faTrashCan} />
    case 'home':
      return <FontAwesomeIcon icon={faHouse} />
    case 'file':
      return <FontAwesomeIcon icon={faFile} />
    case 'clock-o':
      return <FontAwesomeIcon icon={faClock} />
    case 'road':
      return <FontAwesomeIcon icon={faRoad} />
    case 'upload':
      return <FontAwesomeIcon icon={faUpload} />
    case 'inbox':
      return <FontAwesomeIcon icon={faInbox} />
    case 'play-circle':
      return <FontAwesomeIcon icon={faCirclePlay} />
    case 'repeat':
      return <FontAwesomeIcon icon={faRotateRight} />
    case 'refresh':
      return <FontAwesomeIcon icon={faArrowsRotate} />
    case 'list-alt':
      return <FontAwesomeIcon icon={faRectangleList} />
    case 'lock':
      return <FontAwesomeIcon icon={faLock} />
    case 'flag':
      return <FontAwesomeIcon icon={faFlag} />
    case 'headphones':
      return <FontAwesomeIcon icon={faHeadphones} />
    case 'volume-off':
      return <FontAwesomeIcon icon={faVolumeOff} />
    case 'volume-down':
      return <FontAwesomeIcon icon={faVolumeLow} />
    case 'volume-up':
      return <FontAwesomeIcon icon={faVolumeHigh} />
    case 'qrcode':
      return <FontAwesomeIcon icon={faQrcode} />
    case 'barcode':
      return <FontAwesomeIcon icon={faBarcode} />
    case 'tag':
      return <FontAwesomeIcon icon={faTag} />
    case 'tags':
      return <FontAwesomeIcon icon={faTags} />
    case 'book':
      return <FontAwesomeIcon icon={faBook} />
    case 'bookmark':
      return <FontAwesomeIcon icon={faBookmark} />
    case 'print':
      return <FontAwesomeIcon icon={faPrint} />
    case 'camera':
      return <FontAwesomeIcon icon={faCamera} />
    case 'font':
      return <FontAwesomeIcon icon={faFont} />
    case 'bold':
      return <FontAwesomeIcon icon={faBold} />
    case 'italic':
      return <FontAwesomeIcon icon={faItalic} />
    case 'text-height':
      return <FontAwesomeIcon icon={faTextHeight} />
    case 'text-width':
      return <FontAwesomeIcon icon={faTextWidth} />
    case 'align-left':
      return <FontAwesomeIcon icon={faAlignLeft} />
    case 'align-center':
      return <FontAwesomeIcon icon={faAlignCenter} />
    case 'align-right':
      return <FontAwesomeIcon icon={faAlignRight} />
    case 'align-jutify':
      return <FontAwesomeIcon icon={faAlignJustify} />
    case 'list':
      return <FontAwesomeIcon icon={faList} />
    case 'outdent':
      return <FontAwesomeIcon icon={faOutdent} />
    case 'indent':
      return <FontAwesomeIcon icon={faIndent} />
    case 'video-camera':
      return <FontAwesomeIcon icon={faVideo} />
    case 'picture-o':
      return <FontAwesomeIcon icon={faImage} />
    case 'pencil':
      return <FontAwesomeIcon icon={faPencil} />
    case 'map-marker':
      return <FontAwesomeIcon icon={faLocationDot} />
    case 'adjust':
      return <FontAwesomeIcon icon={faCircleHalfStroke} />
    case 'tint':
      return <FontAwesomeIcon icon={faDroplet} />
    case 'share':
      return <FontAwesomeIcon icon={faShare} />
    case 'arrows':
      return <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
    case 'step-backward':
      return <FontAwesomeIcon icon={faBackwardStep} />
    case 'fast-backward':
      return <FontAwesomeIcon icon={faBackwardFast} />
    case 'backward':
      return <FontAwesomeIcon icon={faBackward} />
    case 'play':
      return <FontAwesomeIcon icon={faPlay} />
    case 'pause':
      return <FontAwesomeIcon icon={faPause} />
    case 'stop':
      return <FontAwesomeIcon icon={faStop} />
    case 'forward':
      return <FontAwesomeIcon icon={faForward} />
    case 'fast-forward':
      return <FontAwesomeIcon icon={faFastForward} />
    case 'step-forward':
      return <FontAwesomeIcon icon={faForwardStep} />
    case 'eject':
      return <FontAwesomeIcon icon={faEject} />
    case 'chevron-left':
      return <FontAwesomeIcon icon={faChevronLeft} />
    case 'chevron-right':
      return <FontAwesomeIcon icon={faChevronRight} />
    case 'plus-circle':
      return <FontAwesomeIcon icon={faCirclePlus} />
    case 'minus-circle':
      return <FontAwesomeIcon icon={faCircleMinus} />
    case 'check-circle':
      return <FontAwesomeIcon icon={faCircleCheck} />
    case 'question':
      return <FontAwesomeIcon icon={faQuestion} />
    case 'info':
      return <FontAwesomeIcon icon={faInfo} />
    case 'dot-circle-o':
      return <FontAwesomeIcon icon={faCircleDot} />
    case 'times-circle-o':
      return <FontAwesomeIcon icon={faCircleXmark} />
    case 'ban':
      return <FontAwesomeIcon icon={faBan} />
    case 'arrow-left':
      return <FontAwesomeIcon icon={faArrowLeft} />
    case 'arrow-right':
      return <FontAwesomeIcon icon={faArrowRight} />
    case 'arrow-up':
      return <FontAwesomeIcon icon={faArrowUp} />
    case 'arrow-down':
      return <FontAwesomeIcon icon={faArrowDown} />
    case 'expand':
      return <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
    case 'compress':
      return <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
    case 'plus':
      return <FontAwesomeIcon icon={faPlus} />
    case 'asterisk':
      return <FontAwesomeIcon icon={faAsterisk} />
    case 'exclamation-circle':
      return <FontAwesomeIcon icon={faCircleExclamation} />
    case 'gift':
      return <FontAwesomeIcon icon={faGift} />
    case 'leaf':
      return <FontAwesomeIcon icon={faLeaf} />
    case 'fire':
      return <FontAwesomeIcon icon={faFire} />
    case 'eye':
      return <FontAwesomeIcon icon={faEye} />
    case 'eye-slash':
      return <FontAwesomeIcon icon={faEyeSlash} />
    case 'exclamation-triangle':
      return <FontAwesomeIcon icon={faTriangleExclamation} />
    case 'plane':
      return <FontAwesomeIcon icon={faPlane} />
    case 'calendar':
      return <FontAwesomeIcon icon={faCalendarDays} />
    case 'random':
      return <FontAwesomeIcon icon={faShuffle} />
    case 'comment':
      return <FontAwesomeIcon icon={faComment} />
    case 'magnet':
      return <FontAwesomeIcon icon={faMagnet} />
    case 'chevron-down':
      return <FontAwesomeIcon icon={faChevronDown} />
    case 'chevron-up':
      return <FontAwesomeIcon icon={faChevronUp} />
    case 'retweet':
      return <FontAwesomeIcon icon={faRetweet} />
    case 'shopping-cart':
      return <FontAwesomeIcon icon={faShoppingCart} />
    case 'folder':
      return <FontAwesomeIcon icon={faFolder} />
    case 'folder-open':
      return <FontAwesomeIcon icon={faFolderOpen} />
    case 'arrows-v':
      return <FontAwesomeIcon icon={faArrowsUpDown} />
    case 'arrows-h':
      return <FontAwesomeIcon icon={faArrowsLeftRight} />
    case 'bar-chart':
      return <FontAwesomeIcon icon={faChartBar} />
    case 'twitter-square':
      return <FontAwesomeIcon icon={faTwitterSquare} />
    case 'facebook-square':
      return <FontAwesomeIcon icon={faFacebookSquare} />
    case 'camera-retro':
      return <FontAwesomeIcon icon={faCameraRetro} />
    case 'key':
      return <FontAwesomeIcon icon={faKey} />
    case 'cogs':
      return <FontAwesomeIcon icon={faGears} />
    case 'comments':
      return <FontAwesomeIcon icon={faComments} />
    case 'thumbs-up':
      return <FontAwesomeIcon icon={faThumbsUp} />
    case 'thumbs-down':
      return <FontAwesomeIcon icon={faThumbsDown} />
    case 'star-half':
      return <FontAwesomeIcon icon={faStarHalf} />
    case 'heart-o':
      return <FontAwesomeIcon icon={faHeartO} />
    case 'sign-out':
      return <FontAwesomeIcon icon={faRightFromBracket} />
    case 'linkedin-square':
      return <FontAwesomeIcon icon={faLinkedin} />
    case 'thumb-tack':
      return <FontAwesomeIcon icon={faThumbtack} />
    case 'external-link':
      return <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    case 'sign-In':
      return <FontAwesomeIcon icon={faRightToBracket} />
    case 'trophy':
      return <FontAwesomeIcon icon={faTrophy} />
    case 'github-square':
      return <FontAwesomeIcon icon={faGithubSquare} />
    case 'lemon-o':
      return <FontAwesomeIcon icon={faLemon} />
    case 'square-o':
      return <FontAwesomeIcon icon={faSquare} />
    case 'bookmark-o':
      return <FontAwesomeIcon icon={faBookmarkO} />
    case 'phone-square':
      return <FontAwesomeIcon icon={faSquarePhone} />
    case 'twitter':
      return <FontAwesomeIcon icon={faTwitter} />
    case 'facebook':
      return <FontAwesomeIcon icon={faFacebookF} />
    case 'github':
      return <FontAwesomeIcon icon={faGithub} />
    case 'unlock':
      return <FontAwesomeIcon icon={faUnlock} />
    case 'credit-card':
      return <FontAwesomeIcon icon={faCreditCard} />
    case 'rss':
      return <FontAwesomeIcon icon={faRss} />
    case 'hdd-o':
      return <FontAwesomeIcon icon={faHardDrive} />
    case 'bullhorn':
      return <FontAwesomeIcon icon={faBullhorn} />
    case 'bell':
      return <FontAwesomeIcon icon={faBell} />
    case 'certificate':
      return <FontAwesomeIcon icon={faCertificate} />
    case 'hand-o-right':
      return <FontAwesomeIcon icon={faHandPointRight} />
    case 'hand-o-left':
      return <FontAwesomeIcon icon={faHandPointLeft} />
    case 'hand-o-up':
      return <FontAwesomeIcon icon={faHandPointUp} />
    case 'hand-o-down':
      return <FontAwesomeIcon icon={faHandPointDown} />
    case 'arrow-circle-left':
      return <FontAwesomeIcon icon={faCircleArrowLeft} />
    case 'arrow-circle-right':
      return <FontAwesomeIcon icon={faCircleArrowRight} />
    case 'arrow-circle-up':
      return <FontAwesomeIcon icon={faCircleArrowUp} />
    case 'arrow-circle-down':
      return <FontAwesomeIcon icon={faCircleArrowDown} />
    case 'globe':
      return <FontAwesomeIcon icon={faGlobe} />
    case 'wrench':
      return <FontAwesomeIcon icon={faWrench} />
    case 'task':
      return <FontAwesomeIcon icon={faBarsProgress} />
    case 'filter':
      return <FontAwesomeIcon icon={faFilter} />
    case 'briefcase':
      return <FontAwesomeIcon icon={faBriefcase} />
    case 'arrows-alt':
      return <FontAwesomeIcon icon={faMaximize} />
    case 'link':
      return <FontAwesomeIcon icon={faLink} />
    case 'cloud':
      return <FontAwesomeIcon icon={faCloud} />
    case 'flask':
      return <FontAwesomeIcon icon={faFlask} />
    case 'cut':
      return <FontAwesomeIcon icon={faScissors} />
    case 'copy':
      return <FontAwesomeIcon icon={faCopy} />
    case 'papercip':
      return <FontAwesomeIcon icon={faPaperclip} />
    case 'save':
      return <FontAwesomeIcon icon={faFloppyDisk} />
    case 'square':
      return <FontAwesomeIcon icon={faSquareS} />
    case 'reorder':
      return <FontAwesomeIcon icon={faBars} />
    case 'list-ul':
      return <FontAwesomeIcon icon={faList} />
    case 'strikethrough':
      return <FontAwesomeIcon icon={faStrikethrough} />
    case 'magic':
      return <FontAwesomeIcon icon={faWandMagicSparkles} />
    case 'truck':
      return <FontAwesomeIcon icon={faTruck} />
    case 'pinterest':
      return <FontAwesomeIcon icon={faPinterest} />
    case 'pinterest-square':
      return <FontAwesomeIcon icon={faPinterestSquare} />
    case 'google-plus-square':
      return <FontAwesomeIcon icon={faGooglePlusSquare} />
    case 'google-plus':
      return <FontAwesomeIcon icon={faGooglePlusG} />
    case 'money':
      return <FontAwesomeIcon icon={faMoneyBill} />
    case 'caret-down':
      return <FontAwesomeIcon icon={faCaretDown} />
    case 'caret-up':
      return <FontAwesomeIcon icon={faCaretUp} />
    case 'caret-left':
      return <FontAwesomeIcon icon={faCaretLeft} />
    case 'caret-right':
      return <FontAwesomeIcon icon={faCaretRight} />
    case 'column':
      return <FontAwesomeIcon icon={faTableColumns} />
    case 'sort':
      return <FontAwesomeIcon icon={faSort} />
    case 'sort-up':
      return <FontAwesomeIcon icon={faSortUp} />
    case 'sort-down':
      return <FontAwesomeIcon icon={faSortDown} />
    case 'envelope-o':
      return <FontAwesomeIcon icon={faEnvelopeO} />
    case 'linkedin':
      return <FontAwesomeIcon icon={faLinkedinIn} />
    case 'undo':
      return <FontAwesomeIcon icon={faUndo} />
    case 'dashboard':
      return <FontAwesomeIcon icon={faGauge} />
    case 'bolt':
      return <FontAwesomeIcon icon={faBolt} />
    case 'sitemap':
      return <FontAwesomeIcon icon={faSitemap} />
    case 'umbrella':
      return <FontAwesomeIcon icon={faUmbrella} />
    case 'paste':
      return <FontAwesomeIcon icon={faPaste} />
    case 'exchange':
      return <FontAwesomeIcon icon={faArrowRightArrowLeft} />
    case 'cloud-download':
      return <FontAwesomeIcon icon={faCloudArrowDown} />
    case 'cloud-upload':
      return <FontAwesomeIcon icon={faCloudArrowUp} />
    case 'user-md':
      return <FontAwesomeIcon icon={faUserDoctor} />
    case 'suitcase':
      return <FontAwesomeIcon icon={faSuitcase} />
    case 'stethoscope':
      return <FontAwesomeIcon icon={faStethoscope} />
    case 'cutlery':
      return <FontAwesomeIcon icon={faCutlery} />
    case 'file-o':
      return <FontAwesomeIcon icon={faFileO} />
    case 'building-o':
      return <FontAwesomeIcon icon={faBuilding} />
    case 'hospital-o':
      return <FontAwesomeIcon icon={faHospital} />
    case 'ambulance':
      return <FontAwesomeIcon icon={faTruckMedical} />
    case 'medkit':
      return <FontAwesomeIcon icon={faSuitcaseMedical} />
    case 'fighter-jet':
      return <FontAwesomeIcon icon={faFighterJet} />
    case 'h-square':
      return <FontAwesomeIcon icon={faSquareH} />
    case 'plus-square':
      return <FontAwesomeIcon icon={faPlusSquare} />
    case 'angle-double-left':
      return <FontAwesomeIcon icon={faAnglesLeft} />
    case 'angle-double-right':
      return <FontAwesomeIcon icon={faAnglesRight} />
    case 'angle-double-up':
      return <FontAwesomeIcon icon={faAnglesUp} />
    case 'angle-double-down':
      return <FontAwesomeIcon icon={faAnglesDown} />
    case 'angle-left':
      return <FontAwesomeIcon icon={faAngleLeft} />
    case 'angle-right':
      return <FontAwesomeIcon icon={faAngleRight} />
    case 'angle-up':
      return <FontAwesomeIcon icon={faAngleUp} />
    case 'angle-down':
      return <FontAwesomeIcon icon={faAngleDown} />
    case 'desktop':
      return <FontAwesomeIcon icon={faDesktop} />
    case 'laptop':
      return <FontAwesomeIcon icon={faLaptop} />
    case 'tablet':
      return <FontAwesomeIcon icon={faTablet} />
    case 'mobile-phone':
      return <FontAwesomeIcon icon={faMobile} />
    case 'circle-o':
      return <FontAwesomeIcon icon={faCircleO} />
    case 'quote-right':
      return <FontAwesomeIcon icon={faQuoteRight} />
    case 'spinner':
      return <FontAwesomeIcon icon={faSpinner} />
    case 'circle':
      return <FontAwesomeIcon icon={faCircle} />
    case 'reply':
      return <FontAwesomeIcon icon={faReply} />
    case 'folder-o':
      return <FontAwesomeIcon icon={faFolderO} />
    case 'folder-open-o':
      return <FontAwesomeIcon icon={faFolderOpenO} />
    case 'plus-square-o':
      return <FontAwesomeIcon icon={faPlusSquareO} />
    case 'smile-o':
      return <FontAwesomeIcon icon={faSmile} />
    case 'frown-o':
      return <FontAwesomeIcon icon={faFrown} />
    case 'meh-o':
      return <FontAwesomeIcon icon={faMeh} />
    case 'gamepad':
      return <FontAwesomeIcon icon={faGamepad} />
    case 'keyboard':
      return <FontAwesomeIcon icon={faKeyboard} />
    case 'flag-o':
      return <FontAwesomeIcon icon={faFlagO} />
    case 'flag-checkered':
      return <FontAwesomeIcon icon={faFlagCheckered} />
    case 'code':
      return <FontAwesomeIcon icon={faCode} />
    case 'reply-all':
      return <FontAwesomeIcon icon={faReplyAll} />
    case 'star-half-full':
      return <FontAwesomeIcon icon={faStarHalfStroke} />
    case 'location-arrow':
      return <FontAwesomeIcon icon={faLocationArrow} />
    case 'crop':
      return <FontAwesomeIcon icon={faCrop} />
    case 'unlink':
      return <FontAwesomeIcon icon={faUnlink} />
    case 'exclamation':
      return <FontAwesomeIcon icon={faExclamation} />
    case 'superscript':
      return <FontAwesomeIcon icon={faSuperscript} />
    case 'subscript':
      return <FontAwesomeIcon icon={faSubscript} />
    case 'eraser':
      return <FontAwesomeIcon icon={faEraser} />
    case 'microphone':
      return <FontAwesomeIcon icon={faMicrophone} />
    case 'microphone-slash':
      return <FontAwesomeIcon icon={faMicrophoneSlash} />
    case 'shield':
      return <FontAwesomeIcon icon={faShield} />
    case 'fire-extinguisher':
      return <FontAwesomeIcon icon={faFireExtinguisher} />
    case 'rocket':
      return <FontAwesomeIcon icon={faRocket} />
    case 'maxcdn':
      return <FontAwesomeIcon icon={faMaxcdn} />
    case 'chevron-circle-right':
      return <FontAwesomeIcon icon={faChevronCircleRight} />
    case 'chevron-circle-down':
      return <FontAwesomeIcon icon={faChevronCircleDown} />
    case 'chevron-circle-up':
      return <FontAwesomeIcon icon={faChevronCircleUp} />
    case 'html5':
      return <FontAwesomeIcon icon={faHtml5} />
    case 'css3':
      return <FontAwesomeIcon icon={faCss3} />
    case 'anchor':
      return <FontAwesomeIcon icon={faAnchor} />
    case 'ellipsis-h':
      return <FontAwesomeIcon icon={faEllipsis} />
    case 'ellipsis-v':
      return <FontAwesomeIcon icon={faEllipsisVertical} />
    case 'rss-square':
      return <FontAwesomeIcon icon={faSquareRss} />
    case 'ticket':
      return <FontAwesomeIcon icon={faTicket} />
    case 'minus-square-o':
      return <FontAwesomeIcon icon={faSquareMinus} />
    default:
      return <FontAwesomeIcon icon={faMusic} />
  }
}

export default getIcon
