import { useEffect, useState } from 'react'

export const useOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const setOnline = () => {
      console.log('We are online!')
      setIsOnline(true)
    }
    const setOffline = () => {
      console.log('We are offline!')
      setIsOnline(false)
    }
    window.addEventListener('offline', setOffline)
    window.addEventListener('online', setOnline)

    // cleanup if unmount
    return () => {
      window.removeEventListener('offline', setOffline)
      window.removeEventListener('online', setOnline)
    }
  }, [])

  return { isOnline }
}
