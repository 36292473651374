let initialState = {
  events: [],
  event: {},
}

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EVENTS':
      return { ...state, events: action.payload }
    case 'SET_EVENT':
      return { ...state, event: action.payload }
    case 'DELETE_EVENT':
      return {
        ...state,
        events: state.events.filter((event) => event.appid !== action.payload),
      }
    default:
      return state
  }
}

export default eventReducer
