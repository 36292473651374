import { useState, useRef, forwardRef } from 'react'
import { nanoid } from 'nanoid/async'
import moment from 'moment'
import { Avatar, Box, InputAdornment, Typography } from '@mui/material'
import styles from '../../../styles/streamRightSidebar.module.css'
import { SidebarPanel } from '.'
import { RSTextField } from '../../customComponent/RSTextField'
import { SendIconBtn } from '../../customComponent/SendIconBtn'
import { useSocket } from '../../../context/socket.context'
import { useAuth } from '../../../context/auth.context'
import { DateSeperator, isTodayOrYesterday } from './Chats'
import { useDiscussion } from '../../../context/Discussion.context'

export default function Discussion({ setTab }) {
  const { state } = useDiscussion()
  const discussionRef = useRef()
  let prevDate = ''
  const msgList = state.map((msg) => {
    const currentDate = moment(msg.create_date).format('L')
    let uniqueDate
    if (currentDate !== prevDate) {
      prevDate = currentDate
      uniqueDate = isTodayOrYesterday(currentDate)
    }
    return (
      <div key={msg.id}>
        {uniqueDate && <DateSeperator date={uniqueDate} />}
        <DiscussionMsg
          message={msg.message}
          user={msg.from}
          createdAt={msg.create_date}
        />
      </div>
    )
  })

  return (
    <SidebarPanel title="Discussion" setTab={setTab}>
      <Box
        sx={{
          height: 'calc(100% - 6rem)',
          overflow: 'auto',
          padding: '0 0.25rem',
          scrollBehavior: 'smooth',
        }}
        ref={discussionRef}
      >
        {state?.length ? (
          msgList
        ) : (
          <small>Be the first to start discussion</small>
        )}
      </Box>
      <DiscussionForm ref={discussionRef} />
    </SidebarPanel>
  )
}

function DiscussionMsg({ message, user, createdAt }) {
  return (
    <Box className={styles.DiscussionMsg}>
      <Avatar
        alt={user.name}
        src={user?.profile_pic}
        sx={{ width: 32, height: 32 }}
      />
      <Box className={styles.DiscussionMsgText}>
        <Typography
          variant="subtitle2"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '0.125rem',
          }}
        >
          {user.name}
          <small className={styles.time}>
            {moment(createdAt).format('hh:mm a')}
          </small>
        </Typography>
        <small>{message}</small>
      </Box>
    </Box>
  )
}

const DiscussionForm = forwardRef((props, ref) => {
  const socket = useSocket()
  const { authUser } = useAuth()
  const [message, setMessage] = useState('')
  const { dispatch } = useDiscussion()

  const handleChange = (e) => {
    setMessage(e.target.value)
  }
  const sendMsg = async (e) => {
    e.preventDefault()
    const msg = message.trim()
    if (!msg) return
    const id = await nanoid()
    const create_date = new Date().getTime()
    socket.emit('group discussion', {
      id,
      content: msg,
      to: socket.auth.appId,
      create_date,
    })
    dispatch({
      type: 'create',
      payload: { id, message, from: authUser, create_date },
    })
    setMessage('')
    const scrollToHeight = ref.current.scrollHeight
    ref.current.scrollTop = scrollToHeight
  }

  return (
    <form onSubmit={sendMsg}>
      <RSTextField
        size="small"
        multiline
        className={styles.RSTextField}
        placeholder="Type here"
        value={message}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SendIconBtn onClick={sendMsg} />
            </InputAdornment>
          ),
        }}
      />
    </form>
  )
})
