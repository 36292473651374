import React from 'react'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Container } from '@mui/material'
import SocialShare from './customComponent/Social'
import WebMobiLogo from '../../src/webMOBI/img/logo.png'
import { useDomain } from '../context/domain.context'
//import stryles
import '../styles/joinpage.css'

//component start
const JoinWebMobi = () => {
  const myTheme = useSelector((state) => state.theme)
  const myEvents = useSelector((state) => state.events)
  const { clientUrl, brandLogo } = useDomain()

  return (
    <section
      className="joinpage"
      style={{
        background: `url(${myTheme?.bgImg ?? ''})`,
        backgroundColor: `${myTheme?.bgClr ?? ''}`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        objectFit: 'cover',
      }}
    >
      <Container>
        {/* ----------navbar:start------------ */}
        <nav className="navbar">
          {/* ----------navbar list:end---------- */}
          <ul className="navbar__list">
            {/* -------navbar brand:start--------- */}
            <li
              className="navbar__item navbar__brand"
              style={{ color: `${myTheme?.txtClr ?? ''}` }}
            >
              {myTheme?.logo && myTheme?.logo !== 'undefined' ? (
                <Link
                  to={`/events/${myEvents?.event?.appUrl}`}
                  style={{ height: '80px' }}
                >
                  <img
                    src={`${myTheme?.logo}`}
                    alt="brand logo"
                    width="80px"
                    height="80px"
                  />
                </Link>
              ) : (
                <Link
                  to={`/events/${myEvents?.event?.appUrl}`}
                  style={{ height: '70px' }}
                >
                  <img
                    src={brandLogo ? brandLogo : WebMobiLogo}
                    alt="brand logo"
                    width="170px"
                    height="70px"
                  />
                </Link>
              )}
            </li>
            {/* -------navbar brand:end--------- */}

            <li className="navbar__item">
              {/* -------navbar social icons:start--------- */}
              <div className="navbar__social">
                <p style={{ color: `${myTheme?.txtClr ?? ''}` }}>
                  Made by Ngage.ai
                </p>
                <div className="navbar__iconGroup">
                  <SocialShare shareLink={`${clientUrl}/join-event`} />
                </div>
              </div>
              {/* -------navbar social icons:end--------- */}
            </li>
          </ul>
          {/* ----------navbar list:end---------- */}
        </nav>
        {/* ------------navbar:end---------------- */}

        {/* ---------body:start--------- */}
        <div className="body">
          {/* ---------qr code container:start--------- */}
          <div className="qrContainer">
            {/* ---------qr code:start--------- */}
            <div className="qrContainer__code">
              <p style={{ color: `${myTheme?.otherOptClr ?? ''}` }}>
                Scan the QR Code to Vote
              </p>
              <div>
                <div>
                  <QRCode value={`${clientUrl}/join-event`} className="my_qr" />
                </div>
              </div>
            </div>
            {/* ---------qr code:end--------- */}

            {/* ------------qr code description:start------------ */}
            <div className="qrContainer__desc">
              <p style={{ color: `${myTheme?.txtClr ?? ''}` }}>
                Join Ngage.ai at
              </p>
              <p style={{ color: `${myTheme?.txtClr ?? ''}` }}>
                #{myEvents?.event?.appUrl ?? 'webMOBI'}
              </p>
              <Link to={`/events/${myEvents?.event?.appUrl}`}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: myTheme?.bgClr,
                    color: myTheme?.txtClr,
                    fontWeight: 'bold',
                  }}
                >
                  Create Poll
                </Button>
              </Link>
            </div>
            {/* ------------qr code description:end------------ */}
          </div>
          {/* ---------qr code container:end--------- */}
        </div>
        {/* ---------body:end--------- */}
      </Container>
    </section>
  )
}

export default JoinWebMobi
