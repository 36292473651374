import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ServiceWorkerWrapper from './ServiceWorkerWrapper'
import { BrowserRouter as Router } from 'react-router-dom'
import { LoadingProvider } from './context/loading.context'
import { DomainProvider } from './context/domain.context'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { StateProvider } from './components/StateProvider'
import reducer, { initialState } from './components/reducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import allReducers from './reducers/index'
import { PersistGate } from 'redux-persist/integration/react'
import { IFrameProvider } from './context/iframe.context'
import './styles/styles.css'
import * as msTeams from '@microsoft/teams-js'
import { SocketProvider } from './context/socket.context'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QnAProvider } from './context/qna.context'
import { LiveEventProvider } from './context/liveEvent.context'
import { CaptureConsole } from '@sentry/integrations'

msTeams.initialize()

const history = createBrowserHistory()

const isDevelopment = process.env.REACT_APP_NODE_ENV === 'dev'
const isStaging = process.env.REACT_APP_NODE_ENV === 'staging'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  tracesSampleRate: 1.0,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'featureSettings', 'snackbar', 'ppt', 'presentations'],
}

const persistedReducer = persistReducer(persistConfig, allReducers)
//TODO:remove redux devtool in production
let store = createStore(persistedReducer, composeWithDevTools())

let persistor = persistStore(store)

const theme = createTheme({
  palette: {
    primary: {
      main: '#9627EA',
      light: '#a637ea4a',
    },
    secondary: {
      main: '#27ea96',
    },
    _grey: {
      main: '#00000099',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        // Name of the slots
        paper: {
          // Some CSS
          margin: '0.5rem',
          borderRadius: '0.75rem',
        },
        paperFullWidth: {
          width: 'calc(100% - 16px)',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: '1600px',
        },
      },
    },
  },
})

// function FallbackComponent() {
//   return <div>An error has occurred</div>
// }

// const myFallback = <FallbackComponent />

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
    },
  },
})

ReactDOM.render(
  <>
    <ServiceWorkerWrapper />

    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <StateProvider initialState={initialState} reducer={reducer}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <IFrameProvider>
                  <SocketProvider>
                    <LoadingProvider>
                      {isDevelopment || isStaging ? (
                        <>
                          <LiveEventProvider>
                            <QnAProvider>
                              <Router>
                                <App />
                              </Router>
                            </QnAProvider>
                          </LiveEventProvider>
                        </>
                      ) : (
                        <DomainProvider>
                          <LiveEventProvider>
                            <QnAProvider>
                              <Router>
                                <App />
                              </Router>
                            </QnAProvider>
                          </LiveEventProvider>
                        </DomainProvider>
                      )}
                    </LoadingProvider>
                  </SocketProvider>
                </IFrameProvider>
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </StateProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </React.StrictMode>
  </>,
  document.getElementById('root')
)

reportWebVitals()
