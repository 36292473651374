import { Box, Button, Container } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFound = ({homePath}) => {
  
  return (
    <Container>
      <Box sx={{ p: '1rem', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
          <h1>404 Not Found</h1>
          <Button variant="outlined" component={Link} to={homePath}>
            Home
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
export default NotFound
