import SvgIcon from '@mui/material/SvgIcon'

function EventIcon(props) {
  return (
    <SvgIcon {...props} viewBox="4.5 4.5 27 27">
      <path d="M 28.5 4.5 L 7.5 4.5 C 5.849999904632568 4.5 4.5 5.849999904632568 4.5 7.5 L 4.5 28.5 C 4.5 30.14999961853027 5.849999904632568 31.5 7.5 31.5 L 28.5 31.5 C 30.14999961853027 31.5 31.5 30.14999961853027 31.5 28.5 L 31.5 7.5 C 31.5 5.849999904632568 30.14999961853027 4.5 28.5 4.5 Z M 13.5 25.5 L 10.5 25.5 L 10.5 15 L 13.5 15 L 13.5 25.5 Z M 19.5 25.5 L 16.5 25.5 L 16.5 10.5 L 19.5 10.5 L 19.5 25.5 Z M 25.5 25.5 L 22.5 25.5 L 22.5 19.5 L 25.5 19.5 L 25.5 25.5 Z"></path>
    </SvgIcon>
  )
}

export default EventIcon
