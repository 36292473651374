import {
  useState,
  useEffect,
  Suspense,
  createContext,
  useMemo,
  memo,
  lazy,
} from 'react'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import {
  Box,
  createTheme,
  ThemeProvider,
  useTheme,
  Drawer,
} from '@mui/material'

import StreamProtectedRoute from './StreamProtectedRoute'
import { useAppData, useFetchAttendee } from '../../webMOBI/Hooks/useAppData'
import { useSocket } from '../../context/socket.context'
import { DiscussionProvider } from '../../context/Discussion.context'
import { VotingProvider } from '../../context/voting.context'
import { ChatProvider } from '../../context/Chat.context'
import { useAuth } from '../../context/auth.context'
import useChatEffect from '../../hooks/useChatEffect'
import useQnAEffect from '../../hooks/useQnAEffect'
import { getZoneDateTime } from '../../utils/getCurrentZoneDateTime'
import { StreamSurvey } from './StreamSurvey'
import styles from '../../styles/stream.module.css'
import StreamSpeakerSkeleton from './StreamSpeakerSkeleton'
import { LoaderText } from '../customComponent/LoaderText'
import SponsorExhibitorsSkeleton from './SponsorExhibitorsSkeleton'

const LeftSidebar = lazy(() => import('./LeftSidebar'))
const RightSidebar = lazy(() => import('./rightSidebar'))
const StreamHome = lazy(() => import('./StreamHome'))
const StreamAgenda = lazy(() => import('./StreamAgenda'))
const StreamHomeSkeleton = lazy(() => import('./StreamHomeSkeleton'))
const StreamAgendaSkeleton = lazy(() => import('./StreamAgendaSkeleton'))
const StreamSpeakers = lazy(() => import('./StreamSpeakers'))
const StreamSponsors = lazy(() => import('./StreamSponsors'))
const StreamAboutUs = lazy(() => import('./StreamAboutUs'))
const StreamExhibitors = lazy(() => import('./StreamExhibitors'))
const StreamDocument = lazy(() => import('./StreamDocument'))
const StreamVideos = lazy(() => import('./StreamVideos'))
const StreamLeaderboard = lazy(() => import('./StreamLeaderboard'))
const StreamLive = lazy(() => import('./StreamLive'))
const NotFound = lazy(() => import('./NotFound'))
const StreamContactUs = lazy(() => import('./StreamContactUs'))
const StreamSocialMedia = lazy(() => import('./StreamSocialMedia'))
const StreamMap = lazy(() => import('./StreamMap'))
const StreamGallery = lazy(() => import('./StreamGallery'))

export const DrawerContext = createContext(null)

export default function Stream() {
  const token = sessionStorage.getItem('token')
  const auth = useSelector((state) => state.auth)
  if (!token && auth.isLoading) {
    return <StreamHomeSkeleton />
  }
  return (
    <VotingProvider>
      <ChatProvider>
        <DiscussionProvider>
          <StreamLayout />
        </DiscussionProvider>
      </ChatProvider>
    </VotingProvider>
  )
}

const StreamLayout = memo(() => {
  const { app_url } = useParams()
  const muiTheme = useTheme()
  const { data, refetch, isLoading, isFetching } = useAppData(app_url)
  const [aboutUsData, setAboutUsData] = useState({})
  const [speakersData, setSpeakersData] = useState({ items: [] })
  const [sessionData, setSessionData] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [theme, setTheme] = useState(muiTheme)
  const [expand, setExpand] = useState(false)
  const { refetch: refetchAttendees } = useFetchAttendee(data?.appId)

  let { url } = useRouteMatch()
  const socket = useSocket()
  const { authUser } = useAuth()
  const queryClient = useQueryClient()
  const [session, setSession] = useState(null)

  useEffect(() => {
    if (app_url) refetch()
  }, [app_url, refetch])

  useEffect(() => {
    if (!data?.appId) return
    refetchAttendees()
  }, [data?.appId, refetchAttendees])

  useEffect(() => {
    if (!data || !data?.events?.length) return

    data.events[0].tabs.forEach((tab) => {
      switch (tab.type) {
        case 'speakersData':
          setSpeakersData(tab)
          break
        case 'agenda':
          const agendaDetails = tab.agenda.map((agdDetail) => ({
            ...agdDetail,
            fromtime: getZoneDateTime(agdDetail.fromtime),
            totime: getZoneDateTime(agdDetail.totime),
          }))

          setSessionData(agendaDetails)
          break
        case 'aboutus':
          setAboutUsData(tab)
          break
        default:
          break
      }
    })
    let themeColor
    if (data.theme_color.charAt(0) === '#') {
      themeColor = data.theme_color
    } else themeColor = `#${data.theme_color}`

    const theme = createTheme({
      palette: {
        primary: {
          main: themeColor,
        },
        typography: {
          button: {
            textTransform: 'none',
          },
        },
      },
    })
    setTheme(theme)
  }, [data])

  useEffect(() => {
    if (!data?.appId || !authUser.id) return

    socket.auth = {
      userId: authUser.id,
      name: authUser.name,
      profile_pic: authUser.profile_pic,
      appId: session?.agendaId
        ? `${data.appId}-${session.agendaId}`
        : data.appId,
    }
    socket.connect()

    return () => {
      socket.disconnect()
    }
  }, [socket, authUser, data?.appId, session, queryClient])

  useChatEffect(socket, data?.appId)

  useQnAEffect(socket)

  const handleExpand = () => {
    setExpand(true)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  const value = useMemo(() => ({ handleOpenDrawer, handleExpand }), [])

  if (!data?.events?.length) {
    return <StreamHomeSkeleton />
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={closeDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '6.5rem' },
          }}
        >
          <LeftSidebar url={url} data={data} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '6.5rem' },
          }}
          open
        >
          <LeftSidebar url={url} data={data} />
        </Drawer>

        <Box
          className={styles.main}
          sx={{
            width: { sm: 'calc(100% - 11.8rem)' },
            marginLeft: { xs: 0, sm: '6.5rem' },
          }}
        >
          <DrawerContext.Provider value={value}>
            <StreamMainComponent
              data={data}
              isLoading={isLoading}
              isFetching={isFetching}
              sessionData={sessionData}
              speakersData={speakersData}
              aboutUsData={aboutUsData}
              session={session}
              setSession={setSession}
            />
          </DrawerContext.Provider>
        </Box>
        <RightSidebar expand={expand} setExpand={setExpand} />
      </Box>
    </ThemeProvider>
  )
})

const StreamMainComponent = memo(
  ({
    data,
    isLoading,
    isFetching,
    sessionData,
    speakersData,
    aboutUsData,
    session,
    setSession,
  }) => {
    const { path } = useRouteMatch()
    const { app_url } = useParams()

    const getDynRoute = (tab) => {
      switch (tab.type) {
        case 'pdf':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamDocument docData={tab} />
            </Suspense>
          )

        case 'agenda':
          return (
            <Suspense fallback={<StreamAgendaSkeleton />}>
              <StreamAgenda
                sessionData={sessionData}
                speakersData={speakersData}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </Suspense>
          )
        case 'video':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamVideos isLoading={isLoading} isFetching={isFetching} />
            </Suspense>
          )

        case 'sponsorsData':
          return (
            <Suspense fallback={<SponsorExhibitorsSkeleton />}>
              <StreamSponsors sponsorsData={tab} />
            </Suspense>
          )
        case 'exhibitorsData':
          return (
            <Suspense fallback={<SponsorExhibitorsSkeleton />}>
              <StreamExhibitors exhibitorsData={tab} />
            </Suspense>
          )
        case 'aboutus':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamAboutUs
                aboutUsData={tab}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </Suspense>
          )
        case 'speakersData':
          return (
            <Suspense fallback={<StreamSpeakerSkeleton />}>
              <StreamSpeakers
                sessionData={sessionData}
                speakersData={speakersData}
              />
            </Suspense>
          )
        case 'survey':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamSurvey
                surveyData={tab}
                isLoading={isLoading}
                isFetching={isFetching}
                data={data}
              />
            </Suspense>
          )
        case 'gamification':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamLeaderboard
                gamificationData={tab}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </Suspense>
          )
        case 'contactUs':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamContactUs contactData={tab} />
            </Suspense>
          )
        case 'socialmedia':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamSocialMedia socialData={tab} />
            </Suspense>
          )
        case 'map':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamMap mapData={tab} />
            </Suspense>
          )
        case 'gallery':
          return (
            <Suspense fallback={<LoaderText />}>
              <StreamGallery appData={data} galleryData={tab} />
            </Suspense>
          )
        default:
          return ''
      }
    }
    
    return (
      <Switch>
        <StreamProtectedRoute path={path} exact>
          <Suspense fallback={<StreamHomeSkeleton />}>
            <StreamHome
              data={data}
              isLoading={isLoading}
              isFetching={isFetching}
              sessionData={sessionData}
              speakersData={speakersData}
              aboutUsData={aboutUsData}
            />
          </Suspense>
        </StreamProtectedRoute>
        <StreamProtectedRoute path={`${path}/live`} exact>
          <Suspense fallback={<StreamHomeSkeleton />}>
            <StreamLive
              sessionData={sessionData}
              session={session}
              setSession={setSession}
            />
          </Suspense>
        </StreamProtectedRoute>
        {data.events[0].tabs
          .filter((tab) => !tab.caption.includes('My'))
          .map((tab, i) => (
            <StreamProtectedRoute
              key={i}
              path={`${path}/${tab.checkvalue}`}
              exact
            >
              {getDynRoute(tab)}
            </StreamProtectedRoute>
          ))}
        <Route path={`${path}/*`} exact>
          <Suspense fallback={<LoaderText />}>
            <NotFound homePath={`/${app_url}/stream`} />
          </Suspense>
        </Route>
      </Switch>
    )
  }
)
