import { useState, createContext, useEffect, useContext, useMemo } from 'react'
import { useStateValue } from '../components/StateProvider'

const AuthContext = createContext({})

const authInitialState = {
  isAuthenticated: false,
  id: '',
  name: '',
  profile_pic: '',
}
export const AuthProvider = (props) => {
  const [{ user }] = useStateValue()
  const [authUser, setAuthUser] = useState(authInitialState)
  const token = sessionStorage.getItem('token')
  const id = sessionStorage.getItem('userId')
  const name = sessionStorage.getItem('username')

  useEffect(() => {
    if (token && token !== 'undefined' && id && name) {
      setAuthUser({
        isAuthenticated: true,
        id,
        name,
        profile_pic: '',
      })
    } else if (user) {
      setAuthUser({
        isAuthenticated: true,
        id: user.userId,
        name: `${user.first_name} ${user.last_name}`,
        profile_pic: user.profile_pic,
      })
    } else {
      setAuthUser(authInitialState)
    }
  }, [user, token, id, name])

  const value = useMemo(() => ({ authUser, setAuthUser }), [authUser])
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
