import axios from 'axios'
import { checkAxios } from '../axiosConfig'

export const getAppDataJson = async (appUrl) => {
  const url = `https://webmobi.s3.amazonaws.com/nativeapps/${appUrl}/temp/appData.json`

  const { data } = await axios({
    url,
    method: 'GET',
  })
  return data
}

// for push notifications
export const pushMessage = async (notificationData) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/user/push_notifications',
    headers: { 'Content-Type': 'application/json' },
    data: { ...notificationData.payload },
  })
  // return { ...data, ...notificationData }
  return {
    response: data.response,
    responseString: data.responseString,
    payload: { ...notificationData.payload },
  }
}
export const getNotification = async (appid) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/user/get_push_notification?appid=${appid}`,
    headers: { 'Content-Type': 'application/json' },
  })
  return data.responseString
}
// for documents
export const documents = async (documentdata) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/pdf',
    headers: { 'Content-Type': 'application/json' },
    data: { ...documentdata.payload },
  })
  // return { ...data, ...documentdata }
  return {
    response: data.response,
    responseString: data.responseString,
    payload: { ...documentdata.payload },
  }
}
// cotact us
export const contactUs = async (contactUsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/contactus',
    headers: { 'Content-Type': 'application/json' },
    data: { ...contactUsPayload.payload },
  })
  return { ...data, ...contactUsPayload }
}
export const AboutUs = async (aboutUsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/aboutus',
    headers: { 'Content-Type': 'application/json' },
    data: { ...aboutUsPayload.payload },
  })
  return { ...data, ...aboutUsPayload }
}
// for delete item
export const deleteItem = async (documentPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: { ...documentPayload.payload, type_id: documentPayload.type_id },
  })
  // return { ...data, ...documentPayload }
  return {
    response: data.response,
    responseString: data.responseString,
    payload: documentPayload.payload,
  }
}

// for delete survey

export const deleteSurvey = async (deleteSurveyPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...deleteSurveyPayload.payload,
      type_id: deleteSurveyPayload.type_id,
    },
  })
  // return { ...data, ...deleteSurveyPayload }
  return {
    response: data.response,
    responseString: data.responseString,
    payload: deleteSurveyPayload.payload,
  }
}

// add survey
export const surveyQuestion = async (surveyPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/survey',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...surveyPayload,
      answer: JSON.stringify(surveyPayload.answer),
    },
  })

  return { ...data, ...surveyPayload }
}

// survey settings
export const surveyModSettings = async (settingsPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/survey_mod_settings',
    headers: { 'Content-Type': 'application/json' },
    data: { ...settingsPayload.payload },
  })
  return { ...data, ...settingsPayload }
}
// get leaderboard
export const getleaderboard = async (appid) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/event/get_gamification_rules?appid=${appid}&checkvalue=gamification1`,
    headers: { 'Content-Type': 'application/json' },
  })
  return { ...data }
}
// for stream leaderboard
export const getStreamLeaderboard = async (appId, userId) => {
  const { data } = await checkAxios({
    method: 'GET',
    url: `/event/leaderboard?appid=${appId}&userid=${userId}&checkvalue=gamification1`,
    headers: { 'Content-Type': 'application/json' },
  })
  return { ...data }
}

export const leaderboard = async (leaderboardPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/gamification_rules',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...leaderboardPayload,
      game_rules: JSON.stringify(leaderboardPayload.game_rules),
    },
  })
  console.log('leaderboard res data', data)

  return { ...data, ...leaderboardPayload }
  // return {
  //   response: data.response,
  //   responseString: data.responseString,
  //   payload: {leaderboardPayload.payload },
  // }
}
export const floormap = async (mapPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/maps',
    headers: { 'Content-Type': 'application/json' },
    data: { ...mapPayload.payload },
  })
  return { ...data, ...mapPayload }
}

export const deleteFloor = async (deleteFloorPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...deleteFloorPayload.payload,
      type_id: deleteFloorPayload.type_id,
    },
  })
  return { ...data, ...deleteFloorPayload }
}

export const floorLocation = async (floorLocationPayload) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/floor_location',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...floorLocationPayload.payload,
    },
  })
  return { ...data, ...floorLocationPayload }
}

// ***For Video Upload
export const uploadVideo = async (videoPayLoad) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/pdf',
    headers: { 'Content-Type': 'application/json' },
    data: { ...videoPayLoad },
  })
  return { ...data, ...videoPayLoad }
}

///////Delete Video
export const deleteVideo = async (deleteVideoPayLoad) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: { ...deleteVideoPayLoad, type_id: deleteVideoPayLoad.type_id },
  })
  return { ...data, ...deleteVideoPayLoad }
}

// ***For SocialMedia Upload
export const uploadSocialMedia = async (socialPayLoad) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/social_media',
    headers: { 'Content-Type': 'application/json' },
    data: { ...socialPayLoad },
  })
  return { ...data, ...socialPayLoad }
}

///delete social media details
export const deleteSocialMedia = async (deleteSocialPayLoad) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/delete_item',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...deleteSocialPayLoad,
      // type_id: JSON.stringify(deleteSocialPayLoad.type_id),
    },
  })
  return { ...data, ...deleteSocialPayLoad }
}

////Create Banner ads
export const bannerAds = async (addBannerPayLoad) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/banner_ad',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...addBannerPayLoad,
      banners_array: JSON.stringify(addBannerPayLoad.banners_array),
    },
  })
  return { ...data, ...addBannerPayLoad }
}

////Delete Banner ads
export const deleteBanner = async (deleteBannerPayLoad) => {
  const { data } = await checkAxios({
    method: 'POST',
    url: '/event/banner_ad',
    headers: { 'Content-Type': 'application/json' },
    data: {
      ...deleteBannerPayLoad,
      banners_array: JSON.stringify(deleteBannerPayLoad.banners_array),
    },
  })
  return { ...data, ...deleteBannerPayLoad }
}

// list all the images
export const listImages = async (appId, dashboardUserId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/event/list_image_gallery?appid=${appId}&userid=${dashboardUserId}`,
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

// get segment list for the pushNotification
export const getSegment = async (appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/user/get_segments?appid=${appId}`,
      // url: '/user/get_segments?appid=98df7a044a71a3cad6d8fef726c868bbc36b',
    })
    return data.responseString
  } catch (error) {
    console.error(error)
  }
}

// get user device List for the pushNotification
export const getUserDevice = async (userName, appId) => {
  try {
    const { data } = await checkAxios({
      method: 'GET',
      url: `/user/get_users?query_string=${userName}&appid=${appId}`,
    })
    return userName ? data.responseString : []
  } catch (error) {
    console.error(error)
  }
}
