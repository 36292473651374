import { createContext, useReducer, useMemo } from 'react'

export const VoteContext = createContext()

const initialState = {
  polls: [],
  quizzes: [],
  wordClouds: [],
  ratings: [],
  activeEvent: {
    type: '',
    event: [],
  },
}

export const VoteActions = {
  setPolls: 'SET_POLLS',
  setQuizzes: 'SET_QUIZZES',
  setWC: 'SET_WC',
  setRatings: 'SET_RATINGS',
  setActiveEvent: 'SET_ACTIVE_EVENT',
  createPoll: 'CREATE_POLL',
  createRating: 'CREATE_RATING',
  createWC: 'CREATE_WC',
  deletePoll: 'DELETE_POLL',
  deleteRating: 'DELETE_RATING',
  deleteWC: 'DELETE_WC',
  activatePoll: 'ACTIVE_POLL',
  activateRating: 'ACTIVE_RATING',
  activateWC: 'ACTIVE_WC',
  lockPoll: 'LOCK_POLL',
  lockRating: 'LOCK_RATING',
  lockWC: 'LOCK_WC',
  updatePoll: 'UPDATE_POLL',
  createQuiz: 'CREATE_QUIZ',
  deleteQuiz: 'DELETE_QUIZ',
  activateQuiz: 'ACTIVE_QUIZ',
  lockQuiz: 'LOCK_QUIZ',
  updateQuiz: 'UPDATE_QUIZ',
  updateActiveEvent: 'UPDATE_ACTIVE_EVENT',
  updateRating: 'UPDATE_RATING',
  updateWC: 'UPDATE_WC',
}

const reducer = (state, action) => {
  switch (action.type) {
    case VoteActions.setPolls:
      return { ...state, polls: action.payload }
    case VoteActions.setQuizzes:
      return { ...state, quizzes: action.payload }
    case VoteActions.setWC:
      return { ...state, wordClouds: action.payload }
    case VoteActions.setRatings:
      return { ...state, ratings: action.payload }
    case VoteActions.setActiveEvent:
      return {
        ...state,
        activeEvent: { type: action.payload.type, event: action.payload.event },
      }
    case VoteActions.lockPoll:
      return pollLockStatus(state, action)
    case VoteActions.lockQuiz:
      return quizLockStatus(state, action)
    case VoteActions.lockRating:
      return ratingLockStatus(state, action)
    case VoteActions.lockWC:
      return WCLockStatus(state, action)
    case VoteActions.updateActiveEvent:
      return updateActiveEvent(state, action)
    case VoteActions.activatePoll:
      return updateActivePoll(state, action)
    case VoteActions.activateQuiz:
      return updateActiveQuiz(state, action)
    case VoteActions.activateWC:
      return updateActiveWC(state, action)
    case VoteActions.activateRating:
      return updateActiveRating(state, action)
    case VoteActions.deletePoll:
      return deletePoll(state, action)
    case VoteActions.deleteQuiz:
      return deleteQuiz(state, action)
    case VoteActions.deleteWC:
      return deleteWC(state, action)
    case VoteActions.deleteRating:
      return deleteRating(state, action)
    case VoteActions.createPoll:
      return { ...state, polls: [...state.polls, action.payload] }
    case VoteActions.updatePoll:
      return updatePoll(state, action)
    case VoteActions.updateQuiz:
      return updateQuiz(state, action)
    case VoteActions.createQuiz:
      return { ...state, quizzes: [...state.quizzes, action.payload] }
    case VoteActions.createRating:
      return { ...state, ratings: [...state.ratings, action.payload] }
    case VoteActions.createWC:
      return { ...state, wordClouds: [...state.wordClouds, action.payload] }
    case VoteActions.updateRating:
      return updateRating(state, action)
    case VoteActions.updateWC:
      return updateWC(state, action)
    default:
      return state
  }
}

const updateRating = (state, action) => {
  const temp = [...state.ratings]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i]._id === action.payload._id) {
      temp[i] = action.payload
      break
    }
  }

  return {
    ...state,
    ratings: temp,
  }
}

const updatePoll = (state, action) => {
  const temp = [...state.polls]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].poll_id === action.payload.poll_id) {
      temp[i] = action.payload
      break
    }
  }

  return {
    ...state,
    polls: temp,
  }
}

const updateQuiz = (state, action) => {
  const temp = [...state.quizzes]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].quiz_id === action.payload.quiz_id) {
      temp[i] = action.payload
      break
    }
  }

  return {
    ...state,
    quizzes: temp,
  }
}

const updateWC = (state, action) => {
  const temp = [...state.wordClouds]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].id === action.payload.id) {
      temp[i] = action.payload
      break
    }
  }

  return {
    ...state,
    wordClouds: temp,
  }
}

const deletePoll = (state, action) => {
  //polls
  const temp = state.polls.filter((poll) => poll.poll_id !== action.payload)
  return {
    ...state,
    polls: temp,
  }
}

const deleteQuiz = (state, action) => {
  //polls
  const temp = state.quizzes.filter((quiz) => quiz.quiz_id !== action.payload)
  return {
    ...state,
    quizzes: temp,
  }
}

const deleteWC = (state, action) => {
  //polls
  console.log(typeof action.payload)
  const temp = state.wordClouds.filter(
    (poll) => poll.id.toString() !== action.payload
  )
  return {
    ...state,
    wordClouds: temp,
  }
}

const deleteRating = (state, action) => {
  //polls
  const temp = state.ratings.filter((rating) => rating._id !== action.payload)
  return {
    ...state,
    ratings: temp,
  }
}

const pollLockStatus = (state, action) => {
  //update polls
  const temp = [...state.polls]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].poll_id === action.payload.pollId) {
      temp[i].lock_status = action.payload.lockStatus
      break
    }
  }

  return { ...state, polls: temp }
}
const quizLockStatus = (state, action) => {
  //update polls
  const temp = [...state.quizzes]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].quiz_id === action.payload.quizId) {
      temp[i].lock_status = action.payload.status
      break
    }
  }

  return { ...state, quizzes: temp }
}
const ratingLockStatus = (state, action) => {
  //update polls
  const temp = [...state.ratings]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i]._id === action.payload._id) {
      temp[i].lock_status = action.payload.lock_status
      break
    }
  }

  return { ...state, ratings: temp }
}
const WCLockStatus = (state, action) => {
  //update polls
  const temp = [...state.wordClouds]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].id.toString() === action.payload.id) {
      temp[i].lock_status = action.payload.status
      break
    }
  }

  return { ...state, wordClouds: temp }
}

const updateActiveEvent = (state, action) => {
  if (
    action.payload.updateType === 'poll_lock' &&
    state.activeEvent?.event[0]?.poll_id === action.payload.data.pollId
  ) {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            ...state.activeEvent.event[0],
            lock_status: action.payload.data.lockStatus,
          },
        ],
      },
    }
  }
  if (
    action.payload.updateType === 'quiz_lock' &&
    state.activeEvent?.event[0]?.quiz_id === action.payload.data.quizId
  ) {
    // const quizData = state.quizzes.filter(
    //   (quiz) => quiz.quiz_id === action.payload.data.quizId
    // )

    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            // ...quizData[0],
            ...state.activeEvent.event[0],
            lock_status: action.payload.data.status,
          },
        ],
      },
    }
  }
  if (
    action.payload.updateType === 'rating_lock' &&
    state.activeEvent?.event[0]?._id === action.payload.data._id
  ) {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (
    action.payload.updateType === 'wc_lock' &&
    state?.activeEvent?.event[0]?.id?.toString() === action.payload.data.id
  ) {
    const WCData = state.wordClouds.filter(
      (wc) => wc.id.toString() === action.payload.data.id
    )
    console.log(WCData)
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            ...WCData[0],
            lock_status: action.payload.data.status,
          },
        ],
      },
    }
  }

  if (action.payload.updateType === 'poll_active') {
    const pollData = state.polls.filter(
      (poll) => poll.poll_id === action.payload.data.pollId
    )
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            ...pollData[0],
            active_status: action.payload.data.status,
          },
        ],
      },
    }
  }
  if (action.payload.updateType === 'quiz_active') {
    const quizData = state.quizzes.filter(
      (quiz) => quiz.quiz_id === action.payload.data.quizId
    )
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            ...quizData[0],
            active_status: action.payload.data.status,
          },
        ],
      },
    }
  }
  if (action.payload.updateType === 'wc_active') {
    const WCData = state.wordClouds.filter(
      (wc) => wc.id.toString() === action.payload.data.id
    )
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            ...WCData[0],
            active_status: action.payload.data.status,
          },
        ],
      },
    }
  }

  if (action.payload.updateType === 'rating_active') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'create_poll') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'create_quiz') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'create_rating') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'create_wc') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'update_rating') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'update_poll') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'update_quiz') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'update_wc') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [action.payload.data],
      },
    }
  }

  if (action.payload.updateType === 'quiz_question') {
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: action.payload.data,
      },
    }
  }

  if (action.payload.updateType === 'update_quiz_question') {
    // /changequizquesactivestatus
    return {
      ...state,
      activeEvent: {
        type: action.payload.type,
        event: [
          {
            ...state.activeEvent.event[0],
            question_array: state.activeEvent.event[0].question_array.map(
              (question) =>
                question.question_id === action.payload.data.questionId
                  ? {
                      ...question,
                      isActive: action.payload.data.status,
                      activated_at: new Date().toISOString(),
                    }
                  : question
            ),
          },
        ],
      },
    }
  }

  if (
    action.payload.updateType === 'delete_poll' &&
    state?.activeEvent?.event[0]?.poll_id === action.payload.data
  ) {
    return {
      ...state,
      activeEvent: {
        type: '',
        event: [],
      },
    }
  } else if (
    action.payload.updateType === 'delete_rating' &&
    state?.activeEvent?.event[0]?._id === action.payload.data
  ) {
    return {
      ...state,
      activeEvent: {
        type: '',
        event: [],
      },
    }
  } else if (
    action.payload.updateType === 'delete_wc' &&
    state?.activeEvent?.event[0]?.id.toString() === action.payload.data
  ) {
    return {
      ...state,
      activeEvent: {
        type: '',
        event: [],
      },
    }
  } else if (
    action.payload.updateType === 'delete_quiz' &&
    state?.activeEvent?.event[0]?.quiz_id === action.payload.data
  ) {
    return {
      ...state,
      activeEvent: {
        type: '',
        event: [],
      },
    }
  } else {
    return state
  }
}

const updateActivePoll = (state, action) => {
  const temp = [...state.polls]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].poll_id === action.payload.pollId) {
      temp[i].active_status = action.payload.status
      break
    }
  }

  return { ...state, polls: temp }
}

const updateActiveQuiz = (state, action) => {
  const temp = [...state.quizzes]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].quiz_id === action.payload.quizId) {
      temp[i].active_status = action.payload.status
      break
    }
  }

  return { ...state, quizzes: temp }
}

const updateActiveWC = (state, action) => {
  const temp = [...state.wordClouds]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i].id.toString() === action.payload.id) {
      temp[i].active_status = action.payload.status
      break
    }
  }

  return { ...state, wordClouds: temp }
}

const updateActiveRating = (state, action) => {
  const temp = [...state.ratings]

  for (let i = 0; i < temp.length; i++) {
    if (temp[i]._id === action.payload._id) {
      temp[i].active_status = action.payload.active_status
      break
    }
  }

  return { ...state, ratings: temp }
}

export const VotingProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = useMemo(() => [state, dispatch], [state])
  return (
    <VoteContext.Provider value={value}>{props.children}</VoteContext.Provider>
  )
}
