import { healthAxios } from '../axiosConfig'

export const subscribe = (email, first_name, last_name, plan_id, appId) => {
  return healthAxios({
    url: '/new-subscription',
    method: 'POST',
    data: {
      email,
      first_name,
      last_name,
      plan_id,
      appId,
    },
  })
}
