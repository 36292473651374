import React, { useState, useContext } from 'react'
import { healthAxios } from '../axiosConfig'
import { useHistory, Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { DomainContext } from '../context/domain.context'
// ------mui components imports:start---------
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
// ------mui imports:end---------

// --------- image imports:start ---------
import Unlock from './images/unlock.svg'
// --------- image imports:end ---------

//--------styles import:start--------
import '../styles/newLogin.css'
import '../styles/resetPassword.css'
import Loader from './customComponent/Loader'
import { openSnackbar } from '../actions'
//--------styles import:end--------

// --- login 1:start(using email and pass) ---
const LoginOne = () => {
  // --- states ---
  const [user, setUser] = useState({
    newPassword: '',
    confirmPassword: '',
    passkey: '',
    action: 'generate',
    deviceType: 'web',
  })
  const [openSpinner, setOpenSpinner] = React.useState(false)
  const reduxDispatch = useDispatch()
  const { search } = useLocation()
  const { email, id } = queryString.parse(search)
  const history = useHistory()
  console.log('query param', email, id)

  //--- form submit handler----
  const handelFormSubmit = async (e) => {
    e.preventDefault()
    if (user.newPassword !== user.confirmPassword) {
      return reduxDispatch(openSnackbar('error', 'Password does not match!'))
    }
    setOpenSpinner(true)
    try {
      const axiosRes = await healthAxios({
        url: '/reset', //todo:remove
        method: 'POST',
        data: {
          password: user.confirmPassword,
        },
        params: {
          email,
          id,
        },
      })
      if (axiosRes.status === 200) {
        reduxDispatch(
          openSnackbar('success', axiosRes?.data?.responseString ?? '')
        )
        setTimeout(() => {
          history.replace('/')
        }, 3000)
      }
    } catch (error) {
      let text = ''
      if (error?.response?.data?.data?.length) {
        text = error?.response?.data?.data[0] ?? ''
      } else {
        text =
          error?.response?.data?.responseString ??
          'incorrect username or password'
      }
      reduxDispatch(openSnackbar('success', text ?? ''))
    }
    setOpenSpinner(false)
  }

  return (
    <>
      <Loader open={openSpinner} />
      <form
        className="formGroup__form"
        autoComplete="off"
        onSubmit={handelFormSubmit}
      >
        {/* --- email input:start --- */}
        <div className="inputGroup">
          <label htmlFor="password">New Password</label>
          <TextField
            className="inputGroup__input"
            id="password"
            variant="filled"
            name="password"
            type="password"
            placeholder="Abc123!@#0"
            value={user.newPassword}
            onChange={(e) => {
              setUser((prev) => {
                return { ...prev, newPassword: e.target.value }
              })
            }}
            required={true}
          />
        </div>
        {/* --- email input:end --- */}
        <div className="inputGroup">
          <label htmlFor="confirm password">Confirm Password</label>
          <TextField
            className="inputGroup__input"
            id="confirmPassword"
            variant="filled"
            name="password"
            type="password"
            placeholder=". . . . . . . . . . . . . . . . . . . ."
            value={user.confirmPassword}
            onChange={(e) => {
              setUser((prev) => {
                return { ...prev, confirmPassword: e.target.value }
              })
            }}
            required={true}
          />
        </div>
        <div className="btnGroup">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            className="btnGroup__btn"
          >
            Reset Password
          </Button>
        </div>
        <p className="resetPassword loginMsg">
          Want to Log in to your account?{' '}
          <Link to="/" className="resetPassword loginRoute">
            Log in here!
          </Link>
        </p>
      </form>
    </>
  )
}
// --- login 1:end(using email and pass) ---

// -------- NewLogin component:start --------
function ResetPassword() {
  // --------- state:start ---------
  const { brandName } = useContext(DomainContext)
  // --------- state:end ---------
  // --------- jsx:start ---------
  return (
    <section className="newLogin">
      {/* ------ header:start ------  */}
      <header className="header">
        <ul className="header__list">
          <li className="header__item brand">
            {brandName ? brandName : 'Ngage.ai'}
          </li>
          <li className="header__item btnGroup hide--sm show--lg">
            <Button
              variant="outlined"
              color="primary"
              className="btnGroup__btn"
            >
              Join an event ? Click here !
            </Button>
          </li>
        </ul>
      </header>
      {/* ------ header:end ------  */}
      {/* ------- landing:start ------- */}
      <div className="landing">
        {/* ------ landing img:start ------- */}
        <div className="landing__imgWrapper">
          <img src={Unlock} alt="unlock account" />
        </div>
        {/* ------ landing img:end ------- */}

        {/* ----- formGroup:start -----  */}
        <div className="formGroup">
          <LoginOne />
        </div>
        {/* ----- formGroup:end -----  */}
      </div>
      {/* ------- landing:end ------- */}
      {/* --- sign up message:start --- */}
      <div className="joinEvent--sm">
        <Button variant="outlined" color="primary" className="btnGroup__btn">
          Join an event ? Click here !
        </Button>
      </div>
      <div className="signupDiv">
        <div className="signupDiv__circle">
          <div id="main__circle">
            <div className="signupDiv__msg">
              <h2>Reset</h2>
              <br />
              <h2>your password</h2>
              <p className="loginOpt">
                or <Link to="/register">Create a new account</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* --- sign up message:end --- */}
    </section>
  )
  // --------- jsx:end ---------
}
// -------- NewLogin component:end --------

export default ResetPassword
