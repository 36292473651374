import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const Loader = ({ open = false }) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
        color: '#fff',
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
export default Loader
