import React, { useState, useEffect, useContext } from 'react'
import ReactWordcloud from 'react-wordcloud'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import LockIcon from '@mui/icons-material/Lock'
import { healthAxios } from '../../axiosConfig'
import { useStateValue } from '../StateProvider'
import '../../styles/wordCloudVote.css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { VoteActions, VoteContext } from '../../context/voting.context'
import { VisitorContext } from '../../context/visitor.context'
import Loader from '../customComponent/Loader'

const VotingWC = ({ wordCloud, socket, app_id }) => {
  const [, dispatch] = useContext(VoteContext)
  const [{ user }] = useStateValue()
  const [words, setWords] = useState([])
  const [selected, setSelected] = useState(null)
  const [openAlert, setOpenAlert] = useState(false)
  const [status, setStatus] = useState({
    isLoading: false,
    msg: null,
    type: 'success',
  })
  const [editRes, setEditRes] = useState(true)
  const [addOption, setAddOption] = useState(false)
  const [option, setOption] = useState('')
  const { visitorId } = useContext(VisitorContext)
  const [maxWordLenth, setMaxWordLength] = useState(20)
  const matchesXS = useMediaQuery('(min-width:600px)')

  useEffect(() => {
    if (status.msg) {
      setOpenAlert(true)
    }
  }, [status])

  useEffect(() => {
    if (wordCloud?.options?.length > 0) {
      let arr = []
      for (let i of wordCloud?.options) {
        arr.push({ text: i.word, value: i.clicks })
      }
      setWords(arr)
    }
  }, [wordCloud])

  const handleAlertClose = () => {
    setOpenAlert(false)
  }

  const handleSelect = (id) => {
    if (addOption) {
      setOption('')
      setAddOption(false)
    }
    setSelected(id)
  }
  const handleAddRes = () => {
    setSelected(null)
    setAddOption(true)
  }
  const handleSubmit = async () => {
    let isOption = false

    if (wordCloud?.options)
      for (const wc_option of wordCloud.options) {
        if (wc_option.word.toLowerCase() === option.trim().toLowerCase()) {
          isOption = true
          break
        }
      }

    if (isOption) {
      return setStatus((status) => ({
        ...status,
        msg: 'This option is already voted, please select this option',
        type: 'info',
      }))
    }
    if (selected === null && option === '') {
      return setStatus((status) => ({
        ...status,
        msg: 'Choose at least one option or create a new one',
        type: 'error',
      }))
    }
    setStatus((status) => ({ ...status, msg: '', isLoading: true }))
    let body
    if (selected !== null) {
      body = {
        id: wordCloud.id,
        app_id,
        option_id: selected,
        attendee_id: user ? user?.userId : visitorId,
      }
    } else if (option.trim() !== '') {
      body = {
        id: wordCloud.id,
        app_id,
        option: option.trim(),
        attendee_id: user ? user?.userId : visitorId,
      }
    }
    try {
      const res = await healthAxios.post('/votewordcloud', body)
      if (res.status === 201) {
        // updateActiveEvent({ type: 'wc', data: res.data?.wordCloud })
        setOption('')
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'wc',
            data: res.data?.wordCloud,
            updateType: 'update_wc',
          },
        })
        socket.emit('UPDATE_WORD_CLOUD', {
          roomId: res?.data?.wordCloud?.app_id,
          wordCloud: res?.data?.wordCloud,
        })
        socket.emit('CHANGE_ACTIVE_EVENT', {
          roomId: res?.data?.wordCloud?.app_id,
          type: 'WC',
          event: [res?.data?.wordCloud],
        })
        setStatus((status) => ({
          ...status,
          msg: 'Successfully voted!',
          isLoading: false,
          type: 'success',
        }))
        setEditRes(false)
      } else {
        setStatus((status) => ({
          ...status,
          isLoading: false,
          msg: 'Unable to vote',
          type: 'error',
        }))
      }
    } catch (error) {
      setStatus((status) => ({
        ...status,
        isLoading: false,
        msg:
          error?.response?.data?.responseString ??
          error?.response?.data?.message ??
          error?.message,
        type: 'error',
      }))
    }
  }

  const handleAddWord = (e) => {
    const { value } = e.target
    setMaxWordLength(20 - value.length)

    setOption((option) => (value.length < 20 ? value : option))
  }

  const handleEditRes = () => {
    setStatus((status) => ({ ...status, msg: '' }))
    setEditRes(true)
  }
  return (
    <>
      <Loader open={status.isLoading} />

      {status.msg && (
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={status.type}
            variant="filled"
          >
            {status.msg}
          </Alert>
        </Snackbar>
      )}
      {wordCloud ? (
        <div className="pollVote wordCloudVote">
          <p>Live Poll</p>
          <div className="wordCloudVote-card">
            <h3 className="card-header">{wordCloud.question}</h3>
            {editRes ? (
              <div className="wordCloudOption-container">
                {wordCloud?.options &&
                  wordCloud.options.map((option, index) => (
                    <WordCloudOption
                      key={index}
                      option={option}
                      selected={selected}
                      handleSelect={handleSelect}
                      index={index}
                    />
                  ))}

                {addOption ? (
                  <div className="WCAddOption-input">
                    <TextField
                      variant="outlined"
                      value={option}
                      onChange={handleAddWord}
                      placeholder="Enter word"
                      size="small"
                      fullWidth
                    />
                  </div>
                ) : (
                  <li
                    className="wordCloudVote-option"
                    onClick={() => handleAddRes()}
                  >
                    Add another word ( optional )
                  </li>
                )}
                <small
                  style={{
                    display: 'block',
                    textAlign: 'right',
                    padding: '0.25rem 0',
                    color: maxWordLenth < 6 ? 'red' : 'inherit',
                    fontWeight: maxWordLenth < 6 ? 'bold' : 'inherit',
                  }}
                >
                  Characters left {maxWordLenth}
                </small>
                <div className="wordCloudVote-btn-container">
                  {wordCloud?.active_status === 'false' ||
                  wordCloud?.active_status === false ||
                  wordCloud?.lock_status === 'true' ||
                  wordCloud?.lock_status === true ? (
                    <p>
                      <small>
                        <LockIcon fontSize="small" /> &nbsp; Voting closed
                      </small>
                    </p>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="wordCloudVote-submit-btn"
                        disableElevation
                        onClick={handleSubmit}
                      >
                        Send
                      </Button>
                      <p>
                        <small>
                          Voting as {user ? user.username : 'Anonymous'}
                        </small>
                      </p>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Divider />
                <div className="wordCloudVote-wc">
                  <div className="vote-reactWordCloud">
                    <ReactWordcloud
                      words={words}
                      options={{
                        fontSizes: matchesXS ? [12, 24] : [15, 38],
                        rotationAngles: [0, 0],
                        rotations: 0,
                        padding: 10,
                        fontWeight: 'bold',
                      }}
                    />
                  </div>
                  {wordCloud?.active_status === 'false' ||
                  wordCloud?.active_status === false ||
                  wordCloud?.lock_status === 'true' ||
                  wordCloud?.lock_status === true ? (
                    <p>
                      <small>
                        <LockIcon fontSize="small" /> &nbsp; Voting closed
                      </small>
                    </p>
                  ) : (
                    <Button
                      variant="contained"
                      color="inherit"
                      className="wordCloudVote-edit-res-btn"
                      onClick={handleEditRes}
                    >
                      Edit Response
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
          {/* privacy policy */}
          <a
            href="https://ngage.ai/privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{
              color: '#808080',
              fontWeight: '500',
              fontSize: '0.875rem',
              textAlign: 'center',
              display: 'block',
              marginTop: '2rem',
            }}
          >
            Acceptable Use Privacy Policy
          </a>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const WordCloudOption = React.memo(
  ({ option, selected, handleSelect, index }) => {
    return (
      <>
        {option?.word?.length > 0 && (
          <li
            key={Math.random() + index}
            className={
              option.id === selected
                ? 'wordCloudVote-option selected'
                : 'wordCloudVote-option'
            }
            onClick={() => handleSelect(option.id)}
          >
            {option.word}
          </li>
        )}
      </>
    )
  }
)

export default VotingWC
