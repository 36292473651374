import { useMutation, useQuery } from 'react-query'
import { appFeedBack, appFeedBackCheck } from '../apis/stramApi'

export const useAppFeedBack = () => {
  return useMutation(appFeedBack)
}

export const useFeedBackCheck = (
  appId,
  username,
  email,
  date,
  userid,
  default_id
) => {
  return useQuery(
    'feedBackCheck',
    () => appFeedBackCheck(appId, username, email, date, userid, default_id),
    {
      refetchOnWindowFocus: false,
    }
  )
}
