import { useEffect } from 'react'
import { useQnA } from './qna.hooks'

const useQnAEffect = (socket) => {
  const { create, update, _delete } = useQnA()

  useEffect(() => {
    // for discussion
    socket.on('QandA_qn_created', (data) => {
      create(data.data)
    })
    socket.on('moderate', (data) => {
      update(data)
    })
    socket.on('QandA_update', (data) => {
      update(data)
    })
    socket.on('QandA_delete', (data) => {
      _delete(data)
    })
  }, [socket, create, update, _delete])
}
export default useQnAEffect
