import React, { useState, useContext, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share'
import { Container, Skeleton } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { openSnackbar, updatePpt } from '../actions'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import { makeStyles } from '@mui/styles'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import PresentIcon from './customComponent/icons/PresentIcon'
import Tooltip from '@mui/material/Tooltip'
import '../styles/eventdashboard.css'
import { useSocket } from '../context/socket.context'
import { healthAxios } from '../axiosConfig'
import Loader from './customComponent/Loader'
import { useStateValue } from './StateProvider'
import { createContentSlide } from '../utils'
import UnsavedChangesAlert from './customComponent/UnsavedChangesAlert'
import { SlideRefContext } from '../context/slideRef.context'
import { DomainContext } from '../context/domain.context'
import useCopy from '../hooks/useCopy'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function EventDashboard({ currTab }) {
  const { name } = useParams()
  const { copy } = useCopy()
  const history = useHistory()
  const myActiveEvents = useSelector((state) => state.activeEvent)
  const location = useLocation()
  const { event } = useSelector((state) => state.events)
  const classes = useStyles()
  const [openMobile, setOpenMobile] = useState(false)
  const [openLaptop, setOpenLaptop] = useState(false)
  const mySocket = useSocket()
  const reduxDispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { slides } = useSelector((state) => state.ppt)
  const [openSaveAlert, setOpenSaveAlert] = useState(false)
  const [{ user }] = useStateValue()
  const socket = useSocket()
  const { activePpt } = useSelector((state) => state.presentations)
  const { isActive: isLeaderBoardActive } = useSelector(
    (state) => state.leaderboard
  )
  const slideRef = useContext(SlideRefContext)
  const { clientUrl } = useContext(DomainContext)

  useEffect(() => {
    const escFunction = async () => {
      const presentation_id = activePpt?.id
      if (!presentation_id) return

      if (!document.fullscreenElement) {
        try {
          // // deactive others if presentation status is true
          await changeActiveStatusPresentation(presentation_id, false)
        } catch (error) {
          reduxDispatch(
            openSnackbar(
              'error',
              error?.response?.data?.message ?? error.message
            )
          )
        }
      }
    }
    const handleFullScreenError = (e) => {
      console.log('Error', e)
    }
    document.addEventListener('fullscreenchange', escFunction)
    document.addEventListener('fullscreenerror', handleFullScreenError)

    return () => {
      document.removeEventListener('fullscreenchange', escFunction)
      document.removeEventListener('fullscreenerror', handleFullScreenError)
    }
  }, [activePpt?.id])

  //share link handler
  const handleShare = () => {
    if (currTab === 3) {
      return history.push(`/join-event/${event?.appUrl}`)
    }

    if (currTab === 4) {
      window.sessionStorage.setItem('location', `/join-event/${event?.appUrl}`)
      copy(`${clientUrl}/join-event/${event?.appUrl}?slide=true`)
      return
    }

    if (!myActiveEvents.type) {
      return reduxDispatch(openSnackbar('error', '⚠ No Active Events!'))
    }

    window.sessionStorage.setItem('location', `/join-event/${event?.appUrl}`)
    copy(`${clientUrl}/join-event/${event?.appUrl}`)
  }
  // handle presentation
  const handlePresentation = () => {
    let activeEventType = myActiveEvents.type
    if (isLeaderBoardActive && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activequizzes`, {
        from: location.pathname,
      })
    }

    if (activeEventType === 'poll' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activepolls`, {
        from: location.pathname,
      })
    }
    if (activeEventType === 'WC' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activewordcloud`, {
        from: location.pathname,
      })
    }
    if (activeEventType === 'rating' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activerating`, {
        from: location.pathname,
      })
    }
    if (activeEventType === 'rating' && currTab === 3) {
      return history.push(`/events/${name}/activerating`, {
        from: 'qna',
      })
    }
    if (activeEventType === 'poll' && currTab === 3) {
      return history.push(`/events/${name}/activepolls`, {
        from: 'qna',
      })
    }
    if (activeEventType === 'WC' && currTab === 3) {
      return history.push(`/events/${name}/activewordcloud`, {
        from: 'qna',
      })
    }
    if (activeEventType === 'quiz' && currTab !== 3 && currTab !== 4) {
      return history.push(`/events/${name}/activequizzes`, {
        from: location.pathname,
      })
    }

    if (activeEventType === 'quiz' && currTab === 3) {
      return history.push(`/events/${name}/activequizzes`, {
        from: 'qna',
      })
    }

    //currTab = 4
    if (currTab === 4) {
      // check if any slide is not saved //
      const unsavedChanges = slides.filter((slide) => !slide.update)
      if (unsavedChanges.length) {
        return setOpenSaveAlert(true)
      }

      //make full screen
      return handleFullScreen()
    }

    if (!activeEventType) {
      let appUrl
      if (name) {
        appUrl = name
      } else {
        appUrl = event.appUrl
      }
      return history.push(`/events/${appUrl}/join-webmobi`, {
        from: location.pathname,
      })
    }
  }

  //change active status of presentaiton
  const changeActiveStatusPresentation = async (id, status) => {
    try {
      setLoading(true)
      const axiosRes = await healthAxios({
        url: '/updatepresentation',
        method: 'POST',
        data: {
          id,
          active_status: status,
        },
        withCredentials: true,
      })
      if (axiosRes.status === 201) {
        reduxDispatch(updatePpt({ id, status }))
        mySocket.emit('CHANGE_PRESENTATION_ACTIVE_STATUS', {
          roomId: event.appId,
          payload: { pid: id, status },
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const handleAlertCancel = () => {
    return setOpenSaveAlert(false)
  }

  const handleAlertYes = async () => {
    setLoading(true) // to open loader
    const unsavedSlides = slides.filter((slide) => !slide.update)
    let presentationData = activePpt
    if (unsavedSlides.length) {
      for (const slide of unsavedSlides) {
        await createContentSlide(
          name, // appUrl
          event.appId,
          user.userId,
          presentationData.id,
          slide,
          reduxDispatch,
          socket
        )
      }
    }
    setLoading(false) // to close loader
    setOpenSaveAlert(false)
  }

  //handle full screen
  const handleFullScreen = async () => {
    try {
      const elem = slideRef?.current
      const presentation_id = activePpt?.id
      if (!elem || !presentation_id) return
      //activate presentation
      setLoading(true)

      if (!document.fullscreenElement) {
        elem.requestFullscreen()
        await changeActiveStatusPresentation(presentation_id, true)
      } else {
        document.exitFullscreen()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      reduxDispatch(
        openSnackbar('error', error?.response?.data?.message ?? error?.message)
      )
    }
  }

  const handleCloseMobile = () => {
    setOpenMobile(false)
  }

  const handleCloseLaptop = () => {
    setOpenLaptop(false)
  }
  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className="event-dashboard">
      <Loader open={loading} />
      <UnsavedChangesAlert
        open={openSaveAlert}
        handleCancel={handleAlertCancel}
        handleYes={handleAlertYes}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openMobile}
        onClose={handleCloseMobile}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openMobile}>
          <div>
            <div className="smartphone">
              <div className="smartphone__content">
                <iframe
                  src={`${clientUrl}/join-event/${name}`}
                  title="check mobile view"
                  style={{ width: '100%', border: 'none', height: '100%' }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLaptop}
        onClose={handleCloseLaptop}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLaptop}>
          <div>
            <div className="laptop">
              <div className="content">
                <iframe
                  src={`${clientUrl}/join-event/${name}`}
                  title="check laptop view"
                  style={{ width: '100%', border: 'none', height: '100%' }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <Container sx={{ display: 'flex' }}>
        <div className="dashboard-left">
          <IconButton className="back-btn" size="small" onClick={handleBack}>
            <ArrowBackIosOutlinedIcon fontSize="small" />
          </IconButton>
          {!Object.hasOwn(event, 'appName') ? (
            <div>
              <Skeleton
                variant="text"
                sx={{ fontSize: '1.125rem' }}
                width={200}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '0.875rem' }}
                width={150}
              />
            </div>
          ) : (
            <div className="event-dashboard-name">
              <div>
                <h3>
                  <strong className="app-name">{event?.appName}</strong>
                </h3>
                {event?.startdate && (
                  <p className="gray-p">
                    {new Date(event?.startdate).toLocaleDateString('en-US', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })}{' '}
                    -{' '}
                    {new Date(event?.enddate).toLocaleDateString('en-US', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </p>
                )}
              </div>
              {/* <p className="test">
              <span
                className="event-status-text"
                style={{ backgroundColor: status?.color }}
              >
                {status?.text}
              </span>
            </p> */}
            </div>
          )}
        </div>
        <div className="dashboard-right">
          {(currTab === 2 || currTab === 3 || currTab === 4) && (
            <Tooltip title={currTab === 3 ? 'Join-Event' : 'Copy'}>
              <Button
                variant="contained"
                //color="inherit"
                startIcon={<ShareIcon fontSize="small" />}
                disableElevation
                onClick={handleShare}
                sx={{
                  backgroundColor: 'gray',
                  '&:hover': {
                    backgroundColor: '#555',
                  },
                }}
              >
                Share
              </Button>
            </Tooltip>
          )}
          {currTab === 2 && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PresentIcon fontSize="small" />}
              disableElevation
              onClick={handlePresentation}
            >
              Present
            </Button>
          )}
          {currTab === 3 && (
            <div className="preview-btn-container">
              <span className="preview-txt">Preview on:</span>
              <IconButton
                aria-label=""
                size="small"
                className="preview-icon"
                onClick={() => setOpenMobile(true)}
              >
                <PhoneIphoneIcon
                  fontSize="small"
                  className="eventDashboard_icn"
                />
              </IconButton>
              <IconButton
                aria-label=""
                size="small"
                className="preview-icon"
                onClick={() => setOpenLaptop(true)}
              >
                <DesktopWindowsIcon
                  fontSize="small"
                  className="eventDashboard_icn"
                />
              </IconButton>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default EventDashboard
