import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Slide from '@mui/material/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const UnsavedChangesAlert = ({ open, handleCancel, handleYes }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      aria-labelledby="unsaved-changes-alert"
      fullWidth
    >
      <DialogTitle id="unsaved-changes-alert">Unsaved Changes?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to save your unsaved changes.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleYes}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
UnsavedChangesAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleYes: PropTypes.func.isRequired,
}

export default UnsavedChangesAlert
