import { useEffect } from 'react'
import { useChat } from '../context/Chat.context'

const useMessageSeen = (chats, userId, socket) => {
  const { dispatch } = useChat()

  useEffect(() => {
    if (!chats.length) return

    const updateIsSeen = (id, userId, observer, element) => {
      dispatch({ type: 'seen', payload: { id, userId } })
      socket.emit('update seen', {
        id,
        to: { id: userId },
      })
      // unobserve the element
      observer.unobserve(element)
    }
    const option = {
      root: null,
      threshold: 0.1,
    }

    chats.forEach((chat) => {
      if (!chat.fromSelf && !chat.is_seen) {
        const element = document.getElementById(chat.id)

        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            // update is_seen of chat message
            updateIsSeen(chat.id, userId, observer, element)
          }
        }, option)

        observer.observe(element)
      }
    })
  }, [chats, userId, dispatch, socket])
}

export default useMessageSeen
