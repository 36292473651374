import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  deleteSession,
  editCreateAgenda,
  xlsxUpload,
} from '../Apis/eventSettingApis'
import {
  getAppDataJson,
  deleteSponsor,
  editCreateSponsor,
  editCreateSpeaker,
  deleteSpeaker,
  basicDetails,
  deleteExhibitor,
  editcreateExhibitor,
  homeSettings,
  virtualHybrid,
  speakerXlsxUpload,
  sponsorXlsxUpload,
  exhibitorXlsxUpload,
  modSetting,
  addFeature,
  deleteFeature,
  exportToExcel,
  zipImageUpload,
  uploadImg,
} from '../Apis/eventContentApis'
import {
  pushMessage,
  getNotification,
  documents,
  contactUs,
  AboutUs,
  deleteItem,
  deleteSurvey,
  surveyQuestion,
  surveyModSettings,
  getleaderboard,
  leaderboard,
  floormap,
  deleteFloor,
  floorLocation,
  uploadVideo,
  deleteVideo,
  uploadSocialMedia,
  deleteSocialMedia,
  bannerAds,
  deleteBanner,
  getStreamLeaderboard,
  getSegment,
  getUserDevice,
} from '../Apis/eventNetworkApis'
import {
  getFormQues,
  addHeading,
  createEditFormQuestions,
  deleteQuestion,
  getCupons,
  createCupon,
  deleteCupon,
  getReportData,
} from '../Apis/ticketApis'
import { fetchJsonVersion, publishApp } from '../Apis/publishApi'
import {
  fetchAttendee,
  RegisterAttendee,
  updateAttendeeUser,
  deleteAttendeeUser,
  verifyAttendeeUser,
  attendeeXlsxUpload,
  generatePdf,
  checkinUser,
  userAdminList,
  adminSetting,
  deleteAdmin,
  emailConfig,
  getTemplates,
  updateTemplate,
  updateCampaign,
  createCampaign,
  sendCampaign,
  deleteCampaign,
  outBoundEmail,
  manageSchedule,
} from '../Apis/attendeeApis'
import {
  AndroidNote,
  fetchActivityStats,
  fetchAgendaRating,
  fetchRegVsTicketed,
  fetchSpeakerRating,
  fetchSurveyFeedback,
  loggedInReg,
  NoOfUsers,
} from '../Apis/reportsStatisticsApi'
import { multieventLogin } from '../Apis/streamApi'

export const useRegisterAppData = (appUrl) => {
  const queryClient = useQueryClient()
  const appData = queryClient.getQueryData('appData')
  return useQuery(
    'appData',
    () => getAppDataJson(appUrl, appData?.version ?? 0),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useAppData = (appUrl) => {
  const queryClient = useQueryClient()
  const appData = queryClient.getQueryData('appData')
  return useQuery(
    'appData',
    () => getAppDataJson(appUrl, appData?.version ?? 0),
    {
      refetchOnWindowFocus: true,
    }
  )
}
// to delete sponsor
export const useDeleteSponsor = () => {
  return useMutation(deleteSponsor)
}

// edit create sponsor
export const useEditSponsor = () => {
  return useMutation(editCreateSponsor)
}

// to delete speaker
export const useDeleteSpeaker = () => {
  return useMutation(deleteSpeaker)
}

// to edit speaker
export const useEditSpeaker = () => {
  return useMutation(editCreateSpeaker)
}

// to add documents
export const useAddDocuments = () => {
  return useMutation(documents)
}

// delte doc
export const useDeleteDocument = () => {
  return useMutation(deleteItem)
}

// delete survey
export const useDeleteSurvey = () => {
  return useMutation(deleteSurvey)
}

// edit survey

export const useContactUs = () => {
  const queryClient = useQueryClient()
  return useMutation(contactUs, {
    onSuccess: (response) => {
      if (response.response) {
        queryClient.setQueriesData('appData', (oldData) => {
          return {
            ...oldData,
            events: [
              {
                ...oldData.events[0],
                tabs: updateContactUs(oldData.events[0].tabs, response),
              },
              ...oldData.events,
            ],
          }
        })
      }
    },
  })
}
//update after adding contactus
const updateContactUs = (tabs, { response, payload, type_id }) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === payload.checkvalue) {
      // return { ...tabs, ...response.payload }
      const tabItem = {
        ...tab,
        ...payload,
      }
      // console.log('contactus', tabItem)
      return tabItem
    } else return tab
  })
}

export const useAboutUs = () => {
  const queryClient = useQueryClient()
  return useMutation(AboutUs, {
    onSuccess: (response) => {
      if (response.response) {
        queryClient.setQueriesData('appData', (oldData) => {
          return {
            ...oldData,
            events: [
              {
                ...oldData.events[0],
                tabs: updateAboutUs(oldData.events[0].tabs, response),
              },
              ...oldData.events,
            ],
          }
        })
      }
    },
  })
}
//update after adding contactus
const updateAboutUs = (tabs, { response, payload, type_id }) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === payload.checkvalue) {
      // return { ...tabs, ...response.payload }
      const tabItem = {
        ...tab,
        ...payload,
        // items: tab.items.push({ ...payload }),
      }
      // console.log('AboutUs', tabItem)
      return tabItem
    } else return tab
  })
}
// to add regstration question
export const useAddSurveyQuestion = () => {
  return useMutation(surveyQuestion)
}

// for survey module settings
export const useSurveySettings = () => {
  return useMutation(surveyModSettings)
}

// to send push notification
export const usePushNotification = () => {
  // const queryClient = useQueryClient()
  return useMutation(pushMessage)
}
// to get push notification list
export const useNotification = (appId) => {
  return useQuery('Notification', () => getNotification(appId), {
    refetchOnWindowFocus: false,
  })
}
// to add leaderboard  gamification rules leaderboard
export const useLeaderBoard = () => {
  return useMutation(leaderboard)
}

export const useGamificationData = (appId) => {
  return useQuery('GamificationData', () => getleaderboard(appId), {
    refetchOnWindowFocus: false,
  })
}
export const useStreamLeaderboard = (appId, userId) => {
  return useQuery(
    'streamGamificationData',
    () => getStreamLeaderboard(appId, userId),
    {
      refetchOnWindowFocus: false,
    }
  )
}
//  Agenda part Starts here
export const useDeleteSession = () => {
  return useMutation(deleteSession)
}

export const useEditAgenda = () => {
  return useMutation(editCreateAgenda)
}

export const useBasic = () => {
  const queryClient = useQueryClient()
  return useMutation(basicDetails, {
    onSuccess: (response) => {
      if (response.response) {
        queryClient.setQueriesData('appData', (oldData) => {
          return {
            ...oldData,
            ...response,
          }
        })
      }
    },
  })
}

// ***************Code For Publish App*****************
export const usePublishApp = () => {
  const queryClient = useQueryClient()
  return useMutation(publishApp, {
    onSuccess: (result) => {
      if (result.response) {
        const data = result.responseString
        // queryClient.setQueryData('version', () => data.version)
        queryClient.setQueryData('appData', () => ({
          ...data,
          events: [
            {
              ...data.events[0],
              tabs: data.events[0].tabs.map(
                (tab) => {
                  if (tab.type === 'agenda') {
                    let agendaItems = []
                    for (const agenda of tab.agenda) {
                      agendaItems = agendaItems.concat(agenda.detail)
                    }
                    const compFnc = (a, b) => {
                      if (a.agendaId < b.agendaId) return -1
                      if (a.agendaId > b.agendaId) return 1
                      return 0
                    }
                    agendaItems.sort((a, b) => compFnc(a, b))
                    return { ...tab, agenda: agendaItems }
                  } else return tab
                }
                // tab.type === 'agenda' ? {} : tab
              ),
            },
          ],
        }))
      } else throw new Error('failed to publish')
    },
  })
}

// ********Code fore Form Questions In Ticket***************//
export const useFormQuestions = (appId) => {
  return useQuery('formData', () => getFormQues(appId), {
    refetchOnWindowFocus: false,
  })
}
// ***********update Heading in Form Questions***********//
export const useUpdateHeading = () => {
  const queryClient = useQueryClient()
  return useMutation(addHeading, {
    onSuccess: (response) => {
      if (response.response)
        queryClient.setQueriesData('formData', (oldData) => {
          return [...oldData, createHeading(response.payload)]
        })
    },
  })
}
// exhibitor delete

export const useDeleteExhibitor = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteExhibitor, {
    onSuccess: (response) => {
      queryClient.setQueriesData('appData', (oldData) => {
        return {
          ...oldData,
          events: [
            {
              ...oldData.events[0],
              tabs: updateExhibitorsTabsDelete(
                oldData.events[0].tabs,
                response
              ),
            },
            ...oldData.events,
          ],
        }
      })
    },
  })
}
//Create Heading For form Question
const createHeading = (payload) => {
  return {
    heading: payload.heading,
    head_id: payload.head_id,
    user_type: payload.user_type,
    items: [],
  }
}
export const useUpdateFormQuestion = () => {
  const queryClient = useQueryClient()
  return useMutation(createEditFormQuestions, {
    onSuccess: (response) => {
      if (response.response)
        queryClient.setQueriesData('formData', (oldData) => {
          return oldData.map((heading) => {
            if (heading.head_id === response.payload.head_id)
              return {
                ...heading,
                items: [createFormQuestion(response.payload)],
              }
            else {
              return heading
            }
          })
        })
    },
  })
}

const createFormQuestion = (payload) => {
  return {
    sub_heading: payload.sub_heading,
    sub_id: payload.sub_id,
    questions: [
      {
        answer: payload.answer,
        detail: payload.detail,
        question: payload.question,
        required: payload.required,
        user_type: payload.user_type,
        type: payload.type,
        name: payload.name,
      },
    ],
  }
}
// delForm question
export const useDelFormQuestion = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries('formData')
    },
  })
}

// update exhibitors after delete
const updateExhibitorsTabsDelete = (tabs, response) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === response.checkvalue)
      return {
        ...tab,
        items: tab.items.filter(
          (item) => item.exhibitor_id !== Number(response.type_id)
        ),
      }
    else return tab
  })
}
// edit exhibitor
export const useEditExhibitor = () => {
  const queryClient = useQueryClient()
  return useMutation(editcreateExhibitor, {
    onSuccess: (response) => {
      if (response.response)
        if (response.payload.flag === 'update') {
          queryClient.setQueriesData('appData', (oldData) => {
            return {
              ...oldData,
              events: [
                {
                  ...oldData.events[0],
                  tabs: updateExhibitorsTabsEdit(
                    oldData.events[0].tabs,
                    response
                  ),
                },
                ...oldData.events,
              ],
            }
          })
        } else {
          //create exhibitor logic
          queryClient.setQueriesData('appData', (oldData) => {
            return {
              ...oldData,
              events: [
                {
                  ...oldData.events[0],
                  tabs: updateExhibitorsTabsCreate(
                    oldData.events[0].tabs,
                    response
                  ),
                },
              ],
            }
          })
        }
    },
  })
}
// update after exhibitor edit
const updateExhibitorsTabsEdit = (tabs, { response, payload, type_id }) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === payload.checkvalue) {
      const tabItem = {
        ...tab,
        items: tab.items.map((item) =>
          item.exhibitor_id === type_id ? { ...item, ...payload } : item
        ),
      }
      return tabItem
    } else return tab
  })
}
// update after exhibitor create

const updateExhibitorsTabsCreate = (tabs, { payload }) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === payload.checkvalue) {
      // const itemsLength = tab.items.length
      // const newExhibitorId = tab.items[itemsLength - 1].exhibitor_id + 1

      return {
        ...tab,
        items: tab.items.push({
          ...payload,
          // exhibitor_id: newExhibitorId,
          // type_id: newExhibitorId,
          // type_id,
        }),
      }
    } else return tab
  })
}
// edit map
export const useEditFloorMap = () => {
  const queryClient = useQueryClient()
  return useMutation(floormap, {
    onSuccess: (response) => {
      if (!response.response) return
      if (response.payload.flag === 'update') {
        queryClient.setQueriesData('appData', (oldData) => {
          return {
            ...oldData,
            events: [
              {
                ...oldData.events[0],
                tabs: updateMapTabsEdit(oldData.events[0].tabs, response),
              },
            ],
          }
        })
      } else {
        queryClient.setQueriesData('appData', (oldData) => {
          return {
            ...oldData,
            events: [
              {
                ...oldData.events[0],
                tabs: updateMapTabsCreate(oldData.events[0].tabs, response),
              },
            ],
          }
        })
      }
    },
  })
}
// update map tabs
const updateMapTabsEdit = (tabs, { payload, floor_id }) => {
  return tabs.map((tab) => {
    // console.log('payload', payload)
    if (tab.checkvalue === payload.checkvalue) {
      const tabItem = {
        ...tab,
        floors: tab.floors.map((item) =>
          item.floor_id === floor_id
            ? {
                ...item,
                // ...payload,
                name: payload.floor_name,
                description: payload.floor_description,
              }
            : item
        ),
      }
      return tabItem
    } else return tab
  })
}

// update map after create new floor

const updateMapTabsCreate = (tabs, { payload }) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === payload.checkvalue) {
      // const floorsLength = tab.floors.length
      // const newFloorId = tab.floors[floorsLength - 1].floor_id + 1

      // const tabItem = {
      //   ...tab,
      //   floors: tab.floors.push({
      //     floor_id: payload.floor_id,
      //     name: payload.floor_name,
      //     description: payload.floor_description,
      //   }),
      // }
      return tab
    } else return tab
  })
}

// delete floor
export const useDeleteFloor = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteFloor, {
    onSuccess: (response) => {
      if (response.response) {
        queryClient.setQueriesData('appData', (oldData) => {
          return {
            ...oldData,
            events: [
              {
                ...oldData.events[0],
                tabs: updateFloorTabsDelete(oldData.events[0].tabs, response),
              },
              ...oldData.events,
            ],
          }
        })
      } else console.error('response false floor ', response.data)
    },
  })
}

// update after delete floor
const updateFloorTabsDelete = (tabs, { payload, type_id }) => {
  return tabs.map((tab) => {
    if (tab.checkvalue === payload.checkvalue) {
      return {
        ...tab,
        floors: tab.floors.filter(
          (floor) => floor.floor_id !== Number(type_id)
        ),
      }
    } else return tab
  })
}

// add map pins
export const useFloorLocation = () => {
  return useMutation(floorLocation)
}

export const useDeleteAttendee = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteAttendeeUser, {
    onSuccess: (response) => {
      if (response.response) {
        queryClient.setQueriesData('attendeeData', (attendesData) => {
          return {
            ...attendesData,
            users: attendesData.users.filter(
              (user) => !response.users.includes(user.userid)
            ),
          }
        })
      }
    },
  })
}

//Register User for Attendee
export const useRegisterAttendee = () => {
  const queryClient = useQueryClient()
  // return useMutation(RegisterAttendee)
  return useMutation(RegisterAttendee, {
    onSuccess: (response) => {
      // if (response.response) {
      //   const {
      //     FirstName,
      //     LastName,
      //     UserCategory,
      //     Userid,
      //     blocked_users,
      //     Company,
      //     Designation,
      //     City,
      //     website,
      //     Phone,
      //     schedules,
      //     agenda_id,
      //     BlogUrl,
      //     Description,
      //     Email,
      //   } = response.regAttendeePayload.regUsers[0]
      //   queryClient.setQueriesData('attendeeData', (attendesData) => {
      //     return {
      //       ...attendesData,
      //       users: [
      //         ...attendesData.users,
      //         {
      //           userid: Userid,
      //           user_category: UserCategory,
      //           schedules: schedules,
      //           blocked_users: blocked_users,
      //           first_name: FirstName,
      //           last_name: LastName,
      //           company: Company,
      //           designation: Designation,
      //           city: City,
      //           website,
      //           mobile: Phone,
      //           agenda_id,
      //           user_blog: BlogUrl,
      //           description: Description,
      //           email: Email,
      //           admin_flag: 'none',
      //           registration_date: new Date().getTime(),
      //         },
      //       ],
      //     }
      //   })
      // }
      queryClient.invalidateQueries('attendeeData')
    },
  })
}
//update Select and status in Attendee
export const useUpdateAttendee = () => {
  const queryClient = useQueryClient()
  return useMutation(updateAttendeeUser, {
    onSuccess: (response) => {
      //queryClient.setQueryData('attendeeData')
      if (response.response) {
        const {
          checkin_status,
          user_category,
          company,
          designation,
          last_name,
          first_name,
        } = response
        queryClient.setQueriesData('attendeeData', (attendesData) => {
          return {
            ...attendesData,
            users: attendesData.users.map((user) => {
              if (user.userid === response.userid) {
                return {
                  ...user,
                  checkin_status,
                  user_category,
                  company,
                  designation,
                  last_name,
                  first_name,
                }
              } else return user
            }),
          }
        })
      }
    },
  })
}

///Upload Excel file for Attendee/Users
export const useUoloadAttendeeXlsx = () => {
  return useMutation(attendeeXlsxUpload)
}

//fetch Data for Attendee
export const useFetchAttendee = (appId) => {
  return useQuery('attendeeData', () => fetchAttendee(appId), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
//Verify Attendee user
export const useVerifyAttendee = () => {
  return useMutation(verifyAttendeeUser)
}

// to update modules in mobile app component
export const useHomeSettings = () => {
  return useMutation(homeSettings)
}
// update home tab after edit
// const updateHomeTabsEdit = (tabs, { response, payload }) => {
//   return tabs.map((tab) => {
//     if (tab.checkvalue === 'home0') {
//       const tabItem = {
//         ...tab,
//         items: tab.items.map((item) =>
//           item.value === payload.value ? { ...item, ...payload } : item
//         ),
//       }
//       return tabItem
//     } else return tab
//   })
// }
// update home tab after delete
// const updateHomeTabsDelete = (tabs, { response, payload }) => {
//   return tabs.map((tab) => {
//     if (tab.checkvalue === 'home0') {
//       return {
//         ...tab,
//         items: tab.items.filter((item) => item.value !== payload.value),
//       }
//     } else return tab
//   })
// }

// ********Virtual Hybrid********************
export const useVirtualHybrid = () => {
  return useMutation(virtualHybrid)
}

///upload Xlsx in Agenda
export const useUploadXlsAgenda = () => {
  return useMutation(xlsxUpload)
}
///upload Xlsx in Speakers
export const useUploadXlsSpeakers = () => {
  return useMutation(speakerXlsxUpload)
}

///upload Xlsx in Sponsors
export const useUploadXlsSponsors = () => {
  return useMutation(sponsorXlsxUpload)
}

///upload Xlsx in Exhibitor
export const useUploadXlsExhibitor = () => {
  return useMutation(exhibitorXlsxUpload)
}

// to reorder the moules in layout
export const useModSetting = () => {
  return useMutation(modSetting)
}

// to add new modules in layout
export const useAddFeature = () => {
  return useMutation(addFeature)
}

// to delete feature in settings
export const useDeleteFeature = () => {
  return useMutation(deleteFeature)
}

//export to excel for Exhibitor
export const useExportXlexhibitor = () => {
  return useMutation(exportToExcel)
}

// //////Video Upload
export const useVideo = () => {
  return useMutation(uploadVideo)
}

/////delete Video
export const useDeleteVideo = () => {
  return useMutation(deleteVideo)
}

////To Upload Social Media Link
export const useSocialMedia = () => {
  return useMutation(uploadSocialMedia)
}

///Delete Social Media link
export const useDeleteSocialMedia = () => {
  return useMutation(deleteSocialMedia)
}

////Create Banner Ads
export const useBannerAds = () => {
  return useMutation(bannerAds)
}

///Delete Banner Ads
export const useDeleteBanner = () => {
  return useMutation(deleteBanner)
}

///zip Image Upload for Speaker
export const useZipImageUpload = () => {
  const queryClient = useQueryClient()
  return useMutation(zipImageUpload, {
    onSuccess: () => {
      queryClient.invalidateQueries('gallery')
    },
  })
}

// ////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

//fetch Data for SurveyFeedBack
export const useSurveyFeedback = (appId) => {
  return useQuery('surveyFeedBackData', () => fetchSurveyFeedback(appId), {
    refetchOnWindowFocus: false,
  })
}

//Fetch Speaker Rating Data

export const useSpeakerRating = (appId) => {
  return useQuery('speakerRating', () => fetchSpeakerRating(appId), {
    refetchOnWindowFocus: false,
  })
}

//Fetch Speaker Rating Data
export const useAgendaRating = (appId) => {
  return useQuery('agendaRating', () => fetchAgendaRating(appId), {
    refetchOnWindowFocus: false,
  })
}

//Fetch Reg vs Ticketed User Data
export const useRegTicket = (appId) => {
  return useQuery('regVsTicketed', () => fetchRegVsTicketed(appId), {
    refetchOnWindowFocus: false,
  })
}

//Fetch No of User Data
export const useNoOfUser = (appId) => {
  return useQuery('NoOfUser', () => NoOfUsers(appId), {
    refetchOnWindowFocus: false,
  })
}

//Fetch Notification Data
export const useAndNotification = (appId) => {
  return useQuery('andNotification', () => AndroidNote(appId), {
    refetchOnWindowFocus: false,
  })
}
// ------to get templates
export const useGetTemplates = (appid, userid, type) => {
  return useQuery(type, () => getTemplates(appid, userid, type), {
    refetchOnWindowFocus: false,
  })
}
// ------to
//Fetch Data for Logged In User
export const useLoggedIn = (appId) => {
  return useQuery('loggedUser', () => loggedInReg(appId), {
    refetchOnWindowFocus: false,
  })
}

//UploadMisc(image Upload)
export const useImgUpload = () => {
  return useMutation(uploadImg)
}

////To generate Pdf in Attendee
export const useGenratePdf = () => {
  const queryClient = useQueryClient()
  return useMutation(generatePdf, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries('attendeeData')
      }, 3000)
    },
  })
}

///Checked In user For Attendee
export const useCheckInUser = () => {
  return useMutation(checkinUser)
}

// to update template

export const useUpdateTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation(updateTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}
// to update campaign
export const useUpdateCampaign = () => {
  const queryClient = useQueryClient()
  return useMutation(updateCampaign, {
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}
//  to create campaign
export const useCreateCampaign = () => {
  const queryClient = useQueryClient()
  return useMutation(createCampaign, {
    onSuccess: (res) => {
      queryClient.invalidateQueries()
    },
  })
}

// to send campaign email
export const useSendCampaign = () => {
  return useMutation(sendCampaign)
}
// to  delete campaign
export const useDeleteCampaign = () => {
  return useMutation(deleteCampaign)
}
////User Admins Lists
// export const useAdminList = () => {
//   return useMutation(userAdminList)
// }
export const useAdminList = (appId, dashboardUserId) => {
  return useQuery('adminList', () => userAdminList(appId, dashboardUserId), {
    refetchOnWindowFocus: false,
  })
}

export const useOutBoundEmail = (appId, email, dashboardUserId) => {
  return useQuery(
    'outBoundEmail',
    () => outBoundEmail(appId, email, dashboardUserId),
    {
      refetchOnWindowFocus: false,
    }
  )
}

///post Api for Admin Setting in Attendee
export const useAdminSetting = () => {
  const queryClient = useQueryClient()
  return useMutation(adminSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries('adminList')
    },
  })
}

//////Delete Admin in Attendeees Tab
export const useDeleteAdmin = () => {
  return useMutation(deleteAdmin)
}

//email Cofig in OutBoundEmail
export const useEmailConfig = () => {
  return useMutation(emailConfig)
}
// multievent login for stream
export const useMultilogin = () => {
  return useMutation(multieventLogin, {
    onSuccess: (response) => {
      if (response.response) {
        sessionStorage.setItem('token', response.responseString.token)
        sessionStorage.setItem('userId', response.responseString.userId)
        sessionStorage.setItem('username', response.responseString.username)
        sessionStorage.setItem('attendee_token', response.attendee_token)
        sessionStorage.setItem('email', response.email)
      }
    },
  })
}

//********Get Ticket Coupons*************** */
export const useGetCupons = (appId) => {
  return useQuery('coupons', () => getCupons(appId), {
    refetchOnWindowFocus: false,
  })
}

// *****************Create Cupan Code***************
export const useCreateCoupons = () => {
  const queryClient = useQueryClient()
  return useMutation(createCupon, {
    onSuccess: (response) => {
      queryClient.setQueryData('coupons', (coupons) => [
        ...coupons,
        response.coupon,
      ])
    },
  })
}

// **********Delete Coupon Codes*********
export const useDeleteCoupons = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteCupon, {
    onSuccess: (response) => {
      queryClient.setQueryData('coupons', (coupons) =>
        coupons.filter((coupon) => coupon.id !== response.couponId)
      )
    },
  })
}

// get reports pdf

export const useGetReportPdf = (appId) => {
  return useQuery('reports', () => getReportData(appId), {
    refetchOnWindowFocus: false,
  })
}

// getSegment for the push notification
export const useGetSegment = (appId) => {
  return useQuery('segment', () => getSegment(appId), {
    refetchOnWindowFocus: false,
  })
}

//get dvice name for push Notification
export const useGetDeviceName = (userName, appId) => {
  return useQuery('deviceName', () => getUserDevice(userName, appId), {
    refetchOnWindowFocus: false,
  })
}

// personalized schedule///////////////

export const useManageSchedule = () => {
  const queryClient = useQueryClient()
  return useMutation(manageSchedule, {
    onSuccess: (response) => {
      //queryClient.setQueryData('attendeeData')
      if (response.response) {
        const { schedules } = response
        queryClient.setQueriesData('attendeeData', (attendesData) => {
          return {
            ...attendesData,
            users: attendesData.users.map((user) => {
              if (user.userid === response.userid) {
                return { ...user, schedules }
              } else return user
            }),
          }
        })
      }
    },
  })
}

// Activity Status graph
export const useActivityStats = (appId) => {
  return useQuery('ActivityStats', () => fetchActivityStats(appId), {
    refetchOnWindowFocus: false,
  })
}

export const useGetJsonVersion = (appUrl) => {
  return useQuery('jsonVersion', () => fetchJsonVersion(appUrl), {
    refetchOnWindowFocus: false,
  })
}
