import React, { useState, useEffect, useContext } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import Rating from '@mui/material/Rating'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import StarIcon from '@mui/icons-material/StarRounded'
import StarBorderIcon from '@mui/icons-material/StarBorderRounded'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import LockIcon from '@mui/icons-material/Lock'
import { healthAxios } from '../../axiosConfig'
import { useStateValue } from '../StateProvider'
import { VoteContext, VoteActions } from '../../context/voting.context'
import { VisitorContext } from '../../context/visitor.context'
import '../../styles/ratingVote.css'
import Loader from '../customComponent/Loader'

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend)

const VotingRating = ({ app_id, socket }) => {
  const [state, dispatch] = useContext(VoteContext)
  const [{ user }] = useStateValue()
  const [value, setValue] = useState(0)
  const [openAlert, setOpenAlert] = useState(false)
  const [status, setStatus] = useState({
    isLoading: false,
    msg: null,
    type: 'success',
  })
  // const history = useHistory()
  const [editRes, setEditRes] = useState(true)
  const [totalVotes, setTotalVotes] = useState(0)
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Vote percent',
        data: [],
        backgroundColor: [],
      },
    ],
  })
  const { visitorId } = useContext(VisitorContext)

  useEffect(() => {
    if (status.msg) {
      setOpenAlert(true)
    }
  }, [status])

  useEffect(() => {
    let labels = []
    let votes = []
    let backgroundColor = []
    let totalVotes = 0
    if (
      state.activeEvent.event[0] !== null &&
      state.activeEvent.event[0].total_votes > 0
    ) {
      for (let rate of state.activeEvent.event[0].ratings) {
        totalVotes += rate.votes * rate.rating
      }
      state.activeEvent.event[0].ratings.forEach((rate) => {
        labels.push(`${rate.rating}`)
        votes.push(
          rate.votes > 0
            ? (rate.votes / state.activeEvent.event[0].total_votes) * 100
            : 0
        )
        backgroundColor.push('#9627EA')
      })
      setTotalVotes(totalVotes)
    }
    setData({
      labels,
      datasets: [
        {
          label: 'Vote percent',
          data: votes,
          backgroundColor,
        },
      ],
    })
  }, [state.activeEvent.event[0]])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }
  const handleAlertClose = () => {
    setOpenAlert(false)
  }

  const handleSubmit = async () => {
    if (value < 1) {
      return setStatus((status) => ({
        ...status,
        msg: 'At least one rating is required',
        type: 'error',
      }))
    }
    setStatus((status) => ({ ...status, msg: '', isLoading: true }))
    try {
      let body = {
        _id: state.activeEvent.event[0]._id,
        app_id,
        rate: value,
        rated_by: user ? user.userId : visitorId,
      }
      const res = await healthAxios.post('/rate', body)
      if (res.data.response) {
        let { rating, responseString } = res.data
        dispatch({
          type: VoteActions.updateActiveEvent,
          payload: {
            type: 'rating',
            data: rating,
            updateType: 'update_rating',
          },
        })
        socket.emit('VOTE_RATING_POLL', {
          roomId: rating.app_id,
          payload: rating,
        })
        setStatus((status) => ({
          ...status,
          isLoading: false,
          msg: responseString,
          type: 'success',
        }))
        setEditRes(false)
      }
    } catch (error) {
      setStatus((status) => ({
        ...status,
        isLoading: false,
        msg: 'Failed to rate',
        type: 'error',
      }))
    }
  }

  const handleEditRes = () => {
    setStatus((status) => ({ ...status, msg: '' }))
    setEditRes(true)
  }
  return (
    <>
      <Loader open={status.isLoading} />
      {status.msg && (
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={status.type}
            variant="filled"
          >
            {status.msg}
          </Alert>
        </Snackbar>
      )}
      <div className="ratingVote">
        <p>Live Poll</p>
        <div className="ratingVote-card">
          <h3 className="card-header">{state.activeEvent.event[0].question}</h3>
          <Divider />
          {editRes ? (
            <div className="rating-container">
              <h4>Give Your Rating</h4>
              <Rating
                name="customized-empty"
                defaultValue={0}
                precision={1}
                max={parseInt(state.activeEvent.event[0].max_rating)}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue)
                }}
                size="large"
                icon={<StarIcon fontSize="inherit" color="primary" />}
                emptyIcon={
                  <StarBorderIcon fontSize="inherit" color="primary" />
                }
              />
              <div className="ratingVote-btn-container">
                {state.activeEvent.event[0]?.active_status === 'false' ||
                state.activeEvent.event[0]?.active_status === false ||
                state.activeEvent.event[0]?.lock_status === 'true' ||
                state.activeEvent.event[0]?.lock_status === true ? (
                  <p>
                    <small>
                      <LockIcon fontSize="small" /> &nbsp; Voting closed
                    </small>
                  </p>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="ratingVote-submit-btn"
                      disableElevation
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                    <p>
                      <small>
                        Voting as {user ? user.username : 'Anonymous'}
                      </small>
                    </p>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="ratingVote-graph-container">
              <span className="liverating-score">
                Score :{' '}
                {state.activeEvent.event[0].total_votes === 0
                  ? 0
                  : (
                      totalVotes / state.activeEvent.event[0].total_votes
                    ).toFixed(2)}
              </span>
              <div className="ratingVote-graph">
                <Bar data={data} options={options} />
              </div>
              {state.activeEvent.event[0]?.active_status === 'false' ||
              state.activeEvent.event[0]?.active_status === false ||
              state.activeEvent.event[0]?.lock_status === 'true' ||
              state.activeEvent.event[0]?.lock_status === true ? (
                <p>
                  <small>
                    <LockIcon fontSize="small" /> &nbsp; Voting closed
                  </small>
                </p>
              ) : (
                <Button
                  variant="contained"
                  color="inherit"
                  className="ratingVote-edit-res-btn"
                  onClick={handleEditRes}
                >
                  Edit Response
                </Button>
              )}
            </div>
          )}
        </div>
        {/* privacy policy */}
        <a
          href="https://ngage.ai/privacy-policy"
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#808080',
            fontWeight: '500',
            fontSize: '0.875rem',
            textAlign: 'center',
            display: 'block',
            marginTop: '2rem',
          }}
        >
          Acceptable Use Privacy Policy
        </a>
      </div>
    </>
  )
}

export default VotingRating
